import { openDB } from "idb";
const dbPromise = openDB("Osmose360DB", 1, {
  upgrade(db: any) {
    db.createObjectStore("Osmose360DB");
  }
});

export async function get(key: string) {
  return (await dbPromise).get("Osmose360DB", key);
}
export async function set(key: string, val: any) {
  return (await dbPromise).put("Osmose360DB", val, key);
}
export async function del(key: string) {
  return (await dbPromise).delete("Osmose360DB", key);
}
export async function clear() {
  return (await dbPromise).clear("Osmose360DB");
}
export async function getAll() {
  return (await dbPromise).getAll("Osmose360DB");
}
export async function keys() {
  return (await dbPromise).getAllKeys("Osmose360DB");
}

export function setStaleCache(customerId: string): void {
  const metaDataTimeStampKey = `MetaDataTimeStamp_${customerId}`;
  set(metaDataTimeStampKey, -1);
}

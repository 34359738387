import { postRequest, getRequest } from "../Network/HttpManager.js";
import Store from "@/store/store";
import payloadManager from "./payloadManager.js";

export async function executeQuery(payload) {
  const url = process.env.VUE_APP_QueryServiceURL;
  const results = await postRequest(url, payload);
  console.log("returned data");
  console.dir(results);
  // inspect data for success or failure return response, and response.data
  // if error, invoke error handling/reporting

  if (results.data.Status != true) {
    const errorObj = {
      requestName: payload.queryName,
      Error: results.data.Messages,
      errorMessage: payload.errorMessage
    };

    Store.dispatch("updateError", errorObj);

    Store.dispatch("logger", {
      LoggingCategory: "Exception",
      Action: "executeQueryFailed",
      Message: `Could not execute query id ${payload.queryName}`,
      ExceptionType: `Query Service Error`
    });
  }

  // post query actions
  // store results in index db

  // any specialised handling should be done by caller, for example bulk image
  // determine if store should be used or not for results
  // return data directly if required
  // caller could manage the asynchrouneous call or not
  return results;
}

export async function executeQueryForAssessment(payload) {
  const url = process.env.VUE_APP_QueryServiceURL;
  const threasholdCount = await getCounts(payload);
  if (threasholdCount.MinNumber == -1) threasholdCount.MinNumber = 1;
  if (threasholdCount.MaxNumber == -1)
    threasholdCount.MaxNumber = threasholdCount.AllNumber;
  const interval = 20000;

  const iter = Math.ceil(threasholdCount.MaxNumber / interval);
  console.log("this is the iter " + iter);

  // await this.getMetaDataTSForCustomer(this.payload.CustomerId, cacheKey);
  let results;
  for (let i = 0; i <= iter; i++) {
    const startVal = i * interval + 1;
    if (startVal > threasholdCount.MaxNumber) break;

    if (startVal < threasholdCount.MinNumber - interval * 2) continue;

    const endVal = interval + i * interval;

    payload.SequenceIdStart = startVal;
    payload.SequenceIdEnd = endVal;
    console.log("line 90 :" + payload);
    const chunkResult = await postRequest(url, payload);
    if (chunkResult.data.Status != true) {
      const errorObj = {
        requestName: payload.queryName,
        Error: chunkResult.data.Messages,
        errorMessage: payload.errorMessage
      };

      Store.dispatch("updateError", errorObj);

      Store.dispatch("logger", {
        LoggingCategory: "Exception",
        Action: "executeQueryFailed",
        Message: `Could not execute query id ${payload.queryName}`,
        ExceptionType: `Query Service Error`
      });
    } else {
      if (i == 0) results = chunkResult.data.Results;
      else {
        results = results.concat(chunkResult.data.Results);
      }
    }
  }
  return results;
}

export async function executeGetQuery(payload, options) {
  const results = await getRequest(payload.url, options);
  console.log("returned data");
  console.dir(results);
  // inspect data for success or failure return response, and response.data
  // if error, invoke error handling/reporting

  if (results.status != 200) {
    const errorObj = {
      requestName: payload.queryName,
      Error: results.data.Messages,
      errorMessage: payload.errorMessage
    };

    Store.dispatch("updateError", errorObj);

    Store.dispatch("logger", {
      LoggingCategory: "Exception",
      Action: "executeQueryFailed",
      Message: `Could not execute query id ${payload.queryName}`,
      ExceptionType: `Query Service Error`
    });
  }

  // post query actions
  // store results in index db

  // any specialised handling should be done by caller, for example bulk image
  // determine if store should be used or not for results
  // return data directly if required
  // caller could manage the asynchrouneous call or not
  return results.data;
}

export async function executeJUMetadata(customerId) {
  const queryServiceURL = `${process.env.VUE_APP_METADATA_QUERY_URL}${customerId}`;
  const results = await getRequest(queryServiceURL);
  console.log("returned meta data");
  console.dir(results);
  return results.data;
}

export async function getCounts(payload) {
  const params = {
    customerId: payload.CustomerId,
    errorMessage: "max sequence query for chunked execution failed",
    queryName: "maxSequence",
    ColumnList: [
      {
        Field: "OUS_LOCATION.OUS_INSPECTION.InspectionDateTime",
        Alias: "OUS_LOCATION.OUS_INSPECTION.InspectionDateTime"
      }
    ]
  };
  const countPayload = payloadManager.getMaxSequenceNumberPayload(params);
  countPayload.Rules = payload.Rules;
  countPayload.Condition = "AND";
  const results = await executeQuery(countPayload);
  return results.data.Results;
}

export async function getAllCounts(payload) {
  const params = {
    customerId: payload.CustomerId,
    errorMessage: "max sequence query all counts for chunked execution failed",
    queryName: "maxSequence",
    ColumnList: [
      {
        Field: "OUS_LOCATION.OUS_INSPECTION.InspectionDateTime",
        Alias: "OUS_LOCATION.OUS_INSPECTION.InspectionDateTime"
      }
    ]
  };
  const countPayload = payloadManager.getMaxSequenceNumberPayload(params);
  const totalCountRuleParams = {
    field: "OUS_LOCATION.LocationID",
    type: "integer",
    operator: "greater",
    value: "0"
  };
  const totalCountRule = payloadManager.getRule(totalCountRuleParams);
  countPayload.Rules.push(totalCountRule);
  countPayload.Condition = "AND";
  const results = await executeQuery(countPayload);

  return results.data.Results;
}

/***********************************************
 * ConfigurationManager
 * *********************************************
 * functions to obtain configuration
 * Information from AccessManager Service
 *
 **********************************************/

/////////////////// Imports ///////////////////
import Axios from "axios";
import Store from "@/store/store";
import { useResiliencyDetailsStore } from "@/components/dynamic/MapViewV2/LocalStore/ResiliencyDetailsStore";

export default {
  getO360ApplicationObj: function (
    application,
    projectId,
    authToken,
    callBack,
    URL
  ) {
    console.log("in get project for user");
    const url = `${URL}${projectId}/${application}`;
    Axios.get(url, { headers: { Authorization: `Bearer ${authToken}` } })

      .then(response => {
        callBack(response.data);
      })
      .catch(error => {
        console.log(
          "Error: Could not get the project's application configuration"
        );
        console.dir(error);
        const errorObj = {
          requestName: "getProjectApplicationConfiguration",
          errorMessage: `Could not retrieve the project information. Please try again. 
            If this error continues to occur, please contact support.`,
          Error: error
        };
        Store.dispatch("updateError", errorObj);
      });
  },
  prepareJSON: async function () {
    const jobNumbersLatestYear = Store.getters.jobNumbersLatestYear;
    return {
      JobNumbers:
        jobNumbersLatestYear != null && jobNumbersLatestYear != ""
          ? jobNumbersLatestYear.split(",")
          : null
    };
  },
  getDBDContactDetails: async function (authToken, apiURL) {
    console.log("in getDBDContactDetails");
    const bodyContent = await this.prepareJSON();
    const contactDetails = await Axios.post(apiURL, bodyContent, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(response => {
      if (response.data.contacts != null) {
        if (response.data.contacts.length != 0) {
          return response.data.contacts;
        }
      }
    });
    const DBD = contactDetails.find(
      element => element.projectRole === "Director Business Development"
    );
    Store.dispatch("getDBDContactDetails", DBD);
  },

  getO360ApplicationObj2: async function (
    application,
    projectId,
    authToken,
    URL
  ) {
    console.log("in get project for user");
    const url = `${URL}${projectId}/${application}`;
    const projectInfo = await Axios.get(url, {
      headers: { Authorization: `Bearer ${authToken}` }
    })

      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log(
          "Error: Could not get the project's application configuration"
        );
        console.dir(error);
        const errorObj = {
          requestName: "getProjectApplicationConfiguration",
          errorMessage: `Could not retrieve the project information. Please try again. 
            If this error continues to occur, please contact support.`,
          Error: error
        };
        Store.dispatch("updateError", errorObj);
      });
    return projectInfo;
  },
  updateOrAddO360ApplicationObj: function (applicationDO) {
    const url = process.env.VUE_APP_UpdateUserProjectURL;
    return Axios.post(url, applicationDO, {
      headers: { Authorization: `Bearer ${Store.getters.authToken}` }
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log(
          "Error: Could not update the project's application configuration"
        );
        console.dir(error);
        const errorObj = {
          requestName: "updateProjectApplicationConfiguration",
          errorMessage: `Could not update the project information. Please try again. 
            If this error continues to occur, please contact support.`,
          Error: error
        };
        Store.dispatch("updateError", errorObj);
      });
  },
  getO360tProjectsObj: async function (
    projectId,
    authToken,
    callback,
    callbackName,
    URL
  ) {
    console.log("in get projects");
    const resiliencyDetailStore = useResiliencyDetailsStore();
    const url = `${URL}${projectId}`;
    return Axios.get(url, { headers: { Authorization: `Bearer ${authToken}` } })

      .then(response => {
        callback(callbackName, response.data);
        callback("updateJUCapability", response.data);
        callback("updateCustomerName", response.data.CustomerId);
        resiliencyDetailStore.updateThresholdLoadPercentage(
          response.data.OverloadPercentage
        );
      })
      .catch(error => {
        console.log("Error: Could not get the o360ProjectObj");
        console.dir(error);
        // var errorObj = {
        //   requestName: "getProjectConfiguration",
        //   errorMessage: `Could not retrieve the project information. Please try again.
        //     If this error continues to occur, please contact support.`,
        //   Error: error
        // };
        // Store.dispatch("updateError", errorObj);
      });
  },
  updateUserDefaultProject: function (URL, authToken, userId, projectId) {
    const url = `${URL}${userId}/${projectId}`;

    Axios.get(url, { headers: { Authorization: `Bearer ${authToken}` } })

      .then(response => {
        console.log("Was default project updated? ", response.data);
      })
      .catch(error => {
        console.log("Error: Could not update default project");
        console.dir(error);
      });
  },
  getUserFilterConfiguration: function (userid, projectId, appName) {
    const url = `${process.env.VUE_APP_USER_FILTER_CONFIGURATION}${userid}/${projectId}/${appName}?code=${process.env.VUE_APP_USER_PROJECT_CONFIG_KEY}`;

    // clear current object first
    Store.dispatch("updateUserFilterConfiguration", null);
    Axios.get(url, {
      headers: { Authorization: `Bearer ${Store.getters.authToken}` }
    })
      .then(response => {
        Store.dispatch(
          "updateUserFilterConfiguration",
          response.data || "No User Project Config Found"
        );
      })
      .catch(error => {
        console.log("Error: Could not get the user's filter configuration");
        console.dir(error);
        const errorObj = {
          requestName: "getUserFilterConfiguration",
          errorMessage: `Could not retrieve user's filter configuration. Please try again. 
            If this error continues to occur, please contact support.`,
          Error: error
        };
        Store.dispatch("updateError", errorObj);
      });
  },
  getProjectMVSearchConfig: function (projectId) {
    console.log("making request to get search configuration");
    const url = `${process.env.VUE_APP_PROJECT_MV_SEARCH_CONFIGURATION}${projectId}?code=${process.env.VUE_APP_PROJECT_SCHEMA_SERVICE_KEY}`;
    // clear current object first
    Store.dispatch("updateProjectMVSearchConfiguration", null);
    Axios.get(url, {
      headers: { Authorization: `Bearer ${Store.getters.authToken}` }
    })
      .then(response => {
        Store.dispatch("updateProjectMVSearchConfiguration", response.data);
      })
      .catch(error => {
        console.log(
          "Error: Could not get the project's MV search configuration"
        );
        console.dir(error);
        const errorObj = {
          requestName: "getProjectMVSearchConfig",
          errorMessage: `Could not retrieve project's search configuration. Please try again. 
            If this error continues to occur, please contact support.`,
          Error: error
        };
        Store.dispatch("updateError", errorObj);
      });
  }
};

// gets native form of empty payload
export default {
  getCountPayload(params) {
    const template = this.getPayloadTemplate(params);
    template.CountQuery = true;
    return template;
  },

  getGroupByPayload(params) {
    const template = this.getPayloadTemplate(params);
    template.QueryId = params.queryId;
    template.Visibility = params.visibility;
    template.Cacheable = params.cacheable;
    template.Async = params.async;
    template.GroupByFields = params.groupByFields;
    template.hasSplitBy = params.splitBy;
    return template;
  },

  getDistinctInspectionStatus(params) {
    const template = this.getPayloadTemplate(params);
    template.QueryId = params.queryId;
    template.Visibility = params.visibility;
    template.Cacheable = params.cacheable;
    template.Async = params.async;
    template.jobNumbers = params.jobNumbers;
    template.GroupByFields = params.groupByFields;
    template.hasSplitBy = params.splitBy;
    return template;
  },
  getDistinctStructureTypes(params) {
    const template = this.getPayloadTemplate(params);
    template.QueryId = params.queryId;
    template.Visibility = params.visibility;
    template.Cacheable = params.cacheable;
    template.Async = params.async;
    template.jobNumbers = params.jobNumbers;
    template.GroupByFields = params.groupByFields;
    template.hasSplitBy = params.splitBy;
    return template;
  },

  getTilingPayloadTemplate(params) {
    const template = this.getParentRule(params.condition);
    template.Cacheable = params.Cacheable;
    template.rootTileZoom = params.rootTileZoom;
    template.retainBlobs = params.retainBlobs;
    template.tileType = params.tileType;
    template.project = params.project;
    template.CustomerId = params.customerId;
    template.errorMessage = params.errorMessage;
    template.queryName = params.queryName;
    if (params.format) template.Format = params.format;
    this.getColumnList(params.ColumnList, template);
    return template;
  },
  getPayloadTemplate(params) {
    const template = this.getParentRule(params.condition);

    template.CustomerId = params.customerId;
    template.errorMessage = params.errorMessage;
    template.queryName = params.queryName;
    template.SourceTableName = params.SourceTableName;
    if (params.format) template.Format = params.format;
    this.getColumnList(params.ColumnList, template);
    return template;
  },
  getColumnList(columnList, payload) {
    payload.ColumnList = [];
    columnList.forEach(columnDef => {
      payload.ColumnList.push({
        Field: columnDef.Field,
        Alias: columnDef.Alias
      });
    });
  },

  getRule(params) {
    const rule = {
      Operator: params.operator,
      Type: params.type,
      Field: params.field,
      Value: params.value
      //Condition: params.condition
    };
    return rule;
  },

  getRule2(params) {
    const rules = [];
    params.forEach(rule => {
      const newRule = {
        Operator: rule.operator,
        Type: rule.type,
        Field: rule.field,
        Value: rule.value
        //Condition: params.condition
      };
      rules.push(newRule);
    });

    const parentRule = this.getParentRule("AND");
    const parentRule2 = this.getParentRule("AND");
    parentRule.Rules.push(parentRule2);
    parentRule2.Rules = parentRule2.Rules.concat(rules);

    return parentRule;
  },

  getParentRule(condition) {
    const parentRule = {
      Rules: [],

      Condition: condition
    };
    return parentRule;
  },

  formatedTableName(tableName) {
    if (tableName.includes(".")) {
      const tempArr = tableName.split(".");
      return tempArr[tempArr.length - 1];
    } else {
      return tableName;
    }
  },
  getRulesForFieldValues(params, values) {
    const parentRule = this.getParentRule("OR");

    values.forEach(value => {
      params.value = value;
      parentRule.Rules.push(this.getRule(params));
    });

    return parentRule;
  },
  getRuleForSingleAndMultipleValues(params) {
    const filter = {
      ...params
    };
    // params.field = Store.getters.visibilityColumnPath(filter.Name);
    // params.operator = filter.Operator ? filter.Operator : "equal";
    // params.type = filter.Type ? filter.Type : "string";
    // let values = filter.Value;
    let filterChildRule;
    if (filter.values && filter.values && filter.values.length > 1) {
      filter.condition = "OR";
      filterChildRule = this.getRulesForFieldValues(filter, filter.values);
      filter.condition = "AND";
    } else {
      // adjust params
      filter.value = filter.values[0];
      filterChildRule = this.getRule(filter);
    }
    return filterChildRule;
  },

  getMaxSequenceNumberPayload(params) {
    const template = this.getPayloadTemplate(params);
    template.MaxSequence = true;
    return template;
  },
  getPolySelectRule(params) {
    const rule = this.getRule(params);

    rule.Value = params.value;
    rule.Name = "Polygon";
    return rule;
  }
};

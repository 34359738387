export const state = {
  ErrorObj: null,
  ErrorModalVisibility: false,
  CustomMessages: {},
  defaultMessage:
    "Please try again. If this error continues, please contact support."
};
export const mutations = {
  SET_ERROR(state, errorObj) {
    state.ErrorObj = errorObj;
    if (
      errorObj.requestName == undefined ||
      errorObj.requestName == "getStructureDetails" ||
      errorObj.requestName == "updateMediaDataObject Get Media Service Token" ||
      errorObj.requestName == "updateMediaDataObject Get structure media"
    ) {
      // Do not display the error modal if one of the above requests fails
    } else {
      setTimeout(() => {
        state.ErrorModalVisibility = true;
      }, 350);
    }
  },
  SET_ERROR_MODAL_VISIBILITY(state, visibility) {
    state.ErrorModalVisibility = visibility;
  }
};
export const getters = {
  error: state => {
    return state.ErrorObj;
  },
  errorModalVisibility: state => {
    return state.ErrorModalVisibility;
  },
  errorMessage: state => {
    if (state.ErrorObj) {
      // error message is defined in the request
      if (
        state.ErrorObj.errorMessage != undefined &&
        state.ErrorObj.errorMessage != null &&
        state.ErrorObj.errorMessage != ""
      ) {
        return state.ErrorObj.errorMessage;
      } else {
        return state.defaultMessage;
      }
    }
  },
  errorType: state => {
    if (state.ErrorObj) {
      return state.ErrorObj.requestName;
    }
  }
};
export const actions = {
  updateError({ state, commit }, errorObj) {
    commit("SET_ERROR", errorObj);
  },
  updateErrorModalVisibility({ state, commit }, visibility) {
    commit("SET_ERROR_MODAL_VISIBILITY", visibility);
  },
  setErrorModalVisiblitityToFalse({ state, commit }) {
    commit("SET_ERROR_MODAL_VISIBILITY", false);
  },
  clearError({ state, commit }) {
    commit("SET_ERROR", {});
  }
};

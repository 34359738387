<template>
  <div>
    <div>
      <Topheader></Topheader>
      <Bottomheader></Bottomheader>
    </div>
    <div id="body-back"></div>
    <div class="Dash-container">
      <div class="coreContainer" style="height: 100%; width: 100%">
        <div id="welcome">
          <div id="welcome-text">
            <p>Welcome!</p>
          </div>
        </div>
        <div class="cards-container-box">
          <div class="coreContainer centerContainer">
            <div class="loadingBox">
              <p class="loadingText">
                Please wait while we load your profile...
              </p>
              <b-spinner label="Loading..." class="mapSpinner"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <O360Footer></O360Footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // loadingHomePage: false
    };
  }
};
</script>
<style scoped lang="scss">
.Dash-container {
  min-height: 300px;
  min-width: 550px;
  height: 100%;
  position: relative;
  background-color: rgba(59, 59, 59, 0.4);
}

.Dash-container::before {
  content: "";
  position: fixed;
  top: -140px;
  left: -120px;
  width: calc(100% + 120px);
  min-height: calc(100% + 140px);
  height: auto;
  z-index: -1;
  background: var(--wood-poles-distribution2-url) no-repeat center center;
  background-size: cover;
  overflow: hidden;
}

#body-back {
  height: 109px;
}
#welcome-text {
  text-align: center;
  color: #ffffff;
  font-family: "Segoe UI";
  font-size: 30px;
  font-weight: bold;
  padding-top: 26px;
  margin-bottom: 30px;
}
.coreContainer {
  width: 100%;
  /* min-height: 710px; */
  /* height: calc(100vh - 146px); */
  min-height: calc(100vh - 146px);
  height: auto;
  margin: auto;
}

.centerContainer {
  height: 100%;
  width: 100%;
  grid-column-start: 2;
  grid-column-end: 2;
  display: flex;
  justify-content: center;
}

.loadingBox {
  width: 300px;
  height: 200px;
  background-color: white;
  margin-top: 30px;
  position: relative;
  border-radius: 3px;
  box-shadow: 5px 6px 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loadingText {
  color: map-get($colors, osmose-blue);
  width: 230px;
  font-size: 18px;
}

.mapSpinner {
  width: 40px !important;
  height: 40px !important;
  color: map-get($colors, osmose-blue);
}

.cards-container-box {
  display: grid;
  grid-template-columns: auto 1350px auto;
  justify-items: center;
}

// #card-position {
//   grid-column-start: 2;
//   grid-column-end: 3;
//   float: left;
// }

// .card-cont {
//   background: linear-gradient(270deg, #dedede, #fbfbfb, #dedede);
//   background-size: 400% 400%;
//   animation: CardAnimation 1.5s ease infinite;
// }
// @keyframes CardAnimation {
//   0% {
//     background-position: 0% 50%;
//   }
//   50% {
//     background-position: 100% 50%;
//   }
//   100% {
//     background-position: 0% 50%;
//   }
// }

@media only screen and (max-width: 1367px) {
  .cards-container-box {
    display: grid;
    grid-template-columns: auto 900px auto;
  }
}

@media only screen and (max-width: 991.98px) {
  .cards-container-box {
    display: grid;
    grid-template-columns: auto 450px auto;
  }
}
</style>

<template>
  <div
    id="ieMessage"
    style="
      position: fixed;
      top: 70px;
      width: 100%;
      height: calc(100% - 70px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: var(--new-log-in-background-url);
      background-size: cover;
    "
  >
    <div
      class="ie-header"
      style="
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        background: #1c3766;
      "
    >
      <img
        style="width: 200px; height: 60px; margin-left: 70px"
        :src="osmose360LogoURL"
      />
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: white;
        opacity: 0.9;
        width: auto;
        max-width: 70%;
        height: auto;
        padding: 50px 40px 20px 40px;
      "
    >
      <h2
        style="
          color: #1c3766;
          opacity: 1;
          width: 100%;
          text-align: center;
          font-family: Segoe UI;
          margin-bottom: 30px;
        "
      >
        Your session has timed out
      </h2>
      <h3
        style="
          color: #1c3766;
          opacity: 1;
          width: 100%;
          text-align: center;
          font-family: Segoe UI;
          margin-bottom: 30px;
        "
      >
        Please log in again
      </h3>
      <h4
        style="
          color: #1c3766;
          opacity: 1;
          width: 100%;
          text-align: center;
          font-family: Segoe UI;
          margin-bottom: 30px;
        "
      >
        <a v-on:click.prevent="logUserOut()" href="#">Log In</a>
      </h4>
    </div>
  </div>
</template>

<script>
import { B2CManager } from "@/Lib/B2CManager.js";

export default {
  mixins: [B2CManager],
  data() {
    return {
      appUrl:
        process.env.VUE_APP_URL +
        (this.$router.currentRoute.value.query.lastPage
          ? this.$router.currentRoute.value.query.lastPage
          : ""),

      osmose360LogoURL: process.env.VUE_APP_OSMOSE360_LOGO_URL
    };
  },
  methods: {
    async logUserOut() {
      let lastPage = this.$router.currentRoute.value.query.lastPage;
      lastPage = lastPage == "timedout" || !lastPage ? "home" : lastPage;
      await this.$router.push({ name: lastPage });
      this.logOut();
    }
  }
};
</script>

import { uuid } from "vue-uuid";
import payloadManager from "../../../../../Lib/Database/payloadManager";
import { convertQueryFilterFormat } from "./queryFilterManager";

import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import { toRaw } from "vue";
export class O360MapViewPayloadManager {
  static getMaintenanceRules() {
    const maintRule = {
      Condition: "OR",
      Rules: [
        {
          Condition: "OR",
          Rules: [
            {
              Operator: "equal",
              Value: "Open",
              Field: "OUS_LOCATION.OUS_INSPECTION.OUS_MAINTENANCE.Status",
              Type: "string"
            }
            // {
            //   Operator: "equal",
            //   Type: "string",
            //   Field: "OUS_LOCATION.OUS_INSPECTION.O360InspectionState",
            //   Value: "Delivered"
            // }
          ]
        }
      ]
    };
    return maintRule;
  }
  static getGridDetailQueryPayload(payloadOptions: {
    format: string;
    condition: string;
    gridDetailId: number;
  }): any {
    const payload: any = payloadManager.getParentRule(payloadOptions.condition);
    payload.Format = payloadOptions.format;
    const gridDetailIdRule = {
      Type: "integer",
      Operator: "equal",
      Field: "OUS_LOCATION.LocationID",
      Value: payloadOptions.gridDetailId
    };

    payload.Rules.push(gridDetailIdRule);
    return payload;
  }

  static getSideBarPayload(payloadOptions: {
    format: string;
    condition: string;
    sideBarData: any;
    queryType: string;
  }): any {
    const payload: any = payloadManager.getParentRule(payloadOptions.condition);
    // values within group are or ed
    if (payloadOptions.queryType == "SideBar") {
      Object.keys(payloadOptions.sideBarData).forEach((field: string) => {
        const type: any =
          field == "OUS_LOCATION.LocationID" ? "integer" : "string";

        const params: any = {
          field: field,
          operator: "equal",
          type: type,
          values: payloadOptions.sideBarData[field]
        };
        const parentRule: any = payloadManager.getParentRule("OR");
        const newRule: any =
          payloadManager.getRuleForSingleAndMultipleValues(params);
        parentRule.Rules.push(newRule);
        payload.Rules.push(parentRule);
      });
    } else if (payloadOptions.queryType == "PointSelect") {
      const point = [
        payloadOptions.sideBarData.center.longitude,
        payloadOptions.sideBarData.center.latitude
      ];
      const polyPayload: any = payloadManager.getParentRule(
        payloadOptions.condition
      );
      const params: any = {
        field: "Location",
        operator: "point",
        type: "string",
        value: point
      };
      const pointSelectRule = payloadManager.getPolySelectRule(params);
      polyPayload.Rules.push(pointSelectRule);
      payload.Rules.push(polyPayload);
    } else if (payloadOptions.queryType == "PolygonSelect") {
      const newPolyGeo: any = webMercatorUtils.webMercatorToGeographic(
        payloadOptions.sideBarData,
        false
      );

      const coords = newPolyGeo.rings[0];
      // esri polygon rings are ALWAYS clockwise.  change it to counter clockwise.
      // Cosmo db query - clockwise coords find the inverse (everything outside of the polygon)
      // counter clockwise finds containment inside of polygon
      const reversedCoords: any = coords.slice().reverse();

      const polyPayload: any = payloadManager.getParentRule(
        payloadOptions.condition
      );
      const params: any = {
        field: "Location",
        operator: "polygon",
        type: "string",
        value: reversedCoords
      };
      const pointSelectRule = payloadManager.getPolySelectRule(params);
      polyPayload.Rules.push(pointSelectRule);
      payload.Rules.push(polyPayload);
    }
    payload.Format = payloadOptions.format;
    return payload;
  }

  static getAdvancedSearchPayload(payloadOptions: {
    format: string;
    condition: string;
    advancedSearchData: any;
  }): any {
    const payload: any = payloadManager.getParentRule(payloadOptions.condition);
    // const childPayload: any = payloadManager.getParentRule(
    //   payloadOptions.condition
    // );
    // values within group are or ed

    payloadOptions.advancedSearchData.Condition = "AND";
    const advPayload = {
      Condition: "AND",
      Rules: [payloadOptions.advancedSearchData]
    };
    payload.Rules.push(advPayload);

    //payload.Rules.push(payload);
    payload.Format = payloadOptions.format;
    payload.Condition = "AND";
    return payload;
  }
  static getDistinctInspectionStatus(options: {
    customerId: string;
    format?: string;
    condition: string;
    visibility: any;
    groupByFields: any;
    queryName: string;
    errorMessage: string;
    ColumnList: any;
    queryId: string;
    cacheable: boolean;
    async: boolean;
    Filters: any;
    splitBy: false;
    jobNumbers: any;
  }) {
    const columnList = [];
    const fieldName = "OUS_LOCATION.OUS_INSPECTION.O360InspectionState";
    const col = {
      Field: fieldName
    };
    columnList.push(col);
    options.ColumnList = columnList;
    options.visibility = {
      Schema: {
        ["OUS_INSPECTION"]: {
          O360InspectionState: {
            ColumnName: "O360InspectionState",
            DataType: "string",
            SQLDataType: "varchar",
            TableName: "OUS_LOCATION.OUS_INSPECTION"
          }
        }
      }
    };
    options.groupByFields = ["OUS_LOCATION.OUS_INSPECTION.O360InspectionState"];
    options.queryName = "get O360InspectionState";
    //options.errorMessage = `Could not retrieve filter configurations. Please try again.
    //If this error continues to occur, please contact support.`;
    options.queryId = uuid.v1();
    options.cacheable = true;
    options.async = false;
    options.splitBy = false;
    // querySpec.AuthToken = this.$store.getters.authToken;
    // querySpec.queryName = "get O360InspectionState";
    //const queryServiceURL = this.$store.getters.queryServiceURL;
    //querySpec.Cacheable = true;
    return payloadManager.getDistinctInspectionStatus(options);
  }

  static getDistinctStructureTypes(options: {
    customerId: string;
    format?: string;
    condition: string;
    visibility: any;
    groupByFields: any;
    queryName: string;
    errorMessage: string;
    ColumnList: any;
    queryId: string;
    cacheable: boolean;
    async: boolean;
    Filters: any;
    splitBy: false;
    jobNumbers: any;
  }) {
    const columnList = [];
    const fieldName = "OUS_LOCATION.OUS_STRUCTURE.StructureType";
    const col = {
      Field: fieldName
    };
    columnList.push(col);
    options.ColumnList = columnList;
    options.visibility = {
      Schema: {
        ["OUS_STRUCTURE"]: {
          O360InspectionState: {
            TableName: "OUS_LOCATION.OUS_STRUCTURE",
            ColumnName: "StructureType",
            SQLDataType: "varchar", //"string",
            ReferencedColumn: "", //null, //
            ReferencedTable: "", //null, //
            DataType: "string"
          }
        }
      }
    };
    options.groupByFields = ["OUS_LOCATION.OUS_STRUCTURE.StructureType"];
    options.queryName = "get StructureType";
    //options.errorMessage = `Could not retrieve filter configurations. Please try again.
    //If this error continues to occur, please contact support.`;
    options.queryId = uuid.v1();
    options.cacheable = true;
    options.async = false;
    options.splitBy = false;
    // querySpec.AuthToken = this.$store.getters.authToken;
    // querySpec.queryName = "get O360InspectionState";
    //const queryServiceURL = this.$store.getters.queryServiceURL;
    //querySpec.Cacheable = true;
    return payloadManager.getDistinctInspectionStatus(options);
  }

  static getGroupByPayload(
    sectionConfig: any,
    searchColumnConfig: any,
    options: {
      customerId: string;
      format?: string;
      condition: string;
      visibility: any;
      groupByFields: any;
      queryName: string;
      errorMessage: string;
      ColumnList: any;
      queryId: string;
      cacheable: boolean;
      async: boolean;
      Filters: any;
      splitBy: false;
    }
  ) {
    options.visibility = {
      Schema: {
        [payloadManager.formatedTableName(sectionConfig.TableName)]: {
          [sectionConfig.ColumnName]: {
            TableName: sectionConfig.TableName,
            ColumnName: sectionConfig.ColumnName,
            SQLDataType: sectionConfig.SQLDataType, //"string",
            ReferencedColumn: sectionConfig.ReferencedColumn, //null, //
            ReferencedTable: sectionConfig.ReferencedTable, //null, //
            DataType: sectionConfig.DataType
          }
        }
      }
    };
    options.groupByFields = [
      sectionConfig.TableName + "." + sectionConfig.ColumnName
    ];
    const columnList = [];
    const displayName = sectionConfig.DisplayName;
    const fieldName = sectionConfig.TableName + "." + sectionConfig.ColumnName;
    const col = {
      Field: fieldName,
      Alias: displayName
    };
    columnList.push(col);
    options.ColumnList = columnList;
    options.queryName = "get" + sectionConfig.ColumnName;
    //options.errorMessage = `Could not retrieve filter configurations. Please try again.
    //If this error continues to occur, please contact support.`;
    options.queryId = uuid.v1();
    options.cacheable = true;
    options.async = false;
    options.splitBy = false;
    return payloadManager.getGroupByPayload(options);
  }
  static getPayload(options: {
    value?: any;
    values?: any[];
    format?: string;
    visibility?: any;
    conjunction?: string;

    filterDataType?: string;
    filterOperator?: string;
    filterField?: string;
    queryName?: string;
    errorMessageText?: string;
    maintenanceGridQuery?: boolean;
    customerId?: string;
  }) {
    // fabricate query spec from filter UI
    const querySpec: any = payloadManager.getParentRule(options.conjunction);
    querySpec.Format = options.format;
    querySpec.CustomerId = options.customerId;
    querySpec.Condition = options.conjunction;

    // are two versions of the visibility still needed??
    if (querySpec.format == "GRID_VIEW") {
      querySpec.VisibilityAlias = options.visibility;
    }
    querySpec.Visibility = options.visibility;

    // determine all required paramaters
    //this.getFilterConfiguration(querySpec)

    /////////////////////// implement this method here /////////////////////////////

    // if (maintenanceGridQuery) {
    //   querySpec =
    //     this.$store.getters.applyMaintenanceGridAdditionalFilters(querySpec);
    // }

    querySpec.queryName = options.queryName;
    querySpec.errorMessage = options.errorMessageText;

    return querySpec;
  }

  static getRule(
    options: {
      operator: any;
      value: any;
      field: any;
      type: any;
    }[]
  ) {
    return payloadManager.getRule2(options);
  }

  /***************************************************** */
  // TBD: any Vues Stores variables referenced need to be
  // passed in as variables OR taken from the configs
  // that are passed in
  /**************************************************** */
  static getFilterConfiguration(
    configs: {
      userProjectConfig: any;
      projectApplicationConfig: any;
      projectMVconfig: any;
      projectConfig: { JobIds: { JobNumber: string }[] };
      store: any;
    },
    additionalParams: {
      // dateRangeStartDate?: string; // these comes from the user configuration
      // dateRangeSEndDate?: string;

      // dateRangeYears?: string[];
      // allFilterFlyoutYear?: string;
      // allFilterFlyoutServiceType?: string;
      // projectContractYears?: string[];
      // dateRangeServiceTypes?: string[];
      // projectServiceTypes?: string[];

      // userFilterConfiguration?: { Filters: any[] }[];
      queryFormat?: string;
      // mvVisualFilterFlyoutAppliedValues?: string;
      // customLayers?: { filter: any; enabled: boolean; isChecked: boolean }[];
      // customLayerFilters?: { Condition: string }[];

      jobNumbers?: string[];
      visitStatuses?: string[];
      excludeUserFilters?: boolean;
    }
  ) {
    /*
         * WARNING! this method is responsible for adding filters to queries
           through out MapView. Only modify after consulting other developers if not sure of anything.

         * Structure of the "where" clause of queries this method adds:
            Group 1 (
              SubGroup 1 ( only when Delivered or Preliminary data is requested)
                JobNumbers (if custom date filter is selected, all jobNumbers associated with the project and respect user config will be added)
                AND
                Visit Status (Will only contain one of "Delivered", "Preliminary", or both)
                AND
                InspectionDateTime (if custom date filter is selected, add date range filter)
              OR
              SubGroup 2 (only when Out for collection or In field data is requested)
                Prefield Service Year
                AND
                Prefield Service
                AND
                Visit Status (Will only contain one of "Out for Collection" or  "Prefield" or both)
                AND
                Split by filters (if configured for the project)
            )
            AND
            Group 2 (if there are applied structure types filters in filter flyout OR if there is a user pre configured filter)(
                Applied Structure types (if applied in filter flyout)
                AND
                User's pre configured filters (If it exist in the user's config)
            )
    */
    const parentGroup1: any = {
      Rules: [],

      Condition: "AND"
    };
    // const userProjectConfig = configs.userProjectConfig[0];
    const mvFilterFlyoutAppliedValues =
      configs.store?.getters.mvFilterFlyoutAppliedValues; //userProjectConfig.MVAppliedFilter;
    const excludeUserFilters = additionalParams?.excludeUserFilters ?? false; // this is allways true (apparently)
    const dateRangeFilterTypeSelected =
      mvFilterFlyoutAppliedValues?.dateFilterType == "Custom" ? true : false;
    const prefieldArr = [
      "Out for Collection" /* In Field */,
      "Prefield" /* Unvisited */
    ];
    const postFieldArr = ["Delivered", "Preliminary"];

    /* visit statuses
          1. if querySpec has passed in visitStatuses then use it
          2. else if there are filter flyout applied visit statuses then use it
          3. else use the filter flyout visit statuses options
          4. Because getMapViewVisitStatus is dependent on the distinctInspectionStatus queyr
            and all the other queries does not wait for the distinct queries (no reason for them to wait)
            getMapViewVisitStatus will sometime return undefined/null, therefore if it is undefined we add all visit statuses for the queries
      */
    const visitStatuses = additionalParams?.visitStatuses
      ? additionalParams?.visitStatuses
      : mvFilterFlyoutAppliedValues?.visitStatus?.length
      ? mvFilterFlyoutAppliedValues?.visitStatus
      : configs.store.getters.getMapViewVisitStatus
      ? [...configs.store.getters.getMapViewVisitStatus]
      : ["Out for Collection", "Prefield", "Preliminary", "Delivered"];
    const hasInFieldOrUnvisited =
      visitStatuses?.length > 0
        ? prefieldArr.some(item => visitStatuses.includes(item))
        : false;
    const onlyInFieldOrUnvisited =
      visitStatuses.length > 0
        ? visitStatuses.every((item: string) => prefieldArr.includes(item))
        : false;

    //////////////////// Group 1 - Sub Group 1 - JobNumbers, visit status ///////////////////////

    const child1Group: any = payloadManager.getParentRule("OR");

    if (!onlyInFieldOrUnvisited) {
      const child1SubGroup1: any = payloadManager.getParentRule("AND");

      // mvFilterFlyoutAppliedValues.dateFilterType == "Custom";
      let jobNumbers: string[] = [];
      if (additionalParams?.jobNumbers) {
        jobNumbers = additionalParams?.jobNumbers;
      } else if (dateRangeFilterTypeSelected) {
        jobNumbers = configs.store.getters
          .filteredJobNumbers5(
            undefined,
            mvFilterFlyoutAppliedValues.serviceType,
            true
          )
          .split(",");
      } else {
        jobNumbers = configs.store.getters
          .filteredJobNumbers5(
            mvFilterFlyoutAppliedValues.year,
            mvFilterFlyoutAppliedValues.serviceType,
            true
          )
          .split(",");
      }
      const jobNumbersFilter: any =
        payloadManager.getRuleForSingleAndMultipleValues({
          field: "OUS_LOCATION.OUS_INSPECTION.JobNumber",
          type: "string",
          operator: "equal",
          /*
            If jobNumbers defined in the query spec use it (distinct queries have that). Else if date range filter is selected, 
            use jobNumbers that adheres to selected service types. Else, use jobNumbers that adhere to years and service types selected
            in the filter flyout
          */
          values: jobNumbers
        });

      // start change 1
      const tempParent0: any = payloadManager.getParentRule("AND");
      tempParent0.Rules.push(jobNumbersFilter);
      //child1SubGroup1.Rules.push(jobNumbersFilter);
      // end change 1

      // if there are visitStatuses and visit status doesn't include both Delivered and Preliminary
      // because if both are requested there is no need to add the below block
      if (
        visitStatuses.length
        //&& !postFieldArr.every(item => visitStatus.includes(item))
      ) {
        const tempParent: any = payloadManager.getParentRule("OR");
        const filterFlyoutInspectionStatusFilter: any =
          payloadManager.getRuleForSingleAndMultipleValues({
            field: "OUS_LOCATION.OUS_INSPECTION.O360InspectionState",
            type: "string",
            operator: "equal",
            values: visitStatuses.filter(
              (item: string) => !prefieldArr.includes(item)
            )
          });
        // start change 2
        tempParent.Rules.push(filterFlyoutInspectionStatusFilter);
        tempParent0.Rules.push(tempParent);
        child1SubGroup1.Rules.push(tempParent0);
        // end change2
      }
      // add inspectionDateTime date range filter when custom date filter is selected
      // for queries that don't exclude user filters, and are not distinct queries
      if (
        dateRangeFilterTypeSelected &&
        !excludeUserFilters &&
        !additionalParams?.jobNumbers
      ) {
        const inspectionDateTimeParent1: any =
          payloadManager.getParentRule("OR");
        const startInspectionDateTime: any =
          payloadManager.getRuleForSingleAndMultipleValues({
            field: "OUS_LOCATION.OUS_INSPECTION.InspectionDateTime",
            type: "datetime",
            operator: "greater_or_equal",

            values: [configs.store.getters.dateRangeStartDate]
          });
        inspectionDateTimeParent1.Rules.push(startInspectionDateTime);

        const inspectionDateTimeParent2: any =
          payloadManager.getParentRule("OR");
        const endInspectionDateTime: any =
          payloadManager.getRuleForSingleAndMultipleValues({
            field: "OUS_LOCATION.OUS_INSPECTION.InspectionDateTime",
            type: "datetime",
            operator: "less_or_equal",

            values: [configs.store.getters.dateRangeEndDate]
          });
        inspectionDateTimeParent2.Rules.push(endInspectionDateTime);

        child1SubGroup1.Rules.push(inspectionDateTimeParent1);
        child1SubGroup1.Rules.push(inspectionDateTimeParent2);
      }

      child1Group.Rules.push(child1SubGroup1);
    }

    ////////////// Group 1 - Sub Group 2 - Prefield Service types and years, and visit status ////////////////
    if (hasInFieldOrUnvisited) {
      //const child1Group1: any = payloadManager.getParentRule("AND");
      const mvAppliedYear = mvFilterFlyoutAppliedValues?.year;
      const mvAppliedServiceType = mvFilterFlyoutAppliedValues?.serviceType;

      const filterFlyoutYearArr =
        dateRangeFilterTypeSelected && !excludeUserFilters
          ? configs.store.getters.dateRangeYears
          : mvAppliedYear.length > 0 && !excludeUserFilters
          ? mvAppliedYear
          : configs.store.getters.allFilterFlyoutYear && !excludeUserFilters // from store
          ? configs.store.getters.allFilterFlyoutYear
          : configs.store.getters.projectContractYears; // from store

      const filterFlyoutServiceTypeArr =
        dateRangeFilterTypeSelected && !excludeUserFilters
          ? configs.store.getters.dateRangeServiceTypes(mvAppliedServiceType)
          : mvAppliedServiceType.length > 0 && !excludeUserFilters
          ? mvAppliedServiceType
          : configs.store.getters.allFilterFlyoutServiceType &&
            !excludeUserFilters
          ? configs.store.getters.allFilterFlyoutServiceType
          : configs.store.getters.projectServiceTypes;

      // add pre field service year
      const child1SubGroup2: any = payloadManager.getParentRule("AND");
      const prefieldServiceYearFilter: any =
        payloadManager.getRuleForSingleAndMultipleValues({
          field: "OUS_LOCATION.OUS_INSPECTION.PrefieldServiceYear",
          type: "string",
          operator: "equal",
          values: filterFlyoutYearArr
        });

      // left off here
      child1SubGroup2.Rules.push(prefieldServiceYearFilter);

      const prefieldServiceFilter: any =
        payloadManager.getRuleForSingleAndMultipleValues({
          field: "OUS_LOCATION.OUS_INSPECTION.PrefieldService",
          type: "string",
          operator: "equal",
          values: filterFlyoutServiceTypeArr
        });

      child1SubGroup2.Rules.push(prefieldServiceFilter);

      const prefieldVisitStatusesFilter: any =
        payloadManager.getRuleForSingleAndMultipleValues({
          field: "OUS_LOCATION.OUS_INSPECTION.O360InspectionState",
          type: "string",
          operator: "equal",
          values: visitStatuses.filter((item: string) =>
            prefieldArr.includes(item)
          )
        });

      child1SubGroup2.Rules.push(prefieldVisitStatusesFilter);

      //////////////////// Group 1 - Sub Group 2 - Split By Filters ///////////////////////
      const splitByAttributes = configs.projectApplicationConfig?.SplitBy;
      if (splitByAttributes?.length) {
        for (const splitByItem of splitByAttributes) {
          const splitByFilter =
            payloadManager.getRuleForSingleAndMultipleValues({
              field: splitByItem.TableName + "." + splitByItem.ColumnName,
              //LimitRule: false, // is thi required?
              operator: "equal",
              type: "string",

              values: splitByItem.Values
            });

          child1SubGroup2.Rules.push(splitByFilter);
        }
      }

      const childGroup1SubGroup2ParentRule: any =
        payloadManager.getParentRule("AND");
      childGroup1SubGroup2ParentRule.Rules.push(child1SubGroup2);
      child1Group.Rules.push(childGroup1SubGroup2ParentRule);
      // child1Group.Rules.push(child1SubGroup2);
    }

    //////////////////// Group 2 - Structure Type Filter ///////////////////////

    const child2Group: any = payloadManager.getParentRule("AND");

    if (
      mvFilterFlyoutAppliedValues?.structureType?.length &&
      !excludeUserFilters &&
      !additionalParams?.jobNumbers
    ) {
      const structureTypesFilter: any =
        payloadManager.getRuleForSingleAndMultipleValues({
          field: "OUS_LOCATION.OUS_STRUCTURE.StructureType",
          type: "string",
          operator: "equal",
          values: mvFilterFlyoutAppliedValues.structureType
        });

      const group2ChildParentRule: any = payloadManager.getParentRule("AND");
      group2ChildParentRule.Rules.push(structureTypesFilter);
      child2Group.Rules.push(group2ChildParentRule);
    }
    //////////////////// Group 2 - pre configured user filter ///////////////////////
    const userFilterConfigurationRaw =
      configs.userProjectConfig?.[0]?.MapView?.[0].Filters;
    const userFilterConfiguration = toRaw(userFilterConfigurationRaw);
    if (userFilterConfiguration && !excludeUserFilters) {
      const userPreConfiguredFilter: any = payloadManager.getParentRule("AND");
      if (
        userFilterConfiguration.length > 0 &&
        userFilterConfiguration[0].FormatType &&
        userFilterConfiguration[0].FormatType == "Native"
      )
        userPreConfiguredFilter.Rules = userFilterConfiguration;
      else {
        const formattedRules = convertQueryFilterFormat(
          userFilterConfiguration
        );

        userPreConfiguredFilter.Rules = formattedRules; // check to see if this works, might need to be converted
      }

      child2Group.Rules.push(userPreConfiguredFilter);
    }

    ////////// Group 3 - custom layer group /////////////
    // NOT BEING USED ANYMORE

    // const child3Group: any = payloadManager.getParentRule("OR");

    // only get the active layers that are checked on in the visual flyout
    // const customLayerFilters = configs.store.getters.customLayers
    //   ?.filter(
    //     (customLayer: { isChecked: boolean; enabled: boolean }) =>
    //       customLayer.isChecked == true && customLayer.enabled == true
    //   )
    //   .map((layer: any[]) => layer.filter);

    // Do not apply the custom layer filters to the map data (Format == MAP_VIEW, HYBRID)
    // and do not apply the custom layer filters to the search flyout options
    // queries (Format == JSON).  Only apply the custom layer filters to the
    // following formats: TABLE_VIEW, GRID_VIEW
    // if (
    //   configs.store.getters.mvVisualFilterFlyoutAppliedValues == "None" &&
    //   additionalParams.queryFormat != "MAP_VIEW" &&
    //   additionalParams.queryFormat != "HYBRID" &&
    //   additionalParams.queryFormat != "JSON"
    // ) {
    //   // If there are custom layers, and at least 1 is active and enabled
    //   // add its filter to the child3Group filters

    //   // need to nest custom layer filter for query service to properly interprit

    //   if (
    //     configs.store.getters.customLayers?.length &&
    //     customLayerFilters?.length
    //   ) {
    //     customLayerFilters.forEach((customLayerFilter: any) => {
    //       const childRule: any = payloadManager.getParentRule(
    //         customLayerFilter.Condition
    //       );
    //       childRule.Rules = [{ ...customLayerFilter }];
    //       child3Group.Rules.push({
    //         childRule
    //       });
    //     });
    //   } else {
    //     // Otherwise everything is turned off, don't return anything by forcing
    //     // a no match query.

    //     child3Group.Rules.push({
    //       Condition: "OR",
    //       Rules: [
    //         {
    //           Operator: "equal",
    //           Value: -1,
    //           Field: "OUS_LOCATION.LocationID",
    //           Type: "integer"
    //         }
    //       ]
    //     });
    //   }
    // }
    //////////// end Group 3 - custom layer group child3Group //////////////

    /////////////////////////////// END OF GROUPS //////////////////////////////////
    //payloadManager.getParentRule("AND");

    if (child1Group.Rules.length > 0) parentGroup1.Rules.push(child1Group);
    if (child2Group.Rules.length > 0) parentGroup1.Rules.push(child2Group);
    // if (child3Group.Rules.length > 0) parentGroup1.Rules.push(child3Group);

    return parentGroup1;
  }

  static getExportQueryRules(vuexStore: any) {
    const allQueries = vuexStore.getters.allQueries;

    const payLoad: any = payloadManager.getParentRule("OR");
    let payLoadProxy = payLoad;

    /////////////////// Deselect Query ////////////////////
    if (allQueries.deSelectQuery && allQueries.deSelectQuery.length) {
      payLoad.Condition = "AND";

      const filter = payloadManager.getRule({
        Type: "integer",
        Operator: "not in",
        Field: "OUS_LOCATION.LocationID",
        Value: allQueries.deSelectQuery.join() // need to check this value
      });

      // de - selelcts need to be anded to the other filters
      // new parent will be used for spatial and attribute and select queries
      const newParent = payloadManager.getParentRule("OR");

      payLoad.Rules.push(newParent);
      payLoad.Rules.push(filter);
      payLoadProxy = newParent;
    }

    ////////////////////////////  Spatial Query ////////////////////////////////
    if (allQueries.spatialQuery && allQueries.spatialQuery.length > 0) {
      allQueries.spatialQuery.forEach((spq: { Rules: any[] }) => {
        payLoadProxy.Rules.push(spq.Rules[0]);
      });
    }

    /////////////////////////  attribute query ////////////////////////////////
    if (allQueries.attributeQuery) {
      const attrParent = payloadManager.getParentRule("AND");

      const attributeQuery = toRaw(allQueries.attributeQuery);
      attrParent.Rules = attrParent.Rules.concat(attributeQuery.Rules);
      payLoadProxy.Rules.push(attrParent);
    }

    /////////////////////////////// select query ////////////////////////////////

    if (allQueries.selectQuery && allQueries.selectQuery.length > 0) {
      allQueries.selectQuery.forEach((sel: { Rules: any[] }) => {
        payLoadProxy.Rules.push(sel.Rules[0]);
      });
    }

    return payLoadProxy;
  }
}

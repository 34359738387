<template>
  <div id="base-container">
    <b-input-group>
      <b-form-input
        @focus="showList = true"
        @blur="setSelection(searchValue)"
        v-on:keyup.enter="setSelection(searchValue)"
        v-bind:class="{ searchFieldFocus: showList }"
        autocomplete="new-password"
        class="searchField"
        v-model="searchValue"
        :placeholder="placeHolder"
        ref="inputBar"
      >
      </b-form-input>
      <b-input-group-append @click="$refs.inputBar.focus()">
        <span
          v-bind:class="{ searchIconBoxfocus: showList }"
          class="input-group-text searchIconBox"
        >
          <i class="fas fa-caret-down"></i> </span
      ></b-input-group-append>
    </b-input-group>
    <div v-if="showList" id="filter-list-container">
      <ul class="filter-list">
        <li
          class="list-item"
          :key="index"
          v-for="(item, index) in fiteredOptions"
          @mousedown="setSelection(item[displayProperty])"
        >
          {{ item[displayProperty] }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchValue: null,
      showList: false,
      selectionMade: false
    };
  },
  props: {
    dataList: { type: Array, required: true },
    placeHolder: { type: String, default: "Select" },
    defaultSelection: { type: String, default: null },
    displayProperty: { type: String, required: true }
  },
  computed: {
    fiteredOptions() {
      if (this.searchValue == "" || !this.searchValue) {
        return this.dataList;
      }
      const lowerSearch = this.searchValue.toLowerCase();
      const result = this.dataList.filter(item => {
        const thisResult = item[this.displayProperty].toLowerCase();
        return thisResult ? thisResult.startsWith(lowerSearch) : false;
      });
      return result;
    }
  },
  methods: {
    setSelection(selection) {
      this.searchValue = selection;
      this.showList = false;
      this.$emit("selectionMade", selection);
    },
    setDisplayValue(val) {
      this.showList = val;
    }
  },
  created() {
    if (this.defaultSelection != null) this.searchValue = this.defaultSelection;
  }
};
</script>

<style scoped>
.searchIconBox {
  border-left: none;
  background-color: #ffffff;
  height: 30px;
  cursor: pointer;
}
.searchIconBoxfocus {
  border-color: #80bdff;
  outline: 0;
}
.searchField {
  border-right: none;
  height: 30px;
}
.searchFieldFocus {
  border-right: none;
  border-color: #80bdff;
}
.filter-list {
  list-style-type: none;
  padding: 0;
}
.list-item {
  color: #666;
  padding-left: 8px;
  font-size: 14px;
}
.list-item:hover {
  cursor: pointer;
  background-color: #e2e6ea;
  color: #1c3766;
}
#filter-list-container {
  border: 1px solid #a5c0dc;
  max-height: 100px;
  min-width: 100%;
  overflow: auto;
  position: absolute;
  background-color: white;
  z-index: 1;
}
#base-container {
  position: relative;
}
</style>
;

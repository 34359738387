<template>
  <div>
    <div @click="toggleDropdown = !toggleDropdown">
      <span class="posRelative">
        <span class="cartCount">{{ cartItemsCount }}</span>
        <i class="fas fa-shopping-cart fa-lg"></i>
      </span>
      <!-- <span>CART</span> -->
    </div>
    <div class="cstDropdown" v-show="toggleDropdown">
      <div class="cartItems">
        <div
          class="item d-flex"
          v-for="prodGroup in getCartItems"
          :key="prodGroup.productGroupId"
        >
          <div class="mr-3">
            <img :src="prodGroup.thumbnailURI" class="cartImage" />
          </div>
          <div class="mr-auto mt1" :title="prodGroup.name">
            <div class="cTitle">
              {{
                prodGroup.name.length > 50
                  ? prodGroup.name.substring(0, 50) + "..."
                  : prodGroup.name
              }}
            </div>
            <div
              class="cList d-flex"
              v-for="prod in prodGroup.products"
              :key="prod.productId"
            >
              <div class="mr-auto mt1">
                <div class="cSize" :title="getSizeTitle(prod)">
                  <strong>Size:</strong>&nbsp;
                  <span>{{ getSizeText(prod) }}</span>
                </div>
                <div class="cQuantity">
                  <strong>Quantity:</strong>&nbsp;
                  <span class>{{ prod.quantity }}</span>
                </div>
              </div>
              <div
                class="deleteItem"
                @click.stop="
                  deleteProduct(prodGroup.productGroupId, prod.productId)
                "
              >
                <i class="far fa-trash-alt"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-dropdown-item-button class="cbtnOne" @click="showCart"
        >VIEW CART</b-dropdown-item-button
      >
      <b-dropdown-item-button
        class="cbtnTwo"
        :disabled="cartItemsCount < 1"
        @click="submitQuoteRequest"
        >SUBMIT QUOTE OR PURCHASE ORDER</b-dropdown-item-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "cart-overlay",
  data() {
    return {
      toggleDropdown: false
    };
  },

  watch: {
    cartItemsCount: function (newVal, oldVal) {
      if (this.cartItemsCount < 1) {
        this.toggleDropdown = false;
      }
    }
  },
  created: function () {
    const self = this;

    window.addEventListener("click", function (e) {
      // close dropdown when clicked outside
      if (!self.$el.contains(e.target)) {
        self.toggleDropdown = false;
      }
    });
  },
  computed: {
    ...mapGetters(["getCartItems", "cartItemsCount"])
  },
  methods: {
    ...mapActions([
      "setCartVisibility",
      "removeProduct",
      "moveToQuoteReqestPage",
      "updateCurrentCartPage",
      "setProductID"
    ]),

    showCart() {
      if (
        this.$route.path != null &&
        this.$route.path != "" &&
        !this.$route.path.includes("products")
      ) {
        this.navigateToProducts();
      }
      this.setCartVisibility(true);
      this.toggleDropdown = false;
    },

    navigateToProducts() {
      this.setProductID(null);
      const targetComponentLowerCase = "products";
      this.$router.push({ name: targetComponentLowerCase });
    },

    getSizeText(prod) {
      if (prod != null && prod != "") {
        const text = prod.productCode + " " + prod.display;
        return text.length > 50 ? text.substring(0, 50) + "..." : text;
      }
    },

    getSizeTitle(prod) {
      if (prod != null && prod != "") {
        return prod.productCode + " " + prod.display;
      }
    },

    deleteProduct(prodGroupId, prodId) {
      const prod = {
        gProductId: prodGroupId,
        productId: prodId
      };
      this.removeProduct(prod);
    },

    submitQuoteRequest() {
      if (
        this.$route.path != null &&
        this.$route.path != "" &&
        !this.$route.path.includes("products")
      ) {
        this.navigateToProducts();
      }
      this.setCartVisibility(true);
      this.toggleDropdown = false;
      this.updateCurrentCartPage(1);
    }
  }
};
</script>

<style scoped>
@import "../common.css";

.cTitle {
  font-size: 16px;
  font-weight: 700;
  white-space: pre-wrap;
}
.cSize strong,
.cQuantity strong {
  font-weight: 500;
}

.cSize span {
  font-weight: 300;
  white-space: pre-wrap;
}
.cQuantity span {
  background: #e9e9e9;
  border-radius: 3px;
  min-height: 24px;
  min-width: 30px;
  display: inline-block;
  text-align: center;
  font-weight: 300;
}
.cartItems .item .mt1 {
  margin-top: 0;
  width: 300px;
  padding-left: 2px;
}
.cartImage {
  width: 80px;
  padding-top: 8px;
}

.deleteItem i {
  color: #e94c39;
  font-size: 16px;
  cursor: pointer;
}
.cbtns {
  text-align: center;
}

.cbtnOne,
.cbtnTwo {
  display: inline-block;
  margin: 8px 10px;
}
@media (max-width: 600px) {
  .cartImage {
    width: 50px;
  }
  .cTitle,
  .cList {
    font-size: 14px;
  }
}
</style>

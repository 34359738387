<template>
  <div>
    <footer id="appFooter">
      <div>
        <p>
          © {{ currentDate.getFullYear() }} - Osmose Utilities Services, Inc.
          All Rights Reserved
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      currentDate: new Date()
    };
  }
};
</script>

<style scoped>
#appFooter {
  background: #1c3766;
  height: 37px;
  color: #fff;
  font-size: 12px;
  width: 100%;
  text-align: center;
  float: left;
  font-family: "Segoe UI";
}
footer p {
  padding-top: 8.5px;
  margin-bottom: 0px;
}
</style>

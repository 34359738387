<template>
  <div>
    <div
      v-for="(customer, index) in sortedCustomerList"
      v-bind:key="customer.customeId"
      href="#"
      id="customers"
    >
      <b-button
        block
        v-b-toggle="'collapse-' + index"
        variant="light"
        style="
          text-align: left;
          font-weight: 700;
          font-size: 14px;
          color: rgb(0, 103, 206);
          margin-right: 45px;
        "
        class="mainButtons"
      >
        {{ customer.customerName }}
        <span class="when-opened" style="right: 25px; position: absolute">
          <i class="fas fa-sort-up" style="color: rgb(0, 103, 206)"></i>
        </span>
        <span class="when-closed" style="right: 25px; position: absolute">
          <i class="fas fa-sort-down" style="color: rgb(0, 103, 206)"></i>
        </span>
      </b-button>
      <div class="collapsegroup">
        <!-- Elements to collapse -->
        <b-collapse :id="'collapse-' + index" class="mt-2">
          <div id="attributeSelectDiv">
            <b-dropdown-item
              v-for="project in customer.projects"
              v-bind:key="project.projectId"
              href="#"
              @click="handleProjectChanged(project.projectId)"
              >{{ project.projectName }}</b-dropdown-item
            >
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import { AuthorizationManager } from "@/Lib/AuthorizationManager.js";
export default {
  computed: {
    projectList() {
      return this.$store.getters.projectsWithoutGlobal;
    },
    currentProject() {
      return this.$store.getters.currentProject;
    },
    sortedCustomerList: function () {
      const sortedList = JSON.parse(
        JSON.stringify(this.$store.getters.sortedCustomerList)
      );
      if (sortedList) {
        const customers = [];
        for (let i = 0; i < sortedList.length; i++) {
          const cust = sortedList[i];
          for (let j = 0; j < cust.projects.length; j++) {
            const proj = cust.projects[j];
            if (proj.applications) {
              for (let k = 0; k < proj.applications.length; k++) {
                const application = proj.applications[k];
                if (application.capabilities) {
                  for (let l = 0; l < application.capabilities.length; l++) {
                    const filterCustomer = customers.filter(customer => {
                      return customer.customerId == cust.customerId;
                    });
                    if (filterCustomer && filterCustomer.length == 0) {
                      customers.push(cust);
                    }
                  }
                } else {
                  const appIndex = proj.applications.indexOf(application);
                  proj.applications.splice(appIndex, 1);
                }
              }
            } else {
              const projIndex = cust.projects.indexOf(proj);
              cust.projects.splice(projIndex, 1);
            }
          }
        }
        return customers;
      }
      return [];
    },
    selectedProjectName: function () {
      return this.currentProject ? this.currentProject.projectName : "";
    }
  },
  watch: {
    projectList: function (newVal, oldVal) {
      if (newVal != null) {
        for (let x = 0; x < newVal.length; x++) {
          for (let y = 0; y < newVal[x].length; y++)
            if (newVal[x][y].Default == true) {
              this.handleProjectChanged(newVal[x][y].ProjectId);
            }
        }
      }
    }
  },
  methods: {
    handleProjectChanged: function (projectID) {
      this.$store.dispatch("closeMVPopup");
      this.$store.dispatch("updateCurrentProjectById", projectID);
      // log user changed project group
      this.$store.dispatch("logger", {
        LoggingCategory: "Information",
        Action: "Changed Project Group",
        Location: this.$router.currentRoute.value.name
      });
    }
  }
};
</script>

<style scoped>
.mainButtons:focus {
  box-shadow: none !important;
  border-color: #fff !important;
  background-color: #fff !important;
}
.mainButtons:hover {
  border-color: #fff !important;
  background-color: #fff !important;
  text-decoration: none;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

#attributeSelectDiv {
  font-size: 14px;
  letter-spacing: 0;
  opacity: 1;
  font-family: "Segoe UI";
}
#customers {
  text-align: left;
  font-weight: 600;
  color: rgb(0, 103, 206);
  font-family: "Segoe UI";
  font-size: 14px;
}
</style>

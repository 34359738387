<template>
  <div id="noticeScreenLayout">
    <div id="overLay"></div>
    <div class="messageBox">
      <h2 class="title">{{ title }}</h2>
      <p class="description" v-html="description"></p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    description: String,
    overlay: { default: true }
  }
};
</script>
<style lang="scss" scoped>
#noticeScreenLayout {
  background: transparent 0% 0% no-repeat padding-box;
  background-size: cover;
  font-family: "Segoe UI";
  letter-spacing: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  height: calc(100vh - 146px);
}

#overLay {
  background-color: rgba(0, 0, 0, 0.46);
  /* height: 101.4%;
  top: -7px; */
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.messageBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin-top: 50px; */
  padding: 40px;
  max-width: 60vw;
  /* height: fit-content; */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
  color: #1c3766;
  max-height: 100%;
}

.title {
  font-size: 2rem;
}

.description {
  font-size: 1rem;
  font-weight: 500;
}

@media only screen and (max-width: map-get($max-breakpoints, small)) {
  .messageBox {
    padding: 30px;
  }
  .title {
    font-size: 1.5rem;
  }
  .description {
    font-size: 0.8rem;
  }
}
</style>

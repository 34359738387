<template>
  <div id="bottom-header-bar">
    <b-navbar fixed="top" class="head-bar" toggleable="lg" type="dark">
      <div class="ologo">
        <a href="#" id="osmoseLogo" v-on:click.prevent="navigateToHome">
          <b-navbar-brand>
            <img class="logo360" :src="osmose360LogoURL" />
          </b-navbar-brand>
        </a>
      </div>

      <b-navbar-toggle target="nav-collapse1"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <div id="project-tabs">
            <NavIcon
              v-for="(contentObj, index) in currentTargets"
              :key="index"
              :targetComponunt="contentObj.name"
              :entryPoint="contentObj.entryPoint"
              :isActive="initialOpenApp == contentObj.name"
              :id="contentObj.name + 'Nav'"
            ></NavIcon>
          </div>
        </b-navbar-nav>
      </b-collapse>

      <div class="nav-collapse-items">
        <b-collapse id="nav-collapse1" is-nav>
          <b-navbar-nav class="ml-auto">
            <div id="project-tabs-vertical">
              <NavIcon
                class="nav-flat"
                v-for="(contentObj, index) in currentTargets"
                :key="index"
                :targetComponunt="contentObj.name"
                :entryPoint="contentObj.entryPoint"
                :isActive="initialOpenApp == contentObj.name"
              ></NavIcon>
            </div>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
  </div>
</template>

<script>
import { B2CManager } from "@/Lib/B2CManager.js";
import NavIcon from "@/components/NavIcon.vue";
export default {
  mixins: [B2CManager],
  components: {
    NavIcon
  },
  props: {
    initialOpenApp: { type: String, default: "" },
    apptabs: Array
  },
  data: function () {
    return {
      homeURL: "",
      jointUseURL: "",
      /*
      currentTargets: [
        { name: "JointUse", navContent: "JointUseIcon" },
        { name: "OCalcPro", navContent: "OCalcProIcon" },
        { name: "ProjectDashboard", navContent: "ProjectDashboardIcon" },
        { name: "Osmolytics", navContent: "OsmolyticsIcon" },
        { name: "KnowledgeBase", navContent: "KnowledgeBaseIcon" },
        { name: "OsmoseUniversity", navContent: "OsmoseUniversityIcon" },
        { name: "MapView", navContent: "MapViewIcon" },
        {
          name: "ProjectAdministration",
          navContent: "ProjectAdministrationIcon"
        },

        {
          name: "Prometheus/Prometheus",
          navContent: "Prometheus/PrometheusIcon"
        }
      ], */
      currentComponentContent: "",
      contentObj: null,
      osmose360LogoURL: process.env.VUE_APP_OSMOSE360_LOGO_URL
    };
  },

  computed: {
    currentTargets: function () {
      if (this.$store.getters.userApplications) {
        return this.$store.getters.userApplications
          .filter(display => display.navIconDisplay)
          .slice()
          .sort(function (a, b) {
            return a.serialNumber - b.serialNumber;
          });
      } else {
        return this.$store.getters.userApplications;
      }
    },
    userApplications() {
      return this.$store.getters.userApplications;
    }
  },
  methods: {
    navigateToHome() {
      if (this.userApplications && this.userApplications.length == 1) {
        // if the user has access to one application only, disable going to home page
      } else {
        this.$router.push({ name: "home" });
      }
    }
  }
};
</script>

<style scoped>
.head-bar {
  height: 70px;
  background: #1c3766 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 39px;
  z-index: 100;
}

.logo360 {
  width: 200px;
  opacity: 1;
}

.ologo {
  padding-left: 160px;
}
#project-tabs {
  margin-right: 122px;
  height: 70px;
}

#project-tabs-vertical {
  height: 100%;
  width: 250px;
  background: #1c3766 0% 0% no-repeat padding-box;
}
.nav-flat {
  border-top: 0.3px solid #53555d;
  width: 100%;
}
.nav-collapse-items {
  position: absolute;
  top: 70px;
  right: 0px;
  text-align: left;
}

@media only screen and (max-width: 1675px) and (min-width: 1360px) {
  .ologo {
    padding-left: 60px;
  }
}

@media only screen and (max-width: 1359px) and (min-width: 1071px) {
  .ologo {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 1070px) {
  .ologo {
    padding-left: 5px;
  }
}

@media only screen and (max-width: 1675px) and (min-width: 1360px) {
  #project-tabs {
    margin-right: 70px;
  }
}

@media only screen and (max-width: 1359px) {
  #project-tabs {
    margin-right: 24px;
  }
}

@media only screen and (min-width: 992px) {
  #project-tabs-vertical {
    visibility: hidden;
    height: 0px;
  }
}
</style>
<style>
@media only screen and (max-width: 992px) {
  #bottom-header-bar .navbar {
    padding: 0rem 1rem !important;
  }
}
</style>

export function convertQueryFilterFormat(filters) {
  const newFilterObject = {
    Rules: []
  };
  filters.forEach(filter => {
    // check the filter type. Types are Single, Multiple
    if (filter.FilterType == "Single") {
      const thisFilter = getSingleFilter(filter);
      newFilterObject.Rules.push(thisFilter);
    } else if (filter.FilterType == "Parent") {
      getParentFilters(filter, newFilterObject);
    } else {
      getMultipleFilter(filter, newFilterObject);
    }
  });
  return newFilterObject.Rules;
}
function getSingleFilter(filter) {
  const rule = {};
  if (filter.FilterType == "Single") {
    rule.Operator = filter.Operator; // greater,less_than,equal,not_equal,etc
    rule.Type = filter.Type; // data type integer, string, datetime, etc
    rule.Field = filter.Field; // fully qualified atttribute name, i.e Location.Id
    rule.Value = filter.Value; // value to test against, needs to respect data type
    rule.Format = filter.Format;
    rule.Condition = filter.Condition;
    if (filter.LimitRule) {
      rule.LimitRule = filter.LimitRule;
    }
    if (filter.IncludeChildren) rule.IncludeChildren = filter.IncludeChildren;

    if (filter.Name) rule.Name = filter.Name;
    if (filter.Filters) {
      rule.Rules = [];
      // if(!filter.New)
      getChildFilters(rule, filter.Filters);
    }
    return rule;
  }
}
function getMultipleFilter(filter, payload) {
  const parentRule = {};
  parentRule.Rules = [];
  parentRule.LimitRule = filter.LimitRule;
  parentRule.Condition = "OR";
  payload.Rules.push(parentRule);
  filter?.Values?.forEach(value => {
    // construct rule for each value
    const childRule = {};
    childRule.Operator = filter.Operator;
    childRule.Type = filter.Type; // data type integer, string, datetime, etc
    childRule.Field = filter.Field; // fully qualified atttribute name, i.e Location.Id
    childRule.Value = value; // value to test against, needs to respect data type
    parentRule.Rules.push(childRule);
  });

  return payload;
}
function getCombinedFilter(filter, payload) {
  filter.Values.forEach(value => {
    // construct rule for each value
    const childRule = {};
    childRule.Operator = filter.Operator;
    childRule.Type = filter.Type; // data type integer, string, datetime, etc
    childRule.Field = filter.Field; // fully qualified atttribute name, i.e Location.Id
    childRule.Value = value; // value to test against, needs to respect data type
    payload.Rules.push(childRule);
  });
  return payload;
}
function getRulesFromFilter(filter) {
  const payload = {};
  payload.Rules = [];
  filter.Filters.forEach(filter => {
    // check the filter type. Types are Single, Multiple
    if (filter.FilterType == "Single") {
      const thisFilter = getSingleFilter(filter);
      payload.Rules.push(thisFilter);
    } else if (filter.FilterType == "Parent") {
      getParentFilters(filter, payload);
    } else {
      getMultipleFilter(filter, payload);
    }
  });
  return payload;
}
function getParentFilters(filter, payload) {
  const parentRule = {};
  parentRule.Rules = [];
  if (filter.Conjunction) parentRule.Condition = filter.Conjunction;
  else parentRule.Condition = "OR"; // maybe get this from filter passed in
  filter.Filters.forEach(value => {
    if (value.FormatType === "Native") {
      parentRule.Rules.push(value);
    } else if (value.FilterType == "Single") {
      const thisFilter = getSingleFilter(value);
      parentRule.Rules.push(thisFilter);
    } else if (value.FilterType == "Parent") {
      getParentFilters(value, parentRule);
    } else if (value.FilterType == "Multiple") {
      getMultipleFilter(value, parentRule);
    } else {
      getCombinedFilter(value, parentRule);
    }
  });
  // do not process the same multiple filter twice

  payload.Rules.push(parentRule);
}
function getChildFilters(rule, filters) {
  if (filters && filters.length > 0) {
    filters.forEach(filter => {
      if (filter.FilterType == "Single") {
        const thisFilter = getSingleFilter(filter);
        //thisFilter.New=true;
        rule.Rules.push(thisFilter);
        // only single types can have children
        // getChildFilters(thisFilter,filter.Filters);
      } else if (filter.FilterType == "Parent") {
        getParentFilters(filter, rule);
      } else {
        getMultipleFilter(filter, rule);
      }
    });
  }
}

/********************************************
 * AuthorizationManager.js
 ******************************************
 * Functions to Retrieve Authorizaion Data
 ********************************************
 * Methods:
 * getApplicationRegistry
 *
 ********************************************/

//////////////////////// Imports /////////////////
import Axios from "axios";
import Store from "@/store/store";

////////////////////// Public Methods ////////////
export default {
  /************************************
   * Retrieve the Application Registry
   ************************************/
  getConfiguration: function (URL, authToken, callback, callbackName) {
    Axios.get(
      URL,
      authToken
        ? {
            headers: { Authorization: `Bearer ${authToken}` }
          }
        : undefined
    )

      .then(response => {
        callback(callbackName, response.data);
      })
      .catch(error => {
        console.dir(error);

        let msg = `Could not retrieve your project information. Please try again.`;
        if (error.response && error.response.status == 401)
          msg = `Could not retrieve your project information. User unauthorised.`;
        this.displayErrorMessage("getConfiguration", msg, error);
      });
  },
  displayErrorMessage: function (request, msg, error) {
    const errorObj = {
      requestName: request,
      errorMessage: msg,
      Error: error
    };
    Store.dispatch("updateError", errorObj);
  },
  createAuthInfo: function (
    userAuthObject,
    appRegistry,
    dispatch,
    callbackName
  ) {
    // using a set to make sure there are no duplicate Applications
    const userApplications = new Set();
    const appCapabilities = {};
    const customersAndProjects = [];
    userAuthObject.customers.forEach(customer => {
      customersAndProjects.push(customer);
      customer.projects.forEach(project => {
        if (project.projectName != "Test Harness") {
          if (project && Array.isArray(project.applications)) {
            project.applications.forEach(application => {
              const theApplication = getApplication(application, appRegistry);
              if (theApplication) {
                appCapabilities[theApplication.name.split("/").pop()] =
                  application.capabilities;
                userApplications.add(theApplication);
                if (
                  !application.capabilities ||
                  (application.capabilities &&
                    application.capabilities.length == 0)
                ) {
                  Store.dispatch("logger", {
                    LoggingCategory: "Information",
                    Action: "Permission Check",
                    Message: `This project ${project.projectName} was not configured properly`
                  });
                }
              } else {
                Store.dispatch("logger", {
                  LoggingCategory: "Information",
                  Action: "Permission Check",
                  Message: `This project ${project.projectName} was not configured properly`
                });
              }
            });
          } else {
            Store.dispatch("logger", {
              LoggingCategory: "Information",
              Action: "Permission Check",
              Message: `This project ${project.projectName} was not configured properly`
            });
          }
        }
      });
    });
    const authInfo = {
      UserApplications: Array.from(userApplications),
      AppCapabilities: appCapabilities,
      Projects: customersAndProjects,
      HasUserTakenTour: userAuthObject.hasUserTakenTour
    };
    console.log(authInfo);
    dispatch(callbackName, authInfo);
  },

  getServiceTypeData(authToken, callback, callbackName) {
    const serviceTypeURL = process.env.VUE_APP_SERVICE_TYPE_DISPLAY_NAME;
    Axios.get(serviceTypeURL, {
      headers: { Authorization: `Bearer ${authToken}` }
    })
      .then(result => {
        callback(callbackName, result.data);
      })
      .catch(error => {
        console.log("There was an error:");
        console.dir(error);
        const errorObj = {
          requestName: "Get Project Service Type Data",
          errorMessage:
            "Could not retrieve project service type data. Please try again. If this error continues to occur, please contact support.",
          Error: error
        };
        Store.dispatch("updateError", errorObj);
      });
  },
  getStatesMapping(authToken, callback, callbackName) {
    const statesMappingURL = process.env.VUE_APP_GET_STATES_MAPPING;
    Axios.get(
      statesMappingURL /*, {
        headers: { Authorization: `Bearer ${authToken}` }
      }*/
    )
      .then(result => {
        callback(callbackName, result.data);
      })
      .catch(error => {
        console.log("There was an error:");
        console.dir(error);
        const errorObj = {
          requestName: "Get States Mapping Data",
          errorMessage:
            "Could not retrieve states mapping. Please try again. If this error continues to occur, please contact support.",
          Error: error
        };
        Store.dispatch("updateError", errorObj);
      });
  }
};
function getApplication(userApplication, appRegistry) {
  let theApplication;
  appRegistry.forEach(application => {
    if (application.name.split("/").pop() == userApplication.applicationName) {
      theApplication = application;
      return;
    }
  });
  return theApplication;
}

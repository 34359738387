<template>
  <div>
    <v-tour :steps="steps" :options="myOptions" name="dashboardTour">
      <template #default="tour">
        <transition name="fade" mode="out-in">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :highlight="tour.highlight"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            style="background-color: #ffffff; color: #0067ce"
          >
            <template #actions>
              <div class="flex items-center justify-center">
                <button
                  v-if="tour.currentStep === 0"
                  class="ml-4 btn twoButtons"
                  @click="customSkip(tour.skip)"
                >
                  Skip Tour
                </button>
                <button
                  v-if="tour.currentStep > 0"
                  :class="
                    tour.currentStep === steps.length - 1
                      ? 'ml-4 btn twoButtons'
                      : 'ml-4 btn btnRounded'
                  "
                  @click="customPreviousStep(tour.currentStep)"
                >
                  Previous
                </button>
                <button
                  v-if="tour.currentStep !== steps.length - 1"
                  :class="
                    tour.currentStep == 0
                      ? 'ml-4 btn twoButtons'
                      : 'ml-4 btn btnRounded'
                  "
                  @click="customNextStep(tour.currentStep)"
                >
                  Next
                </button>
                <button
                  v-if="
                    tour.currentStep !== steps.length - 1 &&
                    tour.currentStep != 0
                  "
                  class="ml-4 btn btnRounded exitButton"
                  @click="customExitTour(tour.finish)"
                >
                  Exit Tour
                </button>
                <button
                  v-if="tour.currentStep === steps.length - 1"
                  class="ml-4 btn twoButtons"
                  @click="customFinishStep(tour.finish)"
                >
                  Finish
                </button>
              </div>
            </template>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>
<script>
import Axios from "axios";
export default {
  data: function () {
    return {
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "Skip Tour",
          buttonPrevious: "Previous",
          buttonNext: "Next",
          buttonStop: "Finish"
        }
      },
      sendToServer: true,
      steps: [
        {
          target: "#tourContainer",
          content: `This will be replaced in the render step, just can't be blank`
        }
      ]
    };
  },
  computed: {
    currentTargets: function () {
      if (this.$store.getters.userApplications) {
        return this.$store.getters.userApplications;
      } else {
        return this.$store.getters.userApplications;
      }
    },
    userRegistered() {
      return (
        (this.currentTargets == null || this.currentTargets.length > 0) &&
        (this.userCustomersLength == null || this.userCustomersLength > 0)
      );
    },
    isCustomer() {
      return this.$store.getters.isCustomer;
    },
    userHasTakenTour() {
      return this.$store.getters.hasUserTakenTour;
    },
    retakeTour() {
      return this.$store.getters.retakeTour;
    }
  },
  watch: {
    retakeTour: function () {
      this.sendToServer = false;
      const applicationNameArray = [];
      for (let i = 0; i < this.currentTargets.length; i++) {
        applicationNameArray.push(this.currentTargets[i].name);
      }
      this.renderApplicationTourSteps(applicationNameArray);
    }
  },
  mounted: function () {
    const hasUserTakenTour = this.$store.getters.hasUserTakenTour;
    if (
      this.userRegistered != null &&
      this.userRegistered &&
      hasUserTakenTour != null &&
      !hasUserTakenTour
    ) {
      const applicationNameArray = [];
      if (this.currentTargets != null) {
        for (let i = 0; i < this.currentTargets.length; i++) {
          applicationNameArray.push(this.currentTargets[i].name);
        }
      }
      this.renderApplicationTourSteps(applicationNameArray);
    }
  },
  methods: {
    renderApplicationTourSteps(applicationNameArray) {
      this.steps = [];
      const firstStep = {
        target: "#tourContainer",
        content: `Welcome to Osmose 360! Would you like a feature tour?`,
        params: {
          enableScrolling: false
        }
      };
      this.steps.push(firstStep);
      const secondStep = {
        target: "#tourContainer",
        content: `This is your home page, where you can access many of the features of Osmose 360.`,
        params: {
          enableScrolling: false
        }
      };
      this.steps.push(secondStep);
      const thirdStep = {
        target: ".logo360",
        content: `You can always return to this page by clicking the Osmose 360 logo.`,
        params: {
          highlight: true,
          placement: "right"
        }
      };
      this.steps.push(thirdStep);
      let navbarStep = {};
      if (window.innerWidth > 992) {
        navbarStep = {
          target: ".nav-cont",
          content: `Navigate to different pages by clicking on the icon in the heading.`,
          params: {
            placement: "left"
          }
        };
      } else {
        navbarStep = {
          target: ".head-bar",
          content: `Navigate to different pages by clicking on the icon in the heading.`,
          params: {
            placement: "right"
          }
        };
      }
      this.steps.push(navbarStep);
      if (applicationNameArray.includes("ProjectDashboard")) {
        const projectDashboardStep = {
          target: "#ProjectDashboard",
          content: `On the Project Dashboard, view status of the work Osmose is conducting for your company, key performance indicators, and financial information including invoices.`,
          params: {
            placement: "left",
            highlight: true,
            enableScrolling: false
          }
        };
        this.steps.push(projectDashboardStep);
      }
      if (this.currentTargets) {
        for (let i = 0; i < this.currentTargets.length; i++) {
          if (this.currentTargets[i].alias == "Map View") {
            let mapViewStep = {};
            if (window.innerWidth > 992) {
              mapViewStep = {
                target: "#MapViewNav",
                content: `Use the Map View to see individual structure information collected by our field crews. Here you can filter, export data, and view images.`,
                params: {
                  highlight: true,
                  enableScrolling: false
                }
              };
            } else {
              mapViewStep = {
                target: ".head-bar",
                content: `Use the Map View to see individual structure information collected by our field crews. Here you can filter, export data, and view images.`,
                params: {
                  placement: "right",
                  enableScrolling: false
                }
              };
            }

            this.steps.push(mapViewStep);
            break;
          }
        }
      }

      if (applicationNameArray.includes("OsmoseServices")) {
        const osmoseServicesStep = {
          target: "#OsmoseServices",
          content: `View all the services Osmose is providing for you, and those that you may not be taking advantage of currently.`,
          params: {
            placement: "right",
            highlight: true,
            enableScrolling: false
          }
        };
        this.steps.push(osmoseServicesStep);
      }
      if (applicationNameArray.includes("KnowledgeBase")) {
        const knowledgeBaseStep = {
          target: "#KnowledgeBase",
          content: `Search a knowledge base of industry standards, educational information, and best practices - including videos and images gathered from Osmose's years of industry experience.`,
          params: {
            placement: "left",
            highlight: true,
            enableScrolling: false
          }
        };
        this.steps.push(knowledgeBaseStep);
      }
      if (applicationNameArray.includes("OsmoseUniversity")) {
        const osmoseUniversityStep = {
          target: "#OsmoseUniversity",
          content: `View upcoming training opportunities and sign up for webinars and live events.`,
          params: {
            placement: "right",
            highlight: true,
            enableScrolling: false
          }
        };
        this.steps.push(osmoseUniversityStep);
      }
      if (applicationNameArray.includes("JointUse")) {
        const jointUseStep = {
          target: "#JointUse",
          content: `Check the status of your make-ready survey and design projects.`,
          params: {
            placement: "right",
            highlight: true,
            enableScrolling: false
          }
        };
        this.steps.push(jointUseStep);
      }
      if (applicationNameArray.includes("Products")) {
        const productsStep = {
          target: "#Products",
          content: `Take advantage of the many products Osmose offers to protect, preserve, repair and restore your stuctures and their attachments.`,
          params: {
            placement: "right",
            highlight: true,
            enableScrolling: false
          }
        };
        this.steps.push(productsStep);
      }

      const finalStep = {
        target: "#tourContainer",
        content: `We value your feedback! We're working hard to provide all the tools needed to monitor your Osmose services. Send us your ideas so we can continue to improve your experience.`,
        params: {
          enableScrolling: false
        }
      };

      this.steps.push(finalStep);
      this.$tours["dashboardTour"].start();
      window.scrollTo(0, 0);
      document.body.style["overflow"] = "hidden";
    },
    customNextStep(currentStep) {
      const nextStep = currentStep + 1;
      const step = this.steps[nextStep];
      const target = step.target;
      const targetName = target.substring(1);
      if (target != "#tourContainer" && !target.includes(".")) {
        let targetElement = this.$parent.$refs[targetName];
        if (targetElement != undefined) {
          targetElement[0].$el.scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
        } else {
          targetElement = target;
        }
      } else if (target === "#tourContainer") {
        const element = document.getElementById("bottom-header-bar");
        element.scrollIntoView({ behavior: "smooth" });
      }
      this.$tours["dashboardTour"].nextStep();
      document.body.style["pointer-events"] = "none";
      document.body.style["overflow"] = "hidden";
      return false;
    },
    customPreviousStep(currentStep) {
      const nextStep = currentStep - 1;
      if (nextStep == 0) {
        document.body.style["pointer-events"] = "auto";
      }
      const step = this.steps[nextStep];
      const target = step.target;
      const targetName = target.substring(1);
      if (target != "#tourContainer" && !target.includes(".")) {
        let targetElement = this.$parent.$refs[targetName];
        if (targetElement != undefined) {
          targetElement[0].$el.scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
        } else {
          targetElement = target;
        }
      } else if (target === "#tourContainer") {
        const element = document.getElementById("bottom-header-bar");
        element.scrollIntoView({ behavior: "smooth" });
      }
      this.$tours["dashboardTour"].previousStep();
      return false;
    },
    customExitTour(currentStep) {
      this.$store.dispatch("updateHasUserTakenTour", true);
      this.$tours["dashboardTour"].stop();
      document.body.style["pointer-events"] = "auto";
      document.body.style["overflow"] = "visible";
    },
    customFinishStep(currentStep) {
      this.$tours["dashboardTour"].stop();
      this.changeTakenTour();
      document.body.style["pointer-events"] = "auto";
      document.body.style["overflow"] = "visible";
    },
    customSkip(currentStep) {
      this.$tours["dashboardTour"].stop();
      this.changeTakenTour();
      document.body.style["pointer-events"] = "auto";
      document.body.style["overflow"] = "visible";
    },
    changeTakenTour() {
      if (this.sendToServer) {
        const URL = process.env.VUE_APP_USER_TAKEN_TOUR;
        const authToken = this.$store.getters.authToken;
        Axios.get(URL, {
          headers: { Authorization: `Bearer ${authToken}` }
        })
          .then(response => {
            this.$store.dispatch("updateHasUserTakenTour", true);
            return response;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.$store.dispatch("updateHasUserTakenTour", true);
      }
    }
  }
};
</script>
<style scoped>
.twoButtons {
  left: 20% !important;
  margin-left: 3rem !important;
  font-weight: 600;
  font-size: 10.3px;
  background: #0067ce;
  border-color: #0067ce;
  color: white;
  border-radius: 20px;
  border-width: 2px;
  float: left;
  width: 72px;
  height: 24px;
  font-family: "Segoe UI";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 72px !important;
}
.twoButtons:hover {
  background: white;
  color: #0067ce !important;
}
.exitButton {
  font-size: 10.3px !important;
}
.btnRounded {
  left: 0%;
  font-weight: 600;
  font-size: 11.2px;
  background: #0067ce;
  border-color: #0067ce;
  color: white;
  border-radius: 20px;
  border-width: 2px;
  float: left;
  width: 72px;
  height: 24px;
  font-family: "Segoe UI";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 72px !important;
}
.btnRounded:hover {
  background: white;
  color: #0067ce !important;
}
</style>

<style>
.v-step {
  /* background-color: cyan !important; */
  box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, 0.5) !important;
}
.v-step__arrow {
  /* background-color: black !important; */
  box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, 0.5) !important;
}

.v-step[data-popper-placement^="right"] > .v-step__arrow {
  left: -10px !important;
}

.v-step[data-popper-placement^="left"] > .v-step__arrow {
  right: 0px !important;
}
.v-tour__target--highlighted {
  box-shadow: 0 0 0 4px rgba(0, 103, 206, 1) !important;
}
.v-step[x-placement^="top"] .v-step__arrow {
  border-left-color: transparent !important;
  border-top-color: white !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}

.v-step[x-placement^="bottom"] .v-step__arrow {
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: white !important;
}

.v-step[x-placement^="right"] .v-step__arrow {
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-right-color: white !important;
  border-bottom-color: transparent !important;
}

.v-step[x-placement^="left"] .v-step__arrow {
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-left-color: white !important;
  border-bottom-color: transparent !important;
}
</style>

import { defineStore } from "pinia";

export const useResiliencyDetailsStore = defineStore("ResiliencyDetailsStore", {
  state: (): {
    SelectedResiliencyId: string | number;
    ResiliencyDetails: any;
    ForceFlyoutToExpand: boolean;
    ThresholdLoadPercentage: number;
    ReplacementCost: number;
    SolutionTypeRenderers: object;
    InstalledSolutionTypeRenderers: object;
    ResiliencyWidgetResults: object;
  } => {
    return {
      SelectedResiliencyId: "",
      ResiliencyDetails: {},
      ForceFlyoutToExpand: false,
      ThresholdLoadPercentage: 100,
      ReplacementCost: 0,
      SolutionTypeRenderers: {},
      InstalledSolutionTypeRenderers: {},
      ResiliencyWidgetResults: {}
    };
  },
  getters: {
    getSelectedResiliencyId(state) {
      return state.SelectedResiliencyId;
    },
    getResiliencyDetails(state): any {
      let resultdata = removeSolutionCost(state.ResiliencyDetails);
      // const estimatedLoad = resultdata["OUS_POLELOADING"][0].find(
      //   (item: any) => Object.keys(item)[0] === "EstimatedLoad"
      // );
      // const resiliencyStatus =
      //   estimatedLoad["EstimatedLoad"] > this.getThresholdLoadPercentage
      //     ? "Overloaded"
      //     : "Underloaded";
      // resultdata["OUS_POLELOADING"][0].forEach((item: any) => {
      //   if (Object.keys(item)[0] === "PreResiliencyStatus") {
      //     item["PreResiliencyStatus"] = resiliencyStatus;
      //   }
      // });
      resultdata = calculateAvoidedCost(
        resultdata,
        state.ReplacementCost,
        state.SolutionTypeRenderers
      );

      return resultdata;
    },
    getResiliencyTableNamesObj(): any {
      const resiliencyDetails = this.getResiliencyDetails;
      if (resiliencyDetails !== null && resiliencyDetails !== undefined) {
        const tableNamesWithAliases = formatTableNames(
          Object.entries(resiliencyDetails)
        );
        const tableNamesOrdered = sortTableNames(
          Object.keys(resiliencyDetails)
        );
        return {
          tableNamesFormated: tableNamesWithAliases,
          tableNamesOrder: tableNamesOrdered
        };
      } else {
        return null;
      }
    },
    getThresholdLoadPercentage(state): number {
      return state.ThresholdLoadPercentage;
    },
    getSolutionTypeRenderers(state): any {
      return state.SolutionTypeRenderers;
    }
  },
  actions: {
    updateResiliencyDetails(data: any) {
      this.ResiliencyDetails = data[0];
    },
    updateSelectedResiliencyId(data: any) {
      this.SelectedResiliencyId = data;
    },
    TableData(tableName: any) {
      const resiliencyDetails = this.getResiliencyDetails;
      const data = resiliencyDetails[tableName];
      const result: any = {};
      if (data !== undefined) {
        data[0].forEach((item: any) => {
          const key = Object.keys(item)[0];
          const value = item[key];
          result[formateColumnName(key)] = value;
        });
      }
      return result;
    },
    updateThresholdLoadPercentage(data: number) {
      this.ThresholdLoadPercentage = data;
    },
    updateReplacementCost(data: number) {
      this.ReplacementCost = data;
    },
    updateSolutionTypeRenderers(data: object) {
      this.SolutionTypeRenderers = data;
    },
    updateInstalledSolutionTypeRenderers(data: object) {
      this.InstalledSolutionTypeRenderers = data;
    }
  }
});

// ** helper functions **//
function formatTableNames(tableEntires: any) {
  // tableNamesFormated elements has the following format:
  // {tableName: tableNameAlias}, ex {OUS_Maintenance: "Maintenance (3)"}
  const tableNamesFormated: any = {};
  for (const [key, value] of tableEntires) {
    const tableName = key.length >= 18 ? key.slice(0, 18) + "..." : key;
    tableNamesFormated[key] =
      tableName.substring(4, key.length) +
      (value.length > 1 ? " (" + value.length + ")" : "");
  }
  return tableNamesFormated;
}

function sortTableNames(tableNames: any) {
  /* current order:
        Structure, Location, Resiliency, [rest is ordered alphabetically]
    */
  let tableNamesOrdered: any[] = [];
  if (tableNames.includes("OUS_STRUCTURE")) {
    tableNamesOrdered.push("OUS_STRUCTURE");
    tableNames.splice(tableNames.indexOf("OUS_STRUCTURE"), 1);
  }
  if (tableNames.includes("OUS_LOCATION")) {
    tableNamesOrdered.push("OUS_LOCATION");
    tableNames.splice(tableNames.indexOf("OUS_LOCATION"), 1);
  }
  if (tableNames.includes("OUS_POLELOADING")) {
    tableNamesOrdered.push("OUS_POLELOADING");
    tableNames.splice(tableNames.indexOf("OUS_POLELOADING"), 1);
  }
  if (tableNames.length) {
    tableNames = tableNames.sort((a: any, b: any) => {
      if (a.substring(4, a.length) < b.substring(4, b.length)) {
        return -1;
      }
      if (a.substring(4, a.length) > b.substring(4, b.length)) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });
    tableNamesOrdered = tableNamesOrdered.concat(tableNames);
  }
  return tableNamesOrdered;
}

function formateColumnName(input: string): string {
  return input.replace(/([a-z])([A-Z])/g, "$1 $2");
}
function removeSolutionCost(data: any): any {
  // Remove solution cost
  let index = 0;
  if (data["OUS_POLELOADING"] !== undefined) {
    data["OUS_POLELOADING"][0].forEach((item: any) => {
      if (Object.keys(item)[0] === "SolutionCost") {
        data["OUS_POLELOADING"][0].splice(index, 1);
      }
      index++;
    });
  }

  return data;
}

function calculateAvoidedCost(
  data: any,
  replacementcost: number,
  solutionTypeRenderers: any
) {
  // Avoided Cost calculation [Replacement Cost - Solution Cost]
  if (data["OUS_POLELOADING"] !== undefined) {
    const solutionType = data["OUS_POLELOADING"][0].find(
      (item: any) => Object.keys(item)[0] === "SolutionType"
    )["SolutionType"];

    const solutionCost = solutionTypeRenderers.uniqueValueInfos.find(
      (x: any) => x.value === solutionType
    )?.cost;
    let avoidedCost = replacementcost - solutionCost;
    avoidedCost = avoidedCost < 0 ? 0 : avoidedCost;
    if (solutionType === "None") {
      avoidedCost = 0;
    }
    data["OUS_POLELOADING"][0].forEach((item: any) => {
      if (Object.keys(item)[0] === "AvoidedCost") {
        item["AvoidedCost"] = avoidedCost;
      }
    });
  }

  return data;
}

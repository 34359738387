<template>
  <div>
    <div>
      <div class="header">
        <nav class="navbar navbar-expand-lg navbar-dark top-bar">
          <a class="navbar-brand logobar" href="">
            <img class="o360logo" :src="osmose360LogoURL" />
          </a>
          <div
            class="collapse navbar-collapse justify-content-end"
            id="navbarToggleExternalContent"
          ></div>
        </nav>
      </div>
    </div>
    <div class="home-container">
      <div id="slogan">
        <p class="slogan-text">Better</p>
        <p class="slogan-title">predict, manage & optimize</p>
        <p class="slogan-text">your utility structures</p>
      </div>
      <!-- <div id="notice-box">
        <h6 id="notice-title">Important notice from Osmose360:</h6>
        <div id="notice-div"></div>
      </div> -->

      <div id="logInMenu">
        <SignupForm></SignupForm>
      </div>

      <div id="bottom-box">
        <div id="intro">
          <p id="intro-title"></p>
        </div>
        <div id="tabs">
          <div class="btabs">
            <img class="iconApps" :src="mapViewLogoURL" />
            <div class="textApps">
              <p class="libIntro">Map View</p>
              <p class="title">
                Built on ESRI ArcGIS, the Map View tool provides structure data
                using geospatial maps with layering options.
              </p>
            </div>
          </div>
          <div class="btabs">
            <img class="iconKB" :src="knowledgeBaseLogoURL" />
            <div class="textApps">
              <p class="libIntro">Knowledge Base</p>
              <p class="title">
                Industry standards and knowledge, educational information, and
                best practices all in one location.
              </p>
            </div>
          </div>

          <div class="btabs">
            <img class="iconApps" :src="projectDashboardLogoURL" />
            <div class="textApps">
              <p class="libIntro">Project Dashboard</p>
              <p class="title">
                Display key performance indicators, including structure data,
                completion of work, and financials.
              </p>
            </div>
          </div>
          <div class="btabs">
            <img class="iconApps" :src="osmoseUniveristyLogoURL" />
            <div class="textApps">
              <p class="libIntro">Osmose University</p>
              <p class="title">
                Register for in-person training events, on-site educational
                seminars, and live informative webinars.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { B2CManager } from "@/Lib/B2CManager.js";

export default {
  mixins: [B2CManager],
  props: {
    title: String
  },
  data: function () {
    return {
      homeURL: "/home",
      osmose360LogoURL: process.env.VUE_APP_OSMOSE360_LOGO_URL,
      mapViewLogoURL: process.env.VUE_APP_MAP_VIEW_LOGO_URL,
      knowledgeBaseLogoURL: process.env.VUE_APP_KNOWLEDGE_BASE_LOGO_URL,
      projectDashboardLogoURL: process.env.VUE_APP_PROJECT_DASHBOARD_LOGO_URL,
      osmoseUniveristyLogoURL: process.env.VUE_APP_OSMOSE_UNIVERSITY_LOGO_URL
    };
  },
  methods: {}
};
</script>

<style>
/********************************** home container  *******************************/
.home-container {
  height: calc(100vh - 70px);
  min-height: 847px;
  position: relative;
  background-color: rgba(59, 59, 59, 0.4);
  display: grid;
  grid-template-columns: 0.5fr 1.5fr 324px 0.5fr;
  grid-template-rows: 0.5fr 0.75fr 1.5fr 320px;
}

.home-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("https://osmose360content.blob.core.windows.net/osmose360/NewLogInBackground.jpg");
  background-size: cover;
}

/********************************** navigation bar *******************************/
.header {
  display: grid;
  grid-template-columns: 0.5fr 1.5fr 324px 0.5fr;
  height: 70px;
  background: #1c3766 0% 0% no-repeat padding-box;
  opacity: 1;
}

.top-bar {
  grid-column-start: 2;
  grid-column-end: end;
  height: 70px;
  padding-left: 0px !important;
}

.o360logo {
  width: 185px;
  opacity: 1;
}

.logobar {
}

#userNameSelButton {
  margin-right: 280px;
  border-radius: 0px;
}

#userNameSelButton:hover {
  background-color: transparent;
}

/********************************** Slogan  *******************************/
#slogan {
  text-align: left;
  color: #ffffff;
  line-height: 160%;
  font-family: "Segoe UI";
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  padding-right: 10px;
  /* margin-top: 100px; */
}

/********************************** Notice box  *******************************/
/* #notice-box {
  text-align: left;
  font-family: "Segoe UI";
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 5;
  background-color: white;
  border-color: red;
  border-style: solid;
  border-width: 3px;
  color: black;
  margin: 10px 20px 10px 0px;
  padding: 7px;
  display: none;
  height: fit-content;
}

#notice-title {
  color: red;
}

#notice-div {
  font-size: 14px;
} */

/********************************** Products box  *******************************/
#bottom-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #1c3766;
  /* height: 260px; */
  grid-column-start: 2;
  grid-column-end: end;
  grid-row-start: 4;
  grid-row-end: end;
  align-self: end;
  justify-self: end;
  padding: 0px 100px 0px 100px;
}

.slogan-text {
  font-size: 34px;
  letter-spacing: 0;
  margin-bottom: 5px;
}

.slogan-title {
  line-height: 110%;
  font-size: 36px;
  letter-spacing: 0;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 2px;
}

#intro {
  text-align: left;
  margin-left: 50px;
  margin-top: 20px;
  line-height: normal;
  font-family: "Segoe UI";
}

#tabs {
  /* margin-left: 35px; */
  padding-top: 25px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.btabs {
  display: flex;
  flex-direction: column;
  height: 210px;
  width: 200px;
  /* float: left; */
  margin: 5px 20px;
  padding-top: 7px;
  padding-left: 5px;
}

#intro-text {
  font-size: 20px;
  letter-spacing: 0.6px;
  color: #1c3766;
  letter-spacing: 0.6px;
}

#intro-title {
  font-size: 20px;
  text-transform: uppercase;
  font-style: italic;
  letter-spacing: 0.6px;
  color: #1c3766;
  font-weight: bold;
  margin-bottom: 0px !important;
}

.iconApps {
  height: 42px;
  width: 48px;
  float: left;
  margin-bottom: 12px;
}

.iconKB {
  height: 42px;
  width: 48px;
  float: left;
  margin-bottom: 12px;
  padding-right: 10px;
}

.textApps {
  float: left;
  color: #1c3766;
  font-size: 16px;
  font-family: "Segoe UI";
}

.libIntro {
  text-align: left;
  font-weight: bold;
}

.title {
  font-size: 14px;
  font-family: "Segoe UI";
  text-align: left;
  margin-bottom: 10px;
  color: #1c3766;
  line-height: normal;
}

.lmore {
  line-height: 32px;
  text-align: left;
  text-decoration: underline;
  font-size: 14px;
  font-family: "Segoe UI";
  letter-spacing: 0.32px;
}

.top-bar .dropdown-menu {
  border-radius: 0px;
  background-color: #e9e9e9;
}

/********************************** log in box *******************************/
#logIn {
  position: relative;
  font-family: "Segoe UI";
  text-align: right;
  font-weight: Bold;
  font-size: 10px;
  letter-spacing: 0;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
}

#logInMenu {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;
  position: absolute;
  width: 324px;
  height: 430px;
  text-align: center;
  font-family: "Segoe UI";
  background-color: #e9e9e9;
  z-index: 1;
}

#api {
  width: 278px;
  height: 333px;
  background: #e9e9e9;
}

#devAAD {
  position: relative;
  top: 202px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  border: none;
  background-color: #e9e9e9;
  color: #e9e9e9;
  width: 276px;
  height: 35px;
  font-weight: 600;
  border-radius: 0px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 23px;
}

#devAAD::after {
  content: "Osmose Employee";
  display: block;
  background-color: #7ca5d0;
  color: white;
  position: relative;
  top: -25px;
  left: -6px;
  width: 276px;
  height: 35px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0px;
  border: none;
  padding-top: 6px;
}

#createAccountDiv {
  position: relative;
  top: -68px;
  width: 276px;
  margin-left: 26px;
  margin-right: 26px;
}

#createAccountBtn {
  background-color: #007bff;
  color: white;
  width: 276px;
  height: 35px;
  padding-top: 6px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0px;
  text-decoration: none;
}

#header-text {
  font-size: 20px;
  font-family: "Segoe UI";
  color: #1c3766;
  padding-left: 22px;
  padding-top: 12px;
  font-weight: 700;
  text-align: left;
  letter-spacing: 0;
  margin-bottom: 0px;
}

.intro {
  display: none;
}

.create {
  display: none;
}

.divider {
  display: none;
}

.intro {
  display: none;
}

label[for="logonIdentifier"] {
  display: none;
}

.social {
  height: 35px;
}

#logonIdentifier {
  width: 276px;
  border-radius: 0px;
  border: solid 2px lightgray;
  height: 35px;
  font-size: 14px;
  padding-left: 8px;
  outline: none;
  margin-left: 23px;
}

#password {
  width: 276px;
  border-radius: 0px;
  border: solid 2px lightgray;
  height: 35px;
  font-size: 14px;
  padding-left: 8px;
  position: relative;
  top: -18px;
  outline: none;
  margin-left: 23px;
}

label[for="password"] {
  display: none;
}

#next {
  width: 276px;
  height: 35px;
  font-size: 14px;
  font-weight: 600;
  background-color: #1c3766;
  color: white;
  border-radius: 0px;
  border: none;
  position: relative;
  top: 30px;
  outline: none;
  cursor: pointer;
  margin-left: 23px;
}

.password-label {
  position: relative;
  top: 60px;
  text-align: right;
  padding-right: 30px;
}

#forgotPassword {
  display: block;
  font-size: 14px;
  position: relative;
  bottom: 19px;
  left: 155px;
  width: 140px;
  font-style: italic;
  outline: none;
}

#need-help {
  position: absolute;
  left: 27px;
  top: 155px;
  font-size: 12px;
}

#newToOsmose-title {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #979797;
  line-height: 0.1em;
  margin: 10px 0 10px;
  font-size: 12px;
  color: #828181;
}

#newToOsmose-title span {
  padding: 0 10px;
  background: #e9e9e9;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 600;
}

#newToOsmose-text {
  font-size: 10px;
  text-align: left;
  color: #979797;
  margin-bottom: 12px;
}

.error.itemLevel,
.error.pageLevel {
  position: absolute;
  top: 42px;
  left: 25px;
  font-size: 13px;
  font-family: Segoe UI;
  color: #ffffff;
  width: 276px;
  background-color: #f76868;
  font-weight: 500;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
}

.error.itemLevel p,
.error.pageLevel p {
  margin-bottom: 0px;
}

.highlightError {
  border: solid 1px #f76868 !important;
}

@media only screen and (max-width: 1367px) {
  #bottom-box {
    grid-column-start: 1;
    justify-self: auto;
    padding: 0px;
  }
}

@media only screen and (max-width: 991.98px) {
  .home-container {
    grid-template-columns: 0.75fr 1.5fr 324px 0.75fr;
    grid-template-rows: 50px 530px 50px 0.5fr 2fr;
  }

  /* #notice-box {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 5;
    margin: 0px 0px 10px 0px;
  } */

  #bottom-box {
    grid-row-start: 5;
    grid-row-end: 6;
  }
}

@media only screen and (max-width: 575.98px) {
  #slogan {
    display: none;
  }

  #logInMenu {
    grid-column-start: 2;
    grid-column-end: 4;
    justify-self: center;
  }
}

/* @media only screen and (max-height: 767.98px) {
  #slogan {
    scale: 0.8;
  }

  .btabs {
    height: 100px;
  }

  #bottom-box {
    height: 200px;
  }

  #bottom-box#intro {
    margin: 0px;
  }

  #bottom-box#tabs {
    padding-top: 0px;
  }

  .title {
    display: none;
    background: #f8f3f3;
    position: relative;
    bottom: 117px;
    width: 150px;
    padding: 9px;
    right: -67px;
    font-size: 14px;
    font-family: "Segoe UI";
    color: #1c3766;
    z-index: 5;
  }

  .btabs:hover .title {
    display: block !important;
  } 
}*/
</style>

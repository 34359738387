<template>
  <div class="card-cont">
    <Component :is="cardContent" :navigate="navigate"></Component>
    <!-- <button id="component-button" v-on:click="navigate()">{{targetComponunt}}</button> -->
  </div>
</template>

<script>
import CardNavBase from "./CardNavBase.vue";
export default {
  extends: CardNavBase,
  props: ["targetComponunt", "entryPoint"],
  methods: {},
  computed: {
    cardContent() {
      return () => import(`../components/dynamic/${this.entryPoint}Content`);
      // import(
      //   `../components/dynamic/${this.targetComponunt
      //     .split("/")
      //     .pop()}Content`
      // );
    }
  }
};
</script>
<style scoped>
.card-cont {
  background-color: #ffffff;
  height: 180px;
  width: 430px;
  float: left;
  margin: 3px 10px 25px 10px;
  border-radius: 3px;
}
</style>

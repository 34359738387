import { O360CosmosDataSource } from "@/Lib/SharedComponents/mapControl/cosmosAPI/O360CosmosDataSource";

import { executeQuery, getCounts } from "@/Lib/Database/queryManager";

export class O360MapControlDataSource extends O360CosmosDataSource {
  override async runQuery(): Promise<any[]> {
    let finalResult: any[] = [];

    if (this.payload.Threashold) {
      const threasholdCount: any = await getCounts(this.payload);
      const threasholdQueryResult =
        threasholdCount.AllNumber > this.payload.Threashold ? true : false;
      const threasholdResult: any = [
        {
          threasholdExceeded: threasholdQueryResult,
          structureCount: threasholdCount.AllNumber
        }
      ];

      return threasholdResult;
    }

    const chunkResult = await executeQuery(this.payload);
    if (chunkResult.data.Status == false) {
      console.log("chunk result query failed");
      console.log(chunkResult.data.Messages);
      return [];
    }

    finalResult = finalResult.concat(chunkResult.data.Results);

    for (let x = 0; x < this.registry.length; x++)
      await this.registry[x].addData(finalResult);

    if (this.payload.ExportType && this.payload.ExportType == "BULKIMAGE") {
      console.log("bulk image result");
      console.log(chunkResult);
      finalResult[0] = chunkResult.data.Messages;
    }
    return finalResult;
  }
}

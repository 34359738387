<template>
  <div id="appContainer" :style="cssVars">
    <!-- <div>Hello</div> -->
    <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
    <O360Modal
      :visibility="$store.getters.errorModalVisibility"
      :visibility-update-func="$store.dispatch"
      visibility-update-func-name="setErrorModalVisiblitityToFalse"
      title="We're sorry, something went wrong..."
      :description="errorMessage"
      modal-style="danger"
      :hide-cancel="true"
    />
    <O360Changelog />
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script>
import { B2CManager } from "@/Lib/B2CManager.js";
import NotificationManager from "@/Lib/NotificationManager";
import AuthorizationManager from "@/Lib/AuthorizationManager";

const consoleLog = console.log;
const consoleDir = console.dir;
export default {
  name: "App",
  mixins: [B2CManager, NotificationManager],
  data() {
    return {
      onStartRoute: {},
      cssVars: {
        "--background-url": `url(${process.env.VUE_APP_Background_img})`,
        "--new-log-in-background-url": `url(${process.env.VUE_APP_NewLogInBackground_img})`,
        "--wood-poles-distribution2-url": `url(${process.env.VUE_APP_WoodPolesDistribution_img})`,
        "--osmose-bg-poles-5b-url": `url(${process.env.VUE_APP_osmosebgpoles5b_img})`,
        "--iStock-url": `url(${process.env.VUE_APP_iStock_img})`
      }
    };
  },
  computed: {
    appRegistry() {
      return this.$store.getters.appRegistry;
    },
    userAuthObject() {
      return this.$store.getters.userAuthObject;
    },
    userApplications() {
      return this.$store.getters.userApplications;
    },
    errorMessage() {
      return this.$store.getters.errorMessage;
    },
    debug() {
      return this.$store.getters.debug;
    },
    createLoggedInLog() {
      return this.$store.getters.readyToCreateLoggedInLog;
    }
  },
  watch: {
    userAuthObject(newValue, oldValue) {
      // wait for userApplications and app registery before routing to a dynamic app
      if (newValue != oldValue) {
        this.routerControlAfterAuthObject();
      }
    },
    debug(newValue, oldValue) {
      if (newValue != oldValue && newValue == true) {
        console.log = consoleLog;
        console.dir = consoleDir;
      }
    },
    createLoggedInLog(newValue) {
      const readyToLog =
        newValue &&
        !newValue.alreadyLogged &&
        newValue.currentProjectExist &&
        ((this.$store.getters.isCustomer && newValue.userInfoExist) ||
          !this.$store.getters.isCustomer);
      if (readyToLog) {
        this.$store.dispatch("logger", {
          LoggingCategory: "Information",
          Action: "Logged in"
        });
        this.$store.dispatch("updateReadyToCreateLoggedInLog", {
          alreadyLogged: true
        });
      }
    }
  },
  beforeCreate() {
    // disable console.logs in production builds:
    if (process.env.NODE_ENV === "production") {
      console.log = function () {};
      console.dir = function () {};
    }
    /**** DO NOT ENTER ANY CONSOLE LOGS BEFORE THIS LINE AS THEY WILL SHOW IN PRODUCTION BUILDS*****/
    // console.log("Environment Variables: ", process.env);
  },
  created() {
    this.$router
      .isReady()
      .then(() => {
        this.onStartRoute = this.$router.currentRoute.value;
      })
      .catch(e => {
        console.log("Error: ", e);
      });

    this.$store.dispatch("updateClientId", process.env.VUE_APP_ClientId);
    this.$store.dispatch("updateAuthority", process.env.VUE_APP_Authority);
    this.$store.dispatch(
      "updateO360APIScope",
      process.env.VUE_APP_O360APIScope
    );
    this.$store.dispatch(
      "updateAppRegistryURL",
      process.env.VUE_APP_REGISTRY_URL
    );
    this.$store.dispatch(
      "updateCustomerProjectURL",
      process.env.VUE_APP_CustomerProjectURL
    );
    this.$store.dispatch(
      "updateUserAuthObjectURL",
      process.env.VUE_APP_USER_AUTH_OBJ_URL
    );
    this.$store.dispatch(
      "updateFindIfUserExistURL",
      process.env.VUE_APP_FIND_IF_USER_EXIST_URL
    );
    this.$store.dispatch(
      "updateAddNewUserURL",
      process.env.VUE_APP_ADD_NEW_USER_URL
    );
    this.$store.dispatch(
      "updateUserInfoURL",
      process.env.VUE_APP_USER_INFO_OBJ_URL
    );

    this.$store.dispatch(
      "updateAllUserTypesURL",
      process.env.VUE_APP_ALL_USER_TYPES_URL
    );
    this.$store.dispatch(
      "updateUserPreferencesURL",
      process.env.VUE_APP_USER_PREFERENCES_URL
    );

    this.$store.dispatch(
      "updateDefaultProjectUpdateURL",
      process.env.VUE_APP_UPDATE_DEFAULT_PROJECT_URL
    );

    // this.addAxiosInterceptor();
    // this.routerControlBeforeAuthObject(this.$router.currentRoute);
    this.$store.dispatch(
      "updateOnStartAppRoute",
      this.$router.currentRoute.value.path
    );

    // get states mapping
    AuthorizationManager.getStatesMapping(
      undefined,
      this.$store.dispatch,
      "updateStatesMapping"
    );
    try {
      this.initMsalObj();
      // eslint-disable-next-line
    } catch (err) {
      console.log("error when trying to call initMsalObj  in App.vue");
      console.log(err);
      // this.$store.dispatch("updateOnStartAppState", currentRoute.query);
      // // if reset password request AADB2C90118, just don't do anything and let b2cManager
      // // msal's handleRedirectCallback deal with it
      // if (currentRoute.fullPath.indexOf("AADB2C90118") == -1) {
      //   this.logIn();
      // }
    }
  },
  mounted() {
    this.$router.beforeEach((to, from) => {
      // log user navigation
      if (
        to.name != "loading" &&
        !(to.name == "home" && !this.$store.getters.currentProject) &&
        (this.$store.getters.userInfo || !this.$store.getters.isCustomer)
      ) {
        this.$store.dispatch("logger", {
          LoggingCategory: "Information",
          Action: "Navigated to",
          Location: to.name
        });
      }
      // this.checkTokenExpiration(); // logout if token expired

      // prevent duplicate navigation
      console.log(
        "Navigating: ",
        from.fullPath,
        ">",
        to.fullPath,
        ". Is this duplicate navigation: ",
        to.fullPath == from.fullPath
      );
      if (to.fullPath == from.fullPath) {
        return false;
      } else {
        return true;
      }
    });
  },
  methods: {
    routerControlAfterAuthObject() {
      // if coming back from b2c, go to dashboard
      if (
        this.onStartRoute.path == "/" ||
        this.onStartRoute.path == "/signup" ||
        this.onStartRoute.path == "/login" ||
        this.onStartRoute.path == "/loading" ||
        this.onStartRoute.path == "/home"
      ) {
        const query =
          Object.keys(this.onStartRoute.query).length > 0
            ? this.onStartRoute.query
            : this.$store.getters.onStartAppState;

        if (this.userApplications && this.userApplications.length == 1)
          this.navigate(
            this.userApplications[0].name.toLowerCase(),
            this.userApplications[0].entryPoint
          );
        else this.$router.push({ name: "home", query: query });
      } else {
        this.routeToDynamicApp();
      }
    },
    routeToDynamicApp() {
      let routeIsForDynamicApp = false;
      for (let i = 0; i < this.appRegistry.length; i++) {
        if (
          this.onStartRoute.path.substring(1) ==
          this.appRegistry[i].name.toLowerCase()
        ) {
          routeIsForDynamicApp = this.appRegistry[i];
          break;
        }
      }

      let userIsAuthorizedForApp = false;
      for (let i = 0; i < this.userApplications?.length; i++) {
        if (
          this.onStartRoute.path.substring(1) ==
          this.userApplications[i].name.toLowerCase()
        ) {
          userIsAuthorizedForApp = true;
          break;
        }
      }
      if (this.userApplications && this.userApplications.length == 1) {
        this.navigate(
          this.userApplications[0].name.toLowerCase(),
          this.userApplications[0].entryPoint
        );
      } else if (
        (routeIsForDynamicApp && userIsAuthorizedForApp) ||
        this.onStartRoute.path == "/projectdashboard" || // has its own auth check
        this.onStartRoute.path == "/mapview" || // has its own auth check
        (this.onStartRoute.path == "/personalsettings" &&
          this.$store.getters.isCustomer)
      ) {
        this.navigate(
          routeIsForDynamicApp.name.toLowerCase(),
          routeIsForDynamicApp.entryPoint
        );
      } else {
        // go to home
        const query =
          Object.keys(this.onStartRoute.query).length > 0
            ? this.onStartRoute.query
            : this.$store.getters.onStartAppState;

        this.$router.push({ name: "home", query: query });
      }
    },
    navigate(routeName, entryPoint) {
      if (!this.$router.hasRoute(routeName))
        this.$router.addRoute({
          path: `/${routeName}`,
          name: routeName,
          component: () => import(`./components/dynamic/${entryPoint}`)
        });
      const query =
        Object.keys(this.onStartRoute.query).length > 0
          ? this.onStartRoute.query
          : this.$store.getters.onStartAppState;
      this.$router.push({ name: routeName, query: query });
    }
  }
};
</script>
<style lang="scss">
#app {
  font-family: map-get($font, main-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  padding-right: 0px !important;
}
</style>
<style lang="scss">
.v-toast__item--info {
  background-color: white;
  color: map-get($colors, osmose-blue);
  position: relative;
  top: 80px;
}

.v-toast__item--default {
  background-color: map-get($colors, accent-blue);
  color: white;
  position: relative;
  top: 80px;
}
</style>

<template>
  <b-modal
    id="o360Modal"
    size="lg"
    :visible="Visibility"
    @hidden="updateVisibility"
    no-close-on-esc
    :no-close-on-backdrop="noCloseOnBackDrop"
  >
    <template v-slot:modal-header="{}">
      <div
        id="modalHeader"
        :class="['alert-' + modalStyle, { infoHeader: modalStyle === 'info' }]"
      >
        <p id="modalTitle" :class="{ infoTitle: modalStyle === 'info' }">
          <i
            v-if="modalStyle === 'danger'"
            class="fas fa-exclamation-circle"
            id="errorIcon"
          ></i>
          <i
            v-if="modalStyle === 'warning'"
            class="fas fa-exclamation-circle"
            id="warningIcon"
          ></i>
          <i
            v-if="modalStyle === 'info'"
            class="fas fa-info-circle"
            id="infoIcon"
          ></i>
          {{ title }}
        </p>
      </div>
    </template>

    <template v-slot:default="{}">
      <p id="modalDescription" v-bind:class="{ preLine: applyPreLine }">
        <span v-html="description"></span>
      </p>
    </template>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button
        pill
        v-if="!hideCancel"
        class="Custombtn"
        :class="{
          secondaryPrimaryButton: modalStyle === 'primary',
          secondaryDangerButton: modalStyle === 'danger',
          secondaryInfoButton: modalStyle === 'info',
          secondaryWarningButton: modalStyle === 'warning'
        }"
        :variant="modalStyle"
        @click="cancel(), noCallback ? noCallback() : ''"
      >
        {{ cancelText }}
      </b-button>
      <b-button
        pill
        class="Custombtn"
        :class="{
          primaryButton: modalStyle === 'primary',
          dangerButton: modalStyle === 'danger',
          infoButton: modalStyle === 'info',
          warningButton: modalStyle === 'warning'
        }"
        :variant="modalStyle"
        @click="callback ? callback() : '', ok()"
      >
        {{ okayText }}
      </b-button>
      <b-button
        v-if="enableSecondaryAction"
        pill
        class="Custombtn"
        :class="{
          primaryButton: modalStyle === 'primary',
          dangerButton: modalStyle === 'danger',
          infoButton: modalStyle === 'info',
          warningButton: modalStyle === 'warning'
        }"
        :variant="modalStyle"
        @click="callback ? secondaryButtonCallback() : '', ok()"
      >
        {{ secondaryButtonText }}
      </b-button>
    </template>
  </b-modal>
</template>
<script>
export default {
  props: {
    visibility: { type: Boolean, required: true },
    visibilityUpdateFunc: { type: Function },
    visibilityUpdateFuncName: { type: String },
    title: { type: String, requried: true },
    description: { type: String, requried: true },
    modalStyle: { type: String, default: "primary" }, //['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark']
    okayText: { type: String, default: "OK" },
    cancelText: { type: String, default: "Return" },
    hideCancel: { type: Boolean, default: false },
    callback: { type: Function },
    noCloseOnBackDrop: { type: Boolean, default: true },
    noCallback: { type: Function },
    applyPreLine: { type: Boolean, default: false }, //this will allow new line character \n to work in modal Desciption
    enableSecondaryAction: { type: Boolean, default: false },
    secondaryButtonText: { type: String },
    secondaryButtonCallback: { type: Function }
  },
  computed: {
    Visibility() {
      return this.visibility;
    }
  },
  methods: {
    updateVisibility() {
      // if visibility property is in the store (the call will be dispatch(funcName))
      if (this.visibilityUpdateFuncName) {
        this.visibilityUpdateFunc(this.visibilityUpdateFuncName);
      } else {
        this.visibilityUpdateFunc();
      }
    }
  }
};
</script>
<style scoped>
#infoIcon {
  color: #0067ce;
}
#warningIcon {
  color: #f0ad4e;
}
#errorIcon {
  color: #d9534f;
}
#modalTitle {
  font-size: 24px;
  font-weight: 500;
  margin: 0px;
}

#modalDescription {
  font-size: 18px;
  font-weight: 400;
  margin: 0px;
  color: #1c3766;
}

#modalHeader {
  width: 100%;
  height: 100%;
  padding: 16px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.Custombtn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 2px;
  font-size: 14px !important;
  font-weight: normal;
  line-height: 1.428571429;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/* #cancelBtn {
  color: #000000;
  background-color: #ffffff;
  border-color: #cccccc;
} */

#cancelBtn:hover {
  background-color: #ebebeb;
  border-color: #adadad;
}
.preLine {
  white-space: pre-line;
}
</style>
<style>
#o360Modal .modal-dialog {
  max-width: 580px !important;
  font-family: "Segoe UI";
  position: relative;
  top: 82px;
}

#o360Modal .modal-header {
  padding: 0px !important;
}

#o360Modal .modal-footer {
  display: flex;
  justify-content: center;
}

#o360Modal .modal-footer .btn-danger {
  background-color: #d9534f;
}

#o360Modal .modal-header .infoHeader {
  background-color: #ffffff;
}

#o360Modal .modal-header .infoTitle {
  font-weight: bold;
  color: #1c3766;
}
#o360Modal .modal-footer .primaryButton {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}
#o360Modal .modal-footer .primaryButton:hover {
  background-color: #fff;
  color: #007bff;
  border-color: #007bff;
}
#o360Modal .modal-footer .secondaryPrimaryButton {
  background-color: #fff;
  color: #007bff;
  border-color: #007bff;
}
#o360Modal .modal-footer .secondaryPrimaryButton:hover {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}
#o360Modal .modal-footer .dangerButton {
  background-color: #d9534f;
  color: #fff;
  border-color: #d9534f;
}
#o360Modal .modal-footer .dangerButton:hover {
  background-color: #fff;
  color: #d9534f;
  border-color: #d9534f;
}
#o360Modal .modal-footer .secondaryDangerButton {
  background-color: #fff;
  color: #d9534f;
  border-color: #d9534f;
}
#o360Modal .modal-footer .secondaryDangerButton:hover {
  background-color: #d9534f;
  color: #fff;
  border-color: #d9534f;
}
#o360Modal .modal-footer .warningButton {
  background-color: #f0ad4e;
  color: #fff;
  border-color: #f0ad4e;
}
#o360Modal .modal-footer .warningButton:hover {
  background-color: #fff;
  color: #f0ad4e;
  border-color: #f0ad4e;
}
#o360Modal .modal-footer .secondaryWarningButton {
  background-color: #fff;
  color: #f0ad4e;
  border-color: #f0ad4e;
}
#o360Modal .modal-footer .secondaryWarningButton:hover {
  background-color: #f0ad4e;
  color: #fff;
  border-color: #f0ad4e;
}
#o360Modal .modal-footer .infoButton {
  background-color: #0067ce;
  color: #fff;
  border-color: #0067ce;
}
#o360Modal .modal-footer .infoButton:hover {
  background-color: #fff;
  color: #0067ce;
  border-color: #0067ce;
}
#o360Modal .modal-footer .secondaryInfoButton {
  background-color: #fff;
  color: #0067ce;
  border-color: #0067ce;
}
#o360Modal .modal-footer .secondaryInfoButton:hover {
  background-color: #0067ce;
  color: #fff;
  border-color: #0067ce;
}
</style>

<template>
  <div>
    <div id="signupcontainer">
      <!-- <link
        rel="stylesheet"
        href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
        integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
        crossorigin="anonymous"
      /> -->
      <div id="sheader">
        <p id="header-text">SIGN UP NOW</p>
      </div>

      <b-form id="sform" novalidate @click.stop>
        <template v-if="step === 1">
          <div id="formcontainer">
            <div
              class="form-group"
              :class="{ invalid: v$.userData.EmailAddress.$error }"
            >
              <p
                class="errormessage"
                v-if="
                  v$.userData.EmailAddress.$error &&
                  v$.userData.EmailAddress.isUnique.$invalid
                "
              >
                This email is already registered
              </p>
              <p
                class="errormessage"
                v-else-if="v$.userData.EmailAddress.$error"
              >
                Please enter a valid email address
              </p>

              <!-- <p class="errormessage" v-if="!v$.userData.email.email"> An account using this email address already exists. </p> -->
              <b-form-input
                class="form-input"
                id="input-email"
                v-model="userData.EmailAddress"
                @blur="v$.userData.EmailAddress.$touch()"
                type="email"
                placeholder="Email"
              ></b-form-input>
            </div>

            <div
              class="form-group"
              :class="{ invalid: v$.userData.Password.$error }"
            >
              <p class="errormessage" v-if="v$.userData.Password.$error">
                Please enter a valid password
              </p>
              <b-input-group
                class="input-group-pass"
                :class="{ invalid: v$.userData.Password.$error }"
              >
                <b-form-input
                  type="password"
                  class="form-input"
                  id="input-password"
                  v-model="userData.Password"
                  @blur="v$.userData.Password.$touch()"
                  placeholder="Create Password"
                ></b-form-input>
                <b-input-group-append
                  :class="{ invalid: v$.userData.Password.$error }"
                >
                  <span
                    v-b-popover.right.click.html="popoverContent"
                    id="disabled-wrapper"
                    class="input-group-text infoIconBox"
                  >
                    <i class="far fa-info-circle"></i>
                  </span>
                  <!-- <b-tooltip target="disabled-wrapper" placement="right" triggers="click" >{{tooltip1}}</b-tooltip> -->
                </b-input-group-append>
              </b-input-group>
              <!-- <p class="errormessage" v-if="!v$.userData.password.minLen"> Please enter a valid password </p> -->
            </div>

            <div
              class="form-group"
              :class="{ invalid: v$.userData.confirmpassword.$error }"
            >
              <p class="errormessage" v-if="v$.userData.confirmpassword.$error">
                Password doesn't match
              </p>
              <b-form-input
                type="password"
                class="form-input"
                id="input-confirmpassword"
                v-model="userData.confirmpassword"
                @blur="v$.userData.confirmpassword.$touch()"
                placeholder="Confirm Password"
              ></b-form-input>
            </div>

            <div>
              <div
                class="input-name"
                :class="{ invalid: v$.userData.FirstName.$error }"
              >
                <div v-if="v$.userData.FirstName.$dirty">
                  <p class="errormessage2" v-if="v$.userData.FirstName.$error">
                    Please provide your first name
                  </p>
                </div>
                <b-form-input
                  class="form-input"
                  id="input-fname"
                  v-model="userData.FirstName"
                  @blur="v$.userData.FirstName.$touch()"
                  placeholder="First Name"
                ></b-form-input>
              </div>

              <div
                class="input-name"
                :class="{ invalid: v$.userData.LastName.$error }"
              >
                <div v-if="v$.userData.LastName.$dirty">
                  <p class="errormessage" v-if="v$.userData.LastName.$error">
                    Please provide your last name
                  </p>
                </div>
                <b-form-input
                  class="form-input"
                  id="input-lname"
                  v-model="userData.LastName"
                  @blur="v$.userData.LastName.$touch()"
                  placeholder="Last Name"
                ></b-form-input>
              </div>
            </div>

            <div
              class="form-phone"
              :class="{ invalid: v$.userData.Phone.$error }"
            >
              <p class="errormessage" v-if="v$.userData.Phone.$error">
                Please enter a valid phone number
              </p>
              <b-form-input
                class="form-input"
                id="input-phone"
                v-model="userData.Phone"
                @blur="v$.userData.Phone.$touch()"
                placeholder="Phone"
              ></b-form-input>
            </div>

            <div
              class="form-group"
              :class="{ invalid: v$.userData.Company.$error }"
            >
              <p class="errormessage" v-if="v$.userData.Company.$error">
                Please provide company name
              </p>
              <b-form-input
                class="form-input"
                id="input-company"
                v-model="userData.Company"
                @blur="v$.userData.Company.$touch()"
                placeholder="Company"
              ></b-form-input>
            </div>

            <div
              class="form-group"
              :class="{ invalid: v$.userData.Title.$error }"
            >
              <p class="errormessage" v-if="v$.userData.Title.$error">
                Please provide your title
              </p>
              <b-form-input
                class="form-input"
                id="input-title"
                v-model="userData.Title"
                @blur="v$.userData.Title.$touch()"
                placeholder="Title"
              ></b-form-input>
            </div>

            <div
              class="form-group"
              :class="{ invalid: v$.userData.Address.$error }"
            >
              <p class="errormessage" v-if="v$.userData.Address.$error">
                Please provide address
              </p>
              <b-form-input
                class="form-input"
                id="input-address"
                v-model="userData.Address"
                @blur="v$.userData.Address.$touch()"
                placeholder="Address"
              ></b-form-input>
            </div>

            <div
              class="form-group"
              :class="{ invalid: v$.userData.City.$error }"
            >
              <div v-if="v$.userData.City.$dirty">
                <p class="errormessage" v-if="v$.userData.City.$error">
                  Please provide city
                </p>
              </div>
              <b-form-input
                class="form-input"
                id="input-city"
                v-model="userData.City"
                @blur="v$.userData.City.$touch()"
                placeholder="City"
              ></b-form-input>
              <!-- <p class="errormessage" v-if="!v$.userData.city.minLen"> Please enter a valid city </p> -->
            </div>

            <div
              class="form-group"
              :class="{ invalid: v$.userData.State.$error }"
              id="input-location"
            >
              <p class="errormessage2" v-if="v$.userData.State.$error">
                Please provide state
              </p>
              <b-form-select
                class="form-input"
                id="input-state"
                v-model="userData.State"
                :options="stateOptions"
                value-field="value"
                text-field="text"
                @blur="v$.userData.State.$touch()"
                v-b-popover.hover.top="popoverData"
              >
                <template v-slot:first>
                  <b-form-select-option value="" disabled
                    >State</b-form-select-option
                  >
                </template>
              </b-form-select>
              <!-- <p class="errormessage" v-if="!v$.userData.userType.email"> Please enter a valid state </p> -->
            </div>

            <div
              class="form-group"
              :class="{ invalid: v$.userData.ZipCode.$error }"
              id="input-location"
            >
              <div v-if="v$.userData.ZipCode.$dirty">
                <p class="errormessage" v-if="v$.userData.ZipCode.$error">
                  Please provide zip code
                </p>
                <!-- <p class="errormessage" v-if="!v$.userData.ZipCode.required">Please provide zip code</p>
                -->
              </div>
              <b-form-input
                class="form-input"
                id="input-zip"
                v-model="userData.ZipCode"
                @blur="v$.userData.ZipCode.$touch()"
                placeholder="Zip"
              ></b-form-input>
              <!-- <p class="errormessage" v-if="!v$.userData.zip.required"> Please provide zip code </p> -->
            </div>

            <div
              class="form-inputuser"
              :class="{ invalid: v$.userData.UserPreferences.UserType.$error }"
              id="input-usertype"
            >
              <p
                class="errormessage"
                v-if="v$.userData.UserPreferences.UserType.$error"
              >
                Please select a user type
              </p>
              <b-form-select
                class="form-input"
                id="user-type-drop"
                v-model="userData.UserPreferences.UserType"
                :options="userOptionsAut"
                value-field="userTypeId"
                text-field="userTypeName"
                @blur="v$.userData.UserPreferences.UserType.$touch()"
              >
                <template v-slot:first>
                  <b-form-select-option value="" disabled
                    >User Type</b-form-select-option
                  >
                </template>
              </b-form-select>
            </div>
          </div>

          <p id="title" class="inLineTitle">
            If you were given a sign up code please enter it here:
          </p>
          <div
            class="form-inputuser"
            :class="{ invalid: v$.userData.SignUpProjectCode.$error }"
            id="input-usertype"
          >
            <div v-if="v$.userData.SignUpProjectCode.$dirty">
              <p
                class="errormessage"
                v-if="v$.userData.SignUpProjectCode.$error"
              >
                Valid codes are 7 characters, 3 alpha followed by 4 numeric.
              </p>
            </div>
            <b-form-input
              class="form-input"
              id="user-type-drop"
              v-model="userData.SignUpProjectCode"
              @blur="v$.userData.SignUpProjectCode.$touch()"
              placeholder="Code (optional)"
            ></b-form-input>
            <!-- <p class="errormessage" v-if="!v$.userData.zip.required"> Please provide zip code </p> -->
          </div>

          <p id="title">
            By creating an account you agree to Osmose's
            <router-link
              to="/termsandconditions/terms"
              target="_blank"
              class="title-terms"
              >Terms and Conditions</router-link
            >
            and
            <router-link
              to="/termsandconditions/privacy"
              target="_blank"
              class="title-terms"
              >Privacy Policy</router-link
            >
          </p>
          <b-button
            id="button-next"
            @click.prevent="next()"
            :disabled="v$.$invalid"
            variant="primary"
          >
            Next
            <i id="sicon" class="fas fa-angle-right"></i>
          </b-button>
        </template>

        <!-- User prefrance start -->

        <template v-if="step === 2">
          <div id="formcontainer">
            <div class="form-inputuser" id="input-usertype">
              <b-form-group id="checkbox-container">
                <p id="checkbox-head">My Interests</p>
                <p id="title-interest">Please select at least one interest.</p>
                <b-form-checkbox-group
                  stacked
                  size="sm"
                  id="checkbox-options"
                  v-model="userData.UserPreferences.Preferences"
                  :options="userPreferenceOptionsAut"
                  value-field="preferenceId"
                  text-field="preferenceName"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
          <div class="checkbox-policy">
            <div id="checkbox-privacy">
              <b-form-checkbox
                id="checkbox-terms"
                v-model="userData.UserPreferences.TermsAndConditions.Accepted"
              ></b-form-checkbox>
            </div>
            <div id="checkbox-terms-text">
              <p id="title2">
                I agree to Osmose's
                <router-link
                  to="/termsandconditions/terms"
                  target="_blank"
                  class="title-terms"
                  >Terms and Conditions</router-link
                >
                and
                <router-link
                  to="/termsandconditions/privacy"
                  target="_blank"
                  class="title-terms"
                  >Privacy Policy</router-link
                >
              </p>
            </div>
          </div>
          <b-button
            v-b-modal.modal-1
            id="button-submit"
            @click.prevent="submitted"
            :disabled="isDisabled"
            type="submit"
            variant="primary"
            >Sign Up</b-button
          >
        </template>

        <!-- User prefrance end -->
      </b-form>
      <p id="logBtn">
        Have an account?
        <button id="logInBtn" @click="logIn">Log In</button>
      </p>
      <!-- <b-card v-if="isSubmitted" class="mt-3" header="Form Data Result">
        <pre class="m-0">{{ userData }}</pre>
      </b-card>-->
      <!-- <p>allUserTypes: <span class="fields-val"> {{ allUserTypes }} </span> </p> -->
    </div>
    <div>
      <b-modal id="modal-1" v-on:hide="logIn()" hide-footer hide-header>
        <div>
          <p id="thums-up">
            <i class="fal fa-thumbs-up"></i>
          </p>
          <p id="confirm-header">
            Hey {{ userData.FirstName }}, thank you for signing up!
          </p>
          <p id="confirm-text">
            We will review your request and respond within 24 hours.
          </p>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { B2CManager } from "@/Lib/B2CManager.js";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  helpers
} from "@vuelidate/validators";
const { withAsync } = helpers;
export default {
  mixins: [B2CManager],
  data() {
    return {
      v$: useVuelidate(),
      step: 1,
      isSubmitted: false,
      userData: {
        EmailAddress: "",
        Password: "",
        confirmpassword: "",
        FirstName: "",
        LastName: "",
        Phone: "",
        Company: "",
        Title: "",
        Address: "",
        City: "",
        State: "",
        ZipCode: "",
        SignUpProjectCode: "",
        UserType: "Member",
        UserPreferences: {
          Company: "",
          UserType: "",
          Preferences: [],
          TermsAndConditions: {
            DateTimeAccepted: new Date().toLocaleString(),
            Accepted: false
          }
        }
      }
    };
  },
  validations() {
    return {
      userData: {
        EmailAddress: {
          required,
          maxLen: maxLength(50),
          email,
          isUnique: withAsync(async value => {
            // it is not safe to assume that requried is enforced before this
            // according to docs
            if (value === "") return true;
            return await this.findIfUserExist(value);
          })
        },
        Password: {
          required,
          Pass(Password) {
            return (
              // eslint-disable-next-line prettier/prettier
            /^((?=.*[A-Z])(?!.*[\s])(?=.*[a-z])(?=.*([^\w\s]|_))|(?=.*[a-z])(?!.*[\s])(?=.*([^\w\s]|_))(?=.*[0-9])|(?=.*[A-Z])(?!.*[\s])(?=.*([^\w\s]|_))(?=.*[0-9])|(?=.*[A-Z])(?!.*[\s])(?=.*[a-z])(?=.*[0-9])).{8,64}$/.test(
                Password
              ) &&
              // eslint-disable-next-line prettier/prettier
            Password.length >= 8
            );
          }
        },
        confirmpassword: { sameAsPassword: sameAs(this.userData.Password) },
        FirstName: {
          required,
          maxLen: maxLength(50),
          Fname(FirstName) {
            return (
              /^[0-9a-zA-Z' .-]+$/.test(FirstName) && FirstName.length >= 1
            );
          }
        },
        LastName: {
          required,
          maxLen: maxLength(50),
          Lname(LastName) {
            return /^[0-9a-zA-Z' .-]+$/.test(LastName) && LastName.length >= 1;
          }
        },
        Phone: {
          required,
          maxLen: maxLength(12),
          phone(Phone) {
            return (
              (/^\d{10}$/.test(Phone) && Phone.length >= 10) ||
              (/^\d{1,3}-\d{1,3}-\d{1,4}$/.test(Phone) && Phone.length >= 12)
            );
          }
        },
        Company: { required, minLen: minLength(1), maxLen: maxLength(100) },
        Title: { required, minLen: minLength(1), maxLen: maxLength(64) },
        Address: { required, minLen: minLength(1), maxLen: maxLength(200) },
        City: {
          required,
          maxLen: maxLength(50),
          city(City) {
            return /^[0-9a-zA-Z' .-]+$/.test(City) && City.length >= 1;
          }
        },
        State: { required, minLen: minLength(1) },
        ZipCode: {
          required,
          maxLen: maxLength(11),
          Zip(ZipCode) {
            return (
              (/^[0-9]{5}(?:-[0-9]{4})?$/.test(ZipCode) &&
                ZipCode.length >= 5) ||
              /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(
                ZipCode
              )
            );
          }
        },
        SignUpProjectCode: {
          maxLen: maxLength(7),
          minLen: minLength(7),
          signUpProjectCode(SignUpProjectCode) {
            if (SignUpProjectCode.length > 6) {
              let first3 = true;
              let last4 = true;
              for (let i = 0; i < SignUpProjectCode.length; i++) {
                if (i < 3) {
                  if (!/^[a-zA-Z]+$/.test(SignUpProjectCode[i])) {
                    first3 = false;
                  }
                } else {
                  if (!/^[0-9]+$/.test(SignUpProjectCode[i])) {
                    last4 = false;
                  }
                }
              }
              if (first3 && last4) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          }
        },
        UserPreferences: {
          UserType: { required, minLen: minLength(1) }
        }
      }
    };
  },
  computed: {
    listCommaSeparated() {
      return this.uPreferences.join(", ");
    },
    popoverData() {
      return {
        content: this.stateOptions
          ? this.stateOptions[this.userData.State]
          : null
      };
    },
    popoverContent() {
      return {
        html: true,
        title: () => {
          return "Password requirements";
        },
        content: () => {
          return "The password must be between 8 and 64 characters.<br> The password must have at least 3 of the following:<br><em> - a lowercase letter<br><em> - an uppercase letter<br><em> - a digit <br><em> - a symbol";
        }
      };
    },
    isDisabled() {
      if (
        this.userData.UserPreferences.Preferences > [0] &&
        this.userData.UserPreferences.TermsAndConditions.Accepted === true
      ) {
        return false;
      } else {
        return true;
      }
    },
    userOptionsAut() {
      console.dir();
      const userTypes = this.$store.getters.allUserTypes;
      if (userTypes && userTypes.length) {
        return userTypes;
      }
      return [];
    },
    userPreferenceOptionsAut() {
      console.dir();
      return this.$store.getters.userPreferences;
    },
    stateOptions() {
      return this.$store.getters.statesDict;
    }
  },
  methods: {
    submitted() {
      this.isSubmitted = true;
      this.v$.userData.$touch();
      if (this.v$.userData.$error) return;
      // alert(JSON.stringify(this.userData));
      if (this.v$.$invalid) {
        // do nothing
      } else {
        // userType is set to "Member" since backend can't handle other types
        const userDataCopy = JSON.parse(JSON.stringify(this.userData));
        userDataCopy.userType = "Member";
        userDataCopy.UserPreferences.Company = userDataCopy.Company;
        userDataCopy.UserPreferences.Preferences =
          userDataCopy.UserPreferences.Preferences.join(",");
        userDataCopy.UserPreferences.UserType =
          userDataCopy.UserPreferences.UserType.toString();
        this.addNewUser(userDataCopy);
      }
    },
    next() {
      this.step++;
    }
  },

  created() {
    this.$store.dispatch("getAllUserTypes");
    this.$store.dispatch("getUserPreferences");
  }
};
</script>

<style scoped>
#signupcontainer {
  width: 324px;
  border-radius: 0px;
  background-color: #e9e9e9;
}
#sform {
  border-radius: 0px;
  padding-left: 24px;
  padding-right: 24px;
}
#formcontainer {
  border: solid 1px;
  border-color: #dfdfdf;
  text-align: center;
  margin-top: 5px;
  background-color: #ffffff;
}
.form-input {
  border-radius: 0px;
  font-size: 12px;
  letter-spacing: 0.32px;
  color: #979797;
  opacity: 1;
  font-family: "Segoe UI";
  height: 35px;
}
.form-select {
  border-radius: 0px;
  font-size: 12px;
  letter-spacing: 0.32px;
  color: #6d6b6b;
  opacity: 1;
  font-family: "Segoe UI";
  height: 35px;
}

.form-group {
  margin-bottom: 0px;
}

.form-phone {
  margin-bottom: 0px;
  clear: both;
}

.form-inputuser {
  margin-bottom: 0px;
  clear: both;
}
.invalid input {
  border: 1px solid red;
}
.invalid select {
  border: 1px solid red;
}

.input-group-append.invalid {
  border: 1px solid red;
}

#title {
  font-size: 10px;
  font-family: "Segoe UI";
  text-align: left;
  color: #979797;
  width: 270px;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-bottom: 8px;
  padding-left: 4px;
}
#title2 {
  font-size: 10px;
  font-family: "Segoe UI";
  text-align: left;
  color: #979797;
  width: 250px;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-bottom: 8px;
}
.checkbox-policy {
  margin-top: 3px;
}

#logBtn {
  font-size: 10px;
  font-family: "Segoe UI";
  text-align: left;
  color: #979797;
  margin-top: 5px;
  padding-bottom: 8px;
  padding-left: 24px;
}
#header-text {
  font-size: 18px;
  font-family: "Segoe UI";
  color: #1c3766;
  padding-left: 26px;
  padding-top: 12px;
  font-weight: bold;
  text-align: left;
  letter-spacing: 0;
}

#button-submit {
  width: 276px;
  background: #1c3766;
  border-radius: 0px;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  border-color: #1c3766;
  font-size: 12px;
  height: 35px;
  margin-right: 24px;
}
#button-next {
  width: 276px;
  background: #1c3766;
  border-radius: 0px;
  text-align: left;
  font-weight: bold;
  color: #ffffff;
  border-color: #1c3766;
  font-size: 12px;
  height: 35px;
  margin-right: 24px;
}
#sicon {
  margin-left: 200px;
}
.input-name {
  width: 137px;
  float: left;
}
#input-location {
  width: 137px;
  float: left;
}
#input-password {
  border-right: none;
}
/* #input-phone {
  margin-top: 35px;
} */

#input-usertype {
  color: #6d6b6b;
}

/* #input-usertype:after {
  pointer-events: none;
  position: absolute;
  font-family: "Font Awesome 5 Free";
  top: 5px;
  right: 15px;
  content: "\f0d7";
  font-weight: 900;
  color: #6d6b6b;
} */
#input-state {
  color: #6c757d;
}

#user-type-drop {
  color: #6c757d !important;
}

.infoIconBox {
  border-radius: 0px;
  border-left: none;
  background-color: #ffffff;
  margin-left: -10px;
  font-size: 15px;
  color: #8a8e94;
}

.input-group-text {
  border: 1px solid #e4ebf3;
}

.form-input:focus {
  box-shadow: none;
  border-color: #ced4da;
}
.form-select:focus {
  box-shadow: none;
  border-color: #ced4da;
}
.errormessage {
  font-size: 12px;
  font-family: Segoe UI;
  color: #ffffff;
  position: absolute;
  left: 91%;
  width: 195px;
  background-color: #f76868;
  font-weight: 500;
  border-radius: 5px;
  padding: 6px;
  text-align: left;
  margin-left: 9px;
  letter-spacing: -0.2px;
}

.errormessage:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right-color: #f76868;
  border-left: 0;
  margin-top: -10px;
  margin-left: -8px;
}

.errormessage2 {
  font-size: 12px;
  font-family: "Segoe UI";
  color: #ffffff;
  position: absolute;
  right: 91%;
  width: 196px;
  background-color: #f76868;
  font-weight: 500;
  border-radius: 5px;
  padding: 6px;
  text-align: left;
  margin-right: 9px;
  letter-spacing: -0.2px;
}

.errormessage2:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-left-color: #f76868;
  border-right: 0;
  margin-top: -10px;
  margin-right: -8px;
}
#checkbox-container {
  text-align: left;
}
#checkbox-options {
  text-align: left;
  font-size: 11px;
  color: #6d6b6b;
  font-family: "Segoe UI";
  padding-left: 6px;
  padding-right: 5px;
  letter-spacing: -1px;
}
#checkbox-head {
  font-size: 14px;
  font-family: "Segoe UI";
  color: #1c3766;
  font-weight: bold;
  text-align: left;
  border-bottom: #e9e9e9 solid 0.5px;
  padding: 6px 10px;
  margin-bottom: 4px;
}

#title-interest {
  font-size: 10px;
  font-family: "Segoe UI";
  text-align: left;
  color: #979797;
  margin-bottom: 2px;
  padding-bottom: 6px;
  padding-left: 10px;
}

#logInBtn {
  border: none;
  background-color: transparent;
  color: #007bff;
}
#input-password:focus + .input-group-append {
  border: 1px solid #ced4da;
}
#thums-up {
  font-size: 40px;
  text-align: center;
  color: #e94c39;
}
#confirm-header {
  font-family: "Segoe UI";
  font-weight: 600;
  color: #1c3766;
  text-align: center;
  font-size: 22px;
}
button:disabled {
  pointer-events: none;
}
#confirm-text {
  font-family: "Segoe UI";
  font-weight: 400;
  color: #1c3766;
  text-align: center;
  font-size: 18px;
}
#checkbox-privacy {
  width: 10%;
  float: left;
}
#checkbox-terms-text {
  width: 70%;
  float: left;
}
@media only screen and (max-width: 992px) {
  .title-terms {
    font-size: 10px;
  }
}
.inLineTitle {
  padding-top: 8px;
  padding-bottom: 0px !important;
}
</style>
<style>
.popover.b-popover {
  border-radius: 0px;
  font-size: 12px;
  font-family: "Segoe UI";
  max-width: 305px;
}
</style>

/**********************************************
 * Logging
 * ********************************************
 * logs messages to the logging service
 * *******************************************/

"use strict";

//////////////////////// Imports /////////////////
import Axios from "axios";

export default {
  logMessage: function (logServiceURL, logMessage) {
    this.postMessage(logServiceURL, logMessage);
  },

  postMessage: function (URL, logMessage) {
    Axios.post(URL, logMessage)
      .then(logServiceResults => {
        console.log("Logging result: ", logServiceResults.data);
        // console.dir(logServiceResults.data);
      })
      .catch(error => {
        // ignore any logging errors
        console.log("Logging error: ");
        console.dir(error);
      });
  }

  // getInformationLogMessage: function() {
  //   var logMessage = {};
  //   logMessage["CustomerId"] = "Test";
  //   logMessage["RootLogPath"] = ROOT_LOG_PATH;
  //   logMessage["LoggingCategory"] = "Information";
  //   logMessage["Message"] = "Test";
  //   logMessage["Duration"] = "";

  //   return logMessage//JSON.stringify(logMessage);
  // },

  // getExceptionLogMessage: function() {
  //   var logMessage = {};
  //   logMessage["CustomerId"] = "";
  //   logMessage["RootLogPath"] = ROOT_LOG_PATH;
  //   logMessage["LoggingCategory"] = "Exception";
  //   logMessage["Message"] = "";

  //   return logMessage;
  // },
};

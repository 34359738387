<template>
  <div id="top-header">
    <!-- <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
      integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
      crossorigin="anonymous"
    /> -->
    <b-navbar fixed="top" id="topHeader" toggleable="lg" type="dark">
      <b-navbar-toggle target="topHeader-nav-collapse"></b-navbar-toggle>

      <b-collapse id="topHeader-nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown
            v-if="hasProject"
            id="o360projectSel"
            :text="selectedProjectName"
            right
          >
            <ProjectSelection></ProjectSelection>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown right id="contactUsLink">
            <template v-slot:button-content>
              <span class="cnText">CONTACT US</span>
            </template>
            <b-dropdown-item
              href="javascript:void(0);"
              v-on:click.prevent="navigate('ContactUs')"
              >Contact Us</b-dropdown-item
            >
            <b-dropdown-item
              href="javascript: void(0);"
              v-on:click.prevent="navigate('ViewContact')"
              v-if="hasProject"
              >View Contacts</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item
            class="addCart"
            :disabled="getNotifications.length < 1"
            :title="getNotifications.length < 1 ? 'Notification is empty' : ''"
          >
            <Notification></Notification>
          </b-nav-item>

          <b-nav-item-dropdown
            v-if="$store.getters.accessHelp"
            :no-caret="true"
            id="juHelp"
            ><template v-slot:button-content>
              <i class="far fa-question-circle"></i
            ></template>
            <b-dropdown-item
              :href="baseUrl + 'JointUseHelp/WebHelp/index.htm'"
              target="_blank"
              >Get Help</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item-dropdown right id="userNameSButton">
            <template v-slot:button-content>
              <i id="profileIcon" class="fas fa-user-circle fa-lg"></i>
              <span id="userName">{{ userName }}</span>
            </template>
            <div v-for="(contentObj, index) in currentTargets" :key="index">
              <DropDownMenuItem
                :targetComponunt="contentObj.name"
                :entryPoint="contentObj.entryPoint"
                :alias="contentObj.alias"
              ></DropDownMenuItem>
            </div>
            <div>
              <DropDownMenuItem
                v-if="isCustomer"
                :targetComponunt="personalSettingsInfo.name"
                :entryPoint="personalSettingsInfo.entryPoint"
                :alias="personalSettingsInfo.alias"
              ></DropDownMenuItem>
            </div>
            <b-dropdown-item
              href="/termsandconditions/privacy"
              target="_blank"
              v-if="isCustomer"
            >
              Privacy Policy
            </b-dropdown-item>
            <b-dropdown-item
              href="/termsandconditions/terms"
              target="_blank"
              v-if="isCustomer"
            >
              Terms and Conditions
            </b-dropdown-item>

            <b-dropdown-item href="#" v-on:click="logOut">
              Sign Out
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="launchChangeLog()"
              >What's New</b-dropdown-item
            >
            <b-dropdown-item
              v-if="
                userRegistered &&
                userApplications &&
                userApplications.length > 1
              "
              @click="retakeTour()"
            >
              Take A Tour
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item
            class="addCart"
            :disabled="cartItemsCount < 1"
            v-if="showCartIcon()"
            :title="cartItemsCount < 1 ? 'Cart is empty' : ''"
          >
            <CartOverlay></CartOverlay>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { B2CManager } from "@/Lib/B2CManager.js";
import authorizationManager from "@/Lib/AuthorizationManager.js";
import CartStore from "../dynamic/Products/LocalStore/CartStore";
import AuthorizationStore from "@/store/Modules/AuthorizationStore.js";
import Axios from "axios";
import CartOverlay from "../dynamic/Products/CartOverlay";

import ViewContactStore from "@/components/dynamic/Contact/LocalStore/ViewContactStore";
import DropDownMenuItem from "@/components/infrastructure/DropDownMenuItem";
import Notification from "./Notification.vue";
export default {
  mixins: [B2CManager],
  data: function () {
    return {
      contactURL: "",
      contactTargets: [
        {
          name: "Contacts",
          navContent: "Contacts",
          entryPoint: "Contact/Contacts"
        },
        {
          name: "ContactUs",
          navContent: "Contacts",
          entryPoint: "Contact/Contacts"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["cartItemsCount", "getNotifications"]),

    baseUrl() {
      return process.env.BASE_URL;
    },
    currentProject() {
      return this.$store.getters.currentProject;
    },
    selectedProjectName: function () {
      return this.currentProject ? this.currentProject.projectName : "";
    },
    hasProject() {
      if (this.$store.getters.hasProject > 0 && this.selectedProjectName != "")
        return true;
      else {
        return false;
      }
    },
    isCustomer() {
      return this.$store.getters.isCustomer;
    },
    userRegistered() {
      return (
        (this.$store.getters.userApplications == null ||
          this.$store.getters.userApplications.length > 0) &&
        (this.userCustomersLength == null || this.userCustomersLength > 0)
      );
    },
    userCustomersLength() {
      return this.$store.getters.userCustomersLength;
    },
    currentTargets: function () {
      if (this.$store.getters.userApplications) {
        const returnVal = this.$store.getters.userApplications
          .filter(display => display.userDropDownDisplay)
          .filter(application => {
            return this.$store.getters.globalCustomerHasApplication(
              application.name
            );
          })
          .slice()
          .sort((a, b) => a.serialNumber.localeCompare(b.serialNumber));
        return returnVal;
      } else {
        return this.$store.getters.userApplications;
      }
    },
    personalSettingsInfo() {
      return this.$store.getters.appRegistryByName("PersonalSettings");
    },
    userApplications() {
      return this.$store.getters.userApplications;
    }
  },

  methods: {
    //  userHasAccess(appName, accessCapabilityName) {
    //     if (appName == "ProjectGroupManagement") return true;
    //     return this.$store.getters.hasCapability(
    //       appName,
    //       accessCapabilityName,
    //       this.$store.getters.globalCustomerProject(appName)
    //     );
    //   },
    showCartIcon() {
      return (
        this.cartItemsCount > 0 ||
        (this.$route.path != null &&
          this.$route.path != "" &&
          this.$route.path.includes("products"))
      );
    },
    //Product Navigate with parameters to show product details popup
    navigate: function (target) {
      const targetComponentLowerCase = target;
      if (!this.$router.hasRoute(targetComponentLowerCase))
        this.$router.addRoute({
          path: `/${targetComponentLowerCase}`,
          name: targetComponentLowerCase,
          component: this.activeComponunt(target)
        });

      this.$router.push({ name: targetComponentLowerCase });
    },
    retakeTour: function () {
      if (this.$route.name != undefined && this.$route.name != "home") {
        this.$router.push({ name: "home" }).then(() => {
          this.$store.dispatch("retakeTour", false);
        });
      } else if (this.$route.name != undefined && this.$route.name == "home") {
        this.$store.dispatch("retakeTour", false);
      }
    },
    activeComponunt(target) {
      return () => import(`../dynamic/Contact/${target}.vue`);
    },
    launchChangeLog() {
      this.$store.dispatch("updateReShowChangelog", true);
    }
  },

  components: {
    CartOverlay,
    DropDownMenuItem,
    Notification
  },

  mounted() {
    this.contactURL = process.env.VUE_APP_ContactURL;
  },
  created() {
    if (!this.$store.state[`ViewContactStore`])
      this.$store.registerModule("ViewContactStore", ViewContactStore);
    if (!this.$store.state[`cartStore`])
      this.$store.registerModule("cartStore", CartStore);
  }
};
</script>
<style>
[aria-disabled="true"] {
  color: #6c757d !important;
}

#profileIcon::before {
  font-size: 20px;
}

#topHeader {
  background-color: #7ca5d0;
  height: 39px;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: bold;
}

/* #topHeader:after{
  position: absolute;
  left: 0px;
  right: 0px;
  background: linear-gradient(0deg, #222, #fff);
  content: "";
  z-index: -1;
  height: 40px;
  width: 100%;
  opacity: 0.3;
} */

#topHeader-nav-collapse {
  margin-right: 130px;
}

#topHeader .nav-link {
  font-family: "Segoe UI" !important;
  font-size: 14px;
  font-weight: bold !important;
  color: #ffffff !important;
  position: relative;
}

#topHeader .dropdown-toggle::after {
  position: relative;
  top: 4px;
  font-size: 22px;
  margin-left: 10px;
}

#topHeader .dropdown-menu {
  top: 38px;
  outline: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0px 0px 3px 3px;
  border: none;
  min-width: 100%;
  margin-top: 0px !important;
  margin-right: 8px !important;
  max-height: calc(100vh - 76px);
  overflow: auto;
  white-space: nowrap;
}

#topHeader .dropdown-menu a {
  font-size: 13px;
  font-weight: 500;
  color: #1c4388;
}

#topHeader .dropdown-menu a:active {
  background-color: transparent;
}

#topHeader li:hover {
  background-color: transparent;
}

#topHeader a:hover {
  opacity: 0.7;
}

#contactUsLink:hover {
  background-color: #e94c39 !important;
}

#topHeader .fa-user-circle {
  margin-right: 9px;
  position: relative;
  top: 1px;
}

#topHeader .fa-question-circle {
  font-size: 20px;
  margin-top: 0px;
  margin-right: 8px;
}

#userNameSButton a:after {
  top: 3px !important;
}

#userName {
  position: relative;
  top: -1px;
}

#o360projectSel {
  top: 0.1px;
}
#contactUsLink .cnText {
  font-size: 12px;
}
#contactUsLink ul li a {
  font-size: 14px;
  font-weight: 500;
  color: #0067ce;
  padding: 10px 10px;
  margin: 0px 0px 3px 0px;
  opacity: 1;
}
#contactUsLink ul li a:hover,
#contactUsLink ul li a:focus {
  background: #e94c39 !important;
  color: #ffffff;
  opacity: 1;
}
#contactUsLink ul {
  width: 200px;
  top: 28px !important;
  right: -8px;
  padding: 0;
}
#contactUsLink.show .dropdown-toggle:after {
  transform: rotate(180deg);
}
#contactUsLink.show {
  border-radius: 15px 15px 0px 0px !important;
}
@media only screen and (min-width: 992px) {
  #contactUsLink {
    border-radius: 20px;
    background-color: #e94c39;
    height: 27px;
    width: 145px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;
    margin-left: 20px;
  }

  #contactUsLink a {
    line-height: 12px;
    padding: 7px;
  }

  #helpPagesLink {
    margin-right: 12px;
  }
}

@media only screen and (max-width: 1675px) and (min-width: 1360px) {
  #topHeader-nav-collapse {
    margin-right: 68px;
  }
}

@media only screen and (max-width: 1359px) {
  #topHeader-nav-collapse {
    margin-right: 24px;
  }
}

@media only screen and (max-width: 991px) {
  #topHeader #nav-collapse {
    z-index: 2;
    background: #7ca5d0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  #topHeader-nav-collapse {
    margin-right: -16px;
    margin-left: -16px;
  }
}
</style>
<style>
@media only screen and (max-width: 992px) {
  #top-header .navbar {
    padding: 0rem 1rem !important;
  }
  #topHeader .nav-link {
    background-color: #7ca5d0;
  }

  #topHeader .nav-link:hover {
    background-color: #1c3766;
  }

  #topHeader a:hover {
    opacity: 1;
  }
}
</style>

export default {
  state: {
    contactDetails: [],
    contactUssubmitted: null,
    showLoaderContact: false,
    noRecordFound: false,
    contactLoader: false,
    showContractContect: false
  },
  getters: {
    contactDetails: state => {
      return state.contactDetails;
    },

    isContactSubmitted: state => {
      return state.contactUssubmitted;
    },
    showLoaderContact: state => {
      return state.showLoaderContact;
    },

    isNoRecord: state => {
      return state.noRecordFound;
    },

    showContactLoader: state => {
      return state.contactLoader;
    },
    getContractContact: state => {
      return state.showContractContect;
    }
  },

  actions: {
    setContactUsSubmitted({ commit }, visible) {
      commit("SET_CONTACT_SUCCESS", visible);
    },
    setShowLoaderContact({ commit }, showLoaderContact) {
      commit("SET_SHOW_LOADER_Contact", showLoaderContact);
    },

    setContactDetails({ commit }, contactDetails) {
      commit("SET_CONTACT_DETAILS", contactDetails);
    },

    setNoRecord({ commit }, noContact) {
      commit("SET_NO_CONTACT", noContact);
    },

    setContactLoader({ commit }, contactLoader) {
      commit("SET_CONTACT_LOADER", contactLoader);
    },
    contractContact({ commit }, contractContact) {
      console.log("contractContact", contractContact);
      commit("SET_CONTRACT_CONTACT", contractContact);
    }
  },

  mutations: {
    SET_CONTACT_SUCCESS: (state, visible) => {
      state.contactUssubmitted = visible;
    },
    SET_SHOW_LOADER_Contact: (state, showLoaderContact) => {
      state.showLoaderContact = showLoaderContact;
    },

    SET_CONTACT_DETAILS: (state, contactDetails) => {
      state.contactDetails = contactDetails;
    },

    SET_NO_CONTACT: (state, noContact) => {
      state.noRecordFound = noContact;
    },

    SET_CONTACT_LOADER: (state, loader) => {
      state.contactLoader = loader;
    },
    SET_CONTRACT_CONTACT: (state, contractContact) => {
      state.showContractContect = contractContact;
    }
  }
};

<template>
  <div class="Dash-container">
    <div id="header-bar">
      <b-navbar class="head-bar" toggleable="lg" type="dark">
        <b-navbar-brand class="ologo">
          <img class="logo360" :src="osmose360LogoURL" />
        </b-navbar-brand>
      </b-navbar>
    </div>
    <!-- Tabs -->

    <div class="coreContainer">
      <ul class="nav nav-tabs">
        <li id="privacy-tab" class="nav-item">
          <a
            class="nav-link"
            @click.prevent="setActive('privacy')"
            :class="{ active: isActive('privacy') }"
            href="#"
            >Privacy</a
          >
        </li>
        <li id="terms-tab" class="nav-item">
          <a
            class="nav-link"
            @click.prevent="setActive('terms')"
            :class="{ active: isActive('terms') }"
            href="#"
            >Terms and Conditions</a
          >
        </li>
      </ul>
      <div class="tab-content py-3" id="privacyContent">
        <!-- Privacy Start -->

        <div
          class="tab-pane fade"
          :class="{ 'active show': isActive('privacy') }"
          id="privacy"
        >
          <div>
            <div>
              <p class="eff-date">Effective as of June 1st, 2020</p>
            </div>
            <div class="table-content">
              <div>
                <p class="content-header">Table of Contents</p>
              </div>
              <div class="contents-body">
                <p class="pera-content">
                  <a target="_self" href="#overview" class="nav-link">
                    <span>Overview</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a
                    target="_self"
                    href="#collection-information"
                    class="nav-link"
                  >
                    <span>Collection of your Personal Information</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#use-information" class="nav-link">
                    <span>Use of your Personal Information</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a
                    target="_self"
                    href="#sharing-information"
                    class="nav-link"
                  >
                    <span>Sharing Information with Third Parties</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#tracking-user" class="nav-link">
                    <span>Tracking User Behavior</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#automatically" class="nav-link">
                    <span>Automatically Collected Information</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#links" class="nav-link">
                    <span>Links</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a
                    target="_self"
                    href="#security-information"
                    class="nav-link"
                  >
                    <span>Security of your Personal Information</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#communications" class="nav-link">
                    <span>E-mail Communications</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#statement" class="nav-link">
                    <span>Changes to this Statement</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a
                    target="_self"
                    href="#contact-information"
                    class="nav-link"
                  >
                    <span>Contact Information</span>
                  </a>
                </p>
              </div>
            </div>
            <div>
              <div class="main-text">
                <p class="pera-header-top">Privacy Policy</p>
                <div>
                  <p class="pera-header" id="overview">Overview</p>
                  <p class="pera-text">
                    Protecting your private information is our priority. This
                    Privacy Policy applies to the Osmose360.com website (the
                    “Site”) and Osmose Utilities Services, Inc. and governs data
                    collection and usage. By using this Site, you consent to the
                    data practices described in this statement.
                  </p>
                </div>

                <div>
                  <p class="pera-header" id="collection-information">
                    Collection of your Personal Information
                  </p>
                  <p class="pera-text">
                    In order to better provide you with products and services
                    offered on our Site, Osmose may collect personally
                    identifiable information (“PII”), such as your:
                  </p>

                  <p class="pera-text-list">
                    - First and Last Name
                    <br />- Mailing Address <br />- E-mail Address <br />- Phone
                    Number <br />- Employer <br />- Job Title
                  </p>

                  <p class="pera-text">
                    We only collect personal information that you have
                    voluntarily provided to us. You may be required to provide
                    certain personal information to us when you elect to use
                    certain products or services available on the Site such as
                    registering for an account on our Site, sending us an email
                    message, or requesting a quotation for product sales. In
                    general, we will use your information to communicate with
                    you in relation to information, services, and/or products
                    you have requested from us. We also may gather additional
                    personal or non-personal information in the future.
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="use-information">
                    Use of your Personal Information
                  </p>
                  <p class="pera-text">
                    Osmose collects and uses your PII to operate the Site and
                    deliver the services you have requested.
                    <br />Osmose may also use your PII to inform you of other
                    products or services available from Osmose and its
                    affiliates.
                  </p>
                </div>

                <div>
                  <p class="pera-header" id="sharing-information">
                    Sharing Information with Third Parties
                  </p>
                  <p class="pera-text">
                    Osmose does not sell, rent, or lease its customer lists to
                    third parties.
                    <br />
                    <br />Osmose may share data with trusted partners to help
                    perform statistical analysis, send you email or postal mail,
                    provide customer support, or arrange for deliveries. All
                    such third parties are prohibited from using your personal
                    information except to provide these services to Osmose and
                    they are required to maintain the confidentiality of your
                    information. Osmose may disclose your personal information,
                    without notice, if required to do so by law or in the good
                    faith belief that such action is necessary to: (a) conform
                    to the edicts of the law or comply with legal process served
                    on Osmose or the site; (b) protect and defend the rights or
                    property of Osmose; and/or (c) act under exigent
                    circumstances to protect the personal safety of users of
                    Osmose, or the public.
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="tracking-user">
                    Tracking User Behavior
                  </p>
                  <p class="pera-text">
                    Osmose may keep track of the websites and pages our users
                    visit within Osmose. This data is used to deliver a
                    customized high-quality experience to visitors to the Site.
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="automatically">
                    Automatically Collected Information
                  </p>
                  <p class="pera-text">
                    Information about your computer may be automatically
                    collected by Osmose. This information can include your IP
                    address, browser type, domain names, access times, and
                    referring website addresses. This information is used for
                    the operation of the service, to maintain quality of the
                    service, and to provide general statistics regarding use of
                    the Site.
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="links">Links</p>
                  <p class="pera-text">
                    This Site contains links to other sites. Please be aware
                    that we are not responsible for the content or privacy
                    practices of such other sites. We encourage our users to be
                    aware when they leave our site and to read the privacy
                    statements of any other site that collects PII.
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="security-information">
                    Security of your Personal Information
                  </p>
                  <p class="pera-text">
                    Osmose uses accepted best practices and state-of-the-art
                    technology to secure your personal information from
                    unauthorized access, use, or disclosure.
                    <br />
                    <br />We strive to take appropriate security measures to
                    protect against unauthorized access to or alteration of your
                    personal information. Unfortunately, no data transmission
                    over the Internet or any wireless network can be guaranteed
                    to be 100% secure. As a result, while we strive to protect
                    your personal information, you acknowledge that: (a) there
                    are security and privacy limitations inherent to the
                    Internet which are beyond our control; and (b) security,
                    integrity, and privacy of any and all information and data
                    exchanged between you and us through this Site cannot be
                    guaranteed.
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="communications">
                    E-mail Communications
                  </p>
                  <p class="pera-text">
                    From time to time, Osmose may contact you via email for the
                    purpose of providing announcements, promotional offers,
                    alerts, confirmations, surveys, and/or other general
                    communication. In order to improve our services and
                    products, we may receive a notification when you open an
                    email from Osmose or click on a link therein.
                    <br />
                    <br />If you would like to stop receiving marketing or
                    promotional communications via email from Osmose, you may
                    opt out of such communications by contacting
                    <a href="mailto:osmose360support@osmose.com">
                      osmose360support@osmose.com
                    </a>
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="statement">
                    Changes to this Statement
                  </p>
                  <p class="pera-text">
                    Osmose reserves the right to change this Privacy Policy from
                    time to time. We will notify you about significant changes
                    in the way we treat PPI by sending a notice to the primary
                    email address specified in your account, by placing a
                    prominent notice on our site, and/or by updating this page.
                    Your continued use of the Site and/or services available
                    through this Site after such modifications will constitute
                    your: (a) acknowledgment of the modified Privacy Policy; and
                    (b) agreement to abide and be bound by that Policy.
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="contact-information">
                    Contact Information
                  </p>
                  <p class="pera-text">
                    Osmose welcomes your questions or comments regarding this
                    Privacy Policy. You may contact Osmose at:
                    <br />
                    <br />Osmose Utilities Services, Inc. <br />Product Manager
                    – Osmose 360 <br />635 Highway 74 S <br />Peachtree
                    City,Georgia 30269 <br />United States of America
                    <br />(770) 632-6700 <br />
                    <a href="mailto:osmose360support@osmose.com"
                      >osmose360support@osmose.com</a
                    >
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Terms Start -->
        <div
          class="tab-pane fade"
          :class="{ 'active show': isActive('terms') }"
          id="terms"
        >
          <div>
            <div>
              <p class="eff-date">Effective as of June 1st, 2020</p>
            </div>
            <div class="table-content-terms">
              <div>
                <p class="content-header">Table of Contents</p>
              </div>
              <div class="contents-body">
                <p class="pera-content">
                  <a target="_self" href="#agreement" class="nav-link">
                    <span>Agreement between User and Osmose</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#terms-overview" class="nav-link">
                    <span>Overview</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#terms-privacy" class="nav-link">
                    <span>Privacy</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#electronic" class="nav-link">
                    <span>Electronic Communications</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#your-account " class="nav-link">
                    <span>Your Account</span>
                  </a>
                </p>

                <p class="pera-content">
                  <a target="_self" href="#links-services" class="nav-link">
                    <span>Links to Third Party Sites/Third Party Services</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#no-unlawful" class="nav-link">
                    <span
                      >No Unlawful or Prohibited Use/Intellectual Property</span
                    >
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#international" class="nav-link">
                    <span>International Users</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#indemnification " class="nav-link">
                    <span>Indemnification</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#arbitration" class="nav-link">
                    <span>Arbitration</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#waiver" class="nav-link">
                    <span>Class Action Waiver</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#liability" class="nav-link">
                    <span>Liability Disclaimer</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#termination" class="nav-link">
                    <span>Termination/Access Restriction</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#governing" class="nav-link">
                    <span>Governing Law & Jurisdiction</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#changes-terms " class="nav-link">
                    <span>Changes to Terms</span>
                  </a>
                </p>
                <p class="pera-content">
                  <a target="_self" href="#terms-contact" class="nav-link">
                    <span>Contact Us</span>
                  </a>
                </p>
              </div>
            </div>
            <div>
              <div class="main-text">
                <p class="pera-header-top">Terms and Conditions</p>
                <div>
                  <p class="pera-header" id="agreement">
                    Agreement between User and Osmose
                  </p>
                  <p class="pera-text">
                    Welcome to Osmose360.com (the “Site”). The Site is comprised
                    of various web pages operated by Osmose Utilities Services,
                    Inc. ("Osmose"). The Site is offered to you conditioned on
                    your acceptance without modification of the terms,
                    conditions, and notices contained herein (the "Terms"). Your
                    use of the Site constitutes your agreement to all such
                    Terms.
                  </p>
                </div>

                <div>
                  <p class="pera-header" id="terms-overview">Overview</p>
                  <p class="pera-text">
                    Osmose360.com is an interactive customer portal designed to
                    provide utility structure owners a platform to stay
                    connected to their project data. Features include Project
                    Dashboard, Map View, Knowledge Base, Joint Use Applications,
                    OsmoVault and numerous others. Users of the portal are
                    primarily employees of electric and communication utility
                    companies (“Company” or “Companies”) as well as individuals
                    doing business on behalf of Companies. Osmose may or may not
                    have services agreements with these Companies. Some features
                    may be blocked to employees of Companies with no (or
                    limited) service agreements with Osmose. Osmose reserves the
                    right to block specific features to specific users.
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="terms-privacy">Privacy</p>
                  <p class="pera-text">
                    Your use of the Site is subject to Osmose's Privacy Policy.
                    Please review our Privacy Policy to learn more about our
                    collection and use of your personal data.
                  </p>
                </div>

                <div>
                  <p class="pera-header" id="electronic">
                    Electronic Communications
                  </p>
                  <p class="pera-text">
                    Visiting the Site or sending emails to Osmose constitutes
                    electronic communications. You consent to receive electronic
                    communications from Osmose and you agree that all
                    agreements, notices, disclosures, and other communications
                    that we provide to you electronically, via email, and on the
                    Site satisfy any legal requirement that such communications
                    be in writing.
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="your-account">Your Account</p>
                  <p class="pera-text">
                    If you use this Site, you are responsible for maintaining
                    the confidentiality of your account credentials and password
                    and you agree to accept responsibility for all activities
                    that occur under your account or password. You may not
                    assign or otherwise transfer your account to any other
                    person or entity. You acknowledge that Osmose is not
                    responsible for third party access to your account that
                    results from theft or misappropriation of your account.
                    Osmose and its associates reserve the right to refuse or
                    cancel service, terminate accounts, or remove or edit
                    content in our sole discretion. Accounts may be suspended
                    due to inactivity.
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="links-services">
                    Links to Third Party Sites/Third Party Services
                  </p>
                  <p class="pera-text">
                    The Site may contain links to other websites ("Linked
                    Sites"). The Linked Sites are not under the control of
                    Osmose, and Osmose is not responsible for the contents of
                    any Linked Site, including without limitation any link
                    contained in a Linked Site or any changes or updates to a
                    Linked Site. Osmose is providing these links to you only as
                    a convenience, and the inclusion of any link does not imply
                    endorsement by Osmose of the Linked Site or any association
                    with its operators. Certain services (“Service” or
                    “Services”) made available via the Site are delivered by
                    third party sites and organizations. By using any product,
                    service, or functionality originating from the Site, you
                    hereby acknowledge and consent that Osmose may share such
                    information and data with any third party with whom Osmose
                    has a contractual relationship to provide the requested
                    product, service, or functionality on behalf of the Site
                    users and customers.
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="no-unlawful">
                    No Unlawful or Prohibited Use/Intellectual Property
                  </p>
                  <p class="pera-text">
                    You are granted a non-exclusive, non-transferable, revocable
                    license to access and use the Site strictly in accordance
                    with these terms of use. As a condition of your use of the
                    Site, you warrant to Osmose that you will not use the Site
                    for any purpose that is unlawful or prohibited by these
                    Terms. You may not use the Site in any manner which could
                    damage, disable, overburden, or impair the Site or interfere
                    with any other party's use of the Site. You may not obtain
                    or attempt to obtain any materials or information through
                    any means not intentionally made available or provided for
                    through the Site.
                    <br />
                    <br />Other than content that you have opted to upload, the
                    content included as part of the Site such as text, graphics,
                    logos, images, video, audio, text, and documents, as well as
                    the compilation thereof and any software used on the Site is
                    the property of Osmose or its suppliers and is protected by
                    copyright and other laws that protect intellectual property
                    and proprietary rights. You agree to observe and abide by
                    all copyright and other proprietary notices, legends, or
                    other restrictions contained in any such content and will
                    not make any changes thereto.
                    <br />
                    <br />You will not modify, publish, transmit, reverse
                    engineer, participate in the transfer or sale, create
                    derivative works, or in any way exploit any of the content,
                    in whole or in part, found on the Site. Osmose content is
                    not for resale. Your use of the Site does not entitle you to
                    make any unauthorized use of any protected content, and in
                    particular you will not delete or alter any proprietary
                    rights or attribution notices in any content. You will use
                    protected content solely for your personal use and the use
                    of your Company and will make no other use of the content
                    without the express written permission of Osmose and the
                    copyright owner. You agree that you do not acquire any
                    ownership rights in any protected content. We do not grant
                    you any licenses, express or implied, to the intellectual
                    property of Osmose or our licensors except as expressly
                    authorized by these Terms.
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="international">
                    International Users
                  </p>
                  <p class="pera-text">
                    The Service is controlled, operated, and administered by
                    Osmose in the United States of America. If you access the
                    Service from a location outside the USA, you are responsible
                    for compliance with all local laws. You agree that you will
                    not use the Osmose content accessed through the Site in any
                    country or in any manner prohibited by any applicable laws,
                    restrictions, or regulations.
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="indemnification">
                    Indemnification
                  </p>
                  <p class="pera-text">
                    You agree to indemnify, defend, and hold harmless Osmose,
                    its officers, directors, employees, agents, contractors and
                    third parties for any losses, costs, liabilities, and
                    expenses (including reasonable attorney's fees) relating to
                    or arising out of your use of or inability to use the Site
                    or Services, any user postings made by you, your violation
                    of any terms of this Agreement or your violation of any
                    rights of a third party, or your violation of any applicable
                    laws, rules or regulations. Osmose reserves the right, at
                    its own cost, to assume the exclusive defense and control of
                    any matter otherwise subject to indemnification by you, in
                    which event you will fully cooperate with Osmose in
                    asserting any available defenses.
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="arbitration">Arbitration</p>
                  <p class="pera-text">
                    In the event the parties are not able to resolve any dispute
                    between them arising out of or concerning these Terms and
                    Conditions, or any provisions hereof, whether in contract,
                    tort, or otherwise at law or in equity for damages or any
                    other relief, then such dispute shall be resolved only by
                    final and binding arbitration pursuant to the Federal
                    Arbitration Act, conducted by a single neutral arbitrator
                    and administered by the American Arbitration Association, or
                    a similar arbitration service selected by the parties, in a
                    location mutually agreed upon by the parties. The
                    arbitrator's award shall be final and judgment may be
                    entered upon it in any court having jurisdiction. In the
                    event that any legal or equitable action, proceeding or
                    arbitration arises out of or concerns these Terms and
                    Conditions, the prevailing party shall be entitled to
                    recover its costs and reasonable attorney's fees. The
                    parties agree to arbitrate all disputes and claims in
                    regards to these Terms and Conditions or any disputes
                    arising as a result of these Terms and Conditions, whether
                    directly or indirectly, including Tort claims that are a
                    result of these Terms and Conditions. The parties agree that
                    the Federal Arbitration Act governs the interpretation and
                    enforcement of this provision. The entire dispute, including
                    the scope and enforceability of this arbitration provision
                    shall be determined by the Arbitrator. This arbitration
                    provision shall survive the termination of these Terms and
                    Conditions.
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="waiver">Class Action Waiver</p>
                  <p class="pera-text">
                    Any arbitration under these Terms and Conditions will take
                    place on an individual basis; class arbitrations and
                    class/representative/collective actions are not permitted.
                    THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE
                    OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A
                    PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE
                    AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A
                    PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further,
                    unless both you and Osmose agree otherwise, the arbitrator
                    may not consolidate more than one person's claims, and may
                    not otherwise preside over any form of a representative or
                    class proceeding.
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="liability">Liability Disclaimer</p>
                  <p class="pera-text">
                    THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED
                    IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR
                    TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
                    INFORMATION HEREIN. OSMOSE UTILITIES SERVICES, INC. AND/OR
                    ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE
                    SITE AT ANY TIME.
                    <br />
                    <br />OSMOSE UTILITIES SERVICES, INC. AND/OR ITS SUPPLIERS
                    MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY,
                    AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION,
                    SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED
                    ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED
                    BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS,
                    SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT
                    WARRANTY OR CONDITION OF ANY KIND. OSMOSE UTILITIES
                    SERVICES, INC. AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL
                    WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION,
                    SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING
                    ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
                    FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
                    NON-INFRINGEMENT. NOTHING CONTAINED ON THIS WEBSITE SHALL BE
                    CONSTRUED AS PROVIDING CONSULT OR ADVICE TO YOU.
                    <br />
                    <br />TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN
                    NO EVENT SHALL OSMOSE UTILITIES SERVICES, INC. AND/OR ITS
                    SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE,
                    INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
                    WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS
                    OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY
                    CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE
                    DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE
                    PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY
                    INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
                    GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT
                    OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT,
                    NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF OSMOSE
                    UTILITIES SERVICES, INC. OR ANY OF ITS SUPPLIERS HAS BEEN
                    ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME
                    STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
                    LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
                    DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU
                    ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY
                    OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
                    DISCONTINUE USING THE SITE
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="termination">
                    Termination/Access Restriction
                  </p>
                  <p class="pera-text">
                    Osmose reserves the right, in its sole discretion, to
                    terminate your access to the Site and the related Services
                    or any portion thereof at any time without notice. If at any
                    time you no longer want or need access to the Site, please
                    contact<a href="mailto:osmose360support@osmose.com">
                      osmose360support@osmose.com
                    </a>
                    and request that your account access be removed. Access will
                    be disabled promptly.
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="governing">
                    Governing Law & Jurisdiction
                  </p>
                  <p class="pera-text">
                    To the maximum extent permitted by law, this agreement is
                    governed by the laws of the State of Georgia and you hereby
                    consent to the exclusive jurisdiction and venue of courts in
                    Georgia in all disputes arising out of or relating to the
                    use of the Site. Use of the Site is unauthorized in any
                    jurisdiction that does not give effect to all provisions of
                    these Terms, including, without limitation, this section.
                    <br />
                    <br />You agree that no joint venture, partnership,
                    employment, or agency relationship exists between you and
                    Osmose as a result of this agreement or use of the Site.
                    Osmose's performance of this agreement is subject to
                    existing laws and legal process and nothing contained in
                    this agreement is in derogation of Osmose's right to comply
                    with governmental, court, and law enforcement requests or
                    requirements relating to your use of the Site or information
                    provided to or gathered by Osmose with respect to such use.
                    If any part of this agreement is determined to be invalid or
                    unenforceable pursuant to applicable law including but not
                    limited to the warranty disclaimers and liability
                    limitations set forth above, then the invalid or
                    unenforceable provision will be deemed superseded by a
                    valid, enforceable provision that most closely matches the
                    intent of the original provision and the remainder of the
                    agreement shall continue in effect.
                    <br />
                    <br />Unless otherwise specified herein, this agreement
                    constitutes the entire agreement between the user and Osmose
                    with respect to the Site and it supersedes all prior or
                    contemporaneous communications and proposals, whether
                    electronic, oral, or written, between the user and Osmose
                    with respect to the Site. A printed version of this
                    agreement and of any notice given in electronic form shall
                    be admissible in judicial or administrative proceedings
                    based upon or relating to this agreement to the same extent
                    and subject to the same conditions as other business
                    documents and records originally generated and maintained in
                    printed form. It is the express wish to the parties that
                    this agreement and all related documents be written in
                    English.
                  </p>
                </div>
                <div>
                  <p class="pera-header" id="changes-terms">Changes to Terms</p>
                  <p class="pera-text">
                    Osmose reserves the right, in its sole discretion, to change
                    or revise the Terms under which the Site is offered. The
                    most current version of the Terms will supersede all
                    previous versions. Osmose encourages you to periodically
                    review the Terms to stay informed of our updates.
                  </p>
                </div>

                <div>
                  <p class="pera-header" id="terms-contact">Contact Us</p>
                  <p class="pera-text">
                    Osmose welcomes your questions or comments regarding the
                    Terms. You may contact Osmose at:
                    <br />
                    <br />Osmose Utilities Services, Inc. <br />Osmose 360
                    Product Manager <br />635 Highway 74 S <br />Peachtree
                    City,Georgia 30269 <br />United States of America
                    <br />(770) 632-6700 <br />
                    <a href="mailto:osmose360support@osmose.com">
                      osmose360support@osmose.com
                    </a>
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <O360Footer></O360Footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeItem: "terms",
      id: ["privacy", "terms"],
      osmose360LogoURL: process.env.VUE_APP_OSMOSE360_LOGO_URL
    };
  },
  methods: {
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
      this.$router.push({ path: `/termsandconditions/${menuItem}` });
    },
    changedroute() {
      this.activeItem = this.$route.params.menu;
      // this.setActive(this.$route.params.menu);
    }
  },
  watch: {
    $route() {
      this.changedroute();
    }
  },
  mounted() {
    this.changedroute();
  }
};
</script>
<style scoped>
.head-bar {
  height: 70px;
  background: #1c3766 0% 0% no-repeat padding-box;
  opacity: 1;
  z-index: 1;
}

.logo360 {
  width: 200px;
  opacity: 1;
}

.ologo {
  padding-left: 160px;
}

.Dash-container {
  min-height: 300px;
  height: 100%;
  position: relative;
  background-color: rgba(59, 59, 59, 0.4);
  background-color: #ffffff;
  min-width: 540px;
}

.coreContainer {
  width: 100%;
  min-height: 620px;
}
body {
  padding: 20px 0;
}
#privacyContent {
  text-align: left;
  color: #1c3766;
  padding-left: 50px;
  font-family: "Segoe UI";
}
.pera-header {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 4px;
}
.pera-text {
  line-height: normal;
  font-size: 14px;
  margin-bottom: 9px;
}
.pera-text-list {
  line-height: normal;
  font-size: 14px;
  padding-left: 30px;
  margin-bottom: 8px;
}
.nav-link {
  width: 100%;
  color: #1c3766;
  font-size: 18px;
  font-family: "Segoe UI";
  font-weight: 500;
}
.pera-header-top {
  font-size: 28px;
  font-family: "Segoe UI";
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 5px;
}
.table-content {
  border: 1px solid #1c3766;
  height: 100%;
  width: 19%;
  float: right;
  text-align: center;
  margin-left: 20px;
  margin-right: 35px;
  margin-top: 45px;
}
.table-content-terms {
  border: 1px solid #1c3766;
  height: 100%;
  width: 19%;
  float: right;
  text-align: center;
  margin-left: 20px;
  margin-right: 35px;
  margin-top: 45px;
}
.main-text {
  width: 76%;
  float: left;
}
.pera-content {
  margin-left: 22px;
  margin-bottom: 1px;
  margin-top: 2px;
  width: 90%;
}
.pera-content .nav-link {
  width: 100%;
  text-align: left;
  color: #3864b1;
  font-size: 15px;
  font-family: "Segoe UI";
  margin-bottom: 1px;
  margin-top: 3px;
  font-weight: 500;
  padding: 3px;
  padding-right: 10px;
  line-height: normal;
}
.content-header {
  text-align: left;
  color: #3864b1;
  font-size: 18px;
  font-family: "Segoe UI";
  margin: 22px;
  font-weight: 700;
  margin-bottom: 12px;
}
.contents-body {
  margin-bottom: 15px;
}
#terms-tab {
  width: 265px;
}
#privacy-tab {
  width: 265px;
}
.nav-tabs .nav-link.active {
  font-weight: 700;
  font-size: 19px;
  color: #15428e;
}
.eff-date {
  margin-top: 20px;
  line-height: normal;
  font-size: 14px;
  font-family: "Segoe UI";
}
@media only screen and (max-width: 1420px) {
  .table-content {
    border: 1px solid #1c3766;
    height: 100%;
    width: 360px;
    float: left;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 12px;
  }
  .table-content-terms {
    border: 1px solid #1c3766;
    height: 100%;
    width: 360px;
    float: left;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 12px;
  }
  .main-text {
    width: 99%;
    float: left;
  }
}
</style>
<style>
@media only screen and (max-width: 992px) {
  #header-bar .navbar {
    padding: 0rem 1rem !important;
  }
}
</style>

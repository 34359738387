// import Vue from "vue"; // Include Vue
// import Router from "vue-router"; // Include Vue Router libary
import { createRouter, createWebHistory } from "vue-router";

import Signup from "@/components/infrastructure/Signup.vue"; // <--- We'll look at these in a moment
import Dashboard from "@/components/infrastructure/Dashboard.vue";
import TermsAndConditions from "@/components/infrastructure/TermsAndConditions.vue";
import Loading from "@/components/infrastructure/Loading.vue";
import Timedout from "@/components/infrastructure/Timedout.vue";

// This check is needed for unit tests to succeed since Vue Router
// cannot be overwritten in tests if router was registered globally
// Vue.use(Router);

// export default new Router({
//   mode: "history",
const routes = [
  {
    path: "/login",
    name: "signup",
    component: Signup,
    props: true
  },
  {
    path: "/signup",
    name: "signup",
    component: Signup,
    props: true
  },
  {
    path: "/home",
    name: "home",
    component: Dashboard,
    props: true
  },
  {
    path: "/termsandconditions/:menu",
    name: "termsandconditions",
    component: TermsAndConditions,
    props: true
  },
  {
    path: "/logout",
    name: "logout"
  },
  {
    path: "/loading",
    name: "loading",
    component: Loading
    // props: true
  },
  {
    path: "/timedout",
    name: "timedout",
    component: Timedout,
    props: true
  }
];
// });

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;

import Logger from "@/Lib/LoggingManager.js";

export const state = {
  LogServiceURL: process.env.VUE_APP_LOGGING_SERVICE_URL,
  RootLogPath: "O360Application",
  readyToCreateLoggedInLog: {
    currentProjectExist: false,
    userInfoExist: false,
    alreadyLogged: false
  }
};
export const mutations = {
  SET_READY_TO_CREATE_LOGGED_IN_LOG(state, newObj) {
    state.readyToCreateLoggedInLog = {
      ...state.readyToCreateLoggedInLog,
      ...newObj
    };
  }
};
export const getters = {
  readyToCreateLoggedInLog: state => {
    return state.readyToCreateLoggedInLog;
  }
};
export const actions = {
  logger({ state, commit, rootGetters }, obj = {}) {
    // Format of obj
    // obj = {
    //   RootLogPath: '<if different than default>',
    //   LoggingCategory: '<Logging category, Information, Warning, etc>',
    //   Action: '<Name of action>',
    //   Location: '<the route name>',
    //   Message: '<string>',
    //   ****** Any additional fields can be added here ******
    // }
    // Below is the default logObj
    if (
      rootGetters.currentProject &&
      rootGetters.currentProject.projectName == "Test Harness"
    ) {
      // Prevent Test Harness Logging
    } else {
      let customerName = "";
      if (rootGetters.isCustomer) {
        if (rootGetters.userInfo) {
          if (
            rootGetters.userInfo.UserPreferences.CustomerName &&
            rootGetters.userInfo.UserPreferences.CustomerName != ""
          ) {
            customerName = rootGetters.userInfo.UserPreferences.CustomerName;
          }
        }
      } else {
        customerName = "Osmose";
      }
      const logObj = {
        RootLogPath: state.RootLogPath,
        LoggingCategory: "Information",
        UserId: rootGetters.userId,
        UserName: rootGetters.userName,
        CustomerId:
          rootGetters.customerId && rootGetters.isCustomer
            ? rootGetters.customerId
            : "",
        CustomerName: customerName,
        ProjectGroup: rootGetters.currentProject
          ? rootGetters.currentProject.projectName
          : "",
        ...obj // Obj properties will override the default properties
      };

      console.log("Logging: ", logObj);
      Logger.logMessage(state.LogServiceURL, logObj);
    }
  },
  updateReadyToCreateLoggedInLog({ state, commit, rootGetters }, newObj) {
    commit("SET_READY_TO_CREATE_LOGGED_IN_LOG", newObj);
  }
};

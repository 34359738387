/**************************************
 * Functions to Execute a Cosmos Query
 **************************************/
import Axios from "axios";
import Store from "@/store/store";
import { uuid } from "vue-uuid";
import storageManager from "../LocalAPI/StorageManager";

export default {
  async executeQuery(
    queryPayload,
    queryServiceURL,
    callBackName,
    callBack,
    callBackAdditonalParams = {},
    authToken,
    queryName = "default",
    errorMessage
  ) {
    if (Object.keys(callBackAdditonalParams).length) {
      callBackAdditonalParams.queryCustomerId = Store.getters.currentCustomerId;
      callBackAdditonalParams.queryProjectId = Store.getters.currentProjectId;
    }
    return Axios.post(queryServiceURL, queryPayload, {
      headers: { Authorization: `Bearer ${authToken}` }
    })
      .then(queryResults => {
        if (queryResults.data.Status == true) {
          let results = queryResults.data.Results;

          if (!queryPayload.CountQuery)
            Store.dispatch("updateLoadingFilter", false);

          if (Object.keys(callBackAdditonalParams).length) {
            const tempResults = results;
            results = {
              results: tempResults,
              additonalParams: callBackAdditonalParams
            };
          }

          if (callBackName == "BULKIMAGE") {
            Store.dispatch("updateBulkImageText", "success");
            callBackName = "updateQueryResultsCSV";
          }

          if (queryPayload.Cacheable) {
            // store the results in the local storage
            const cacheKey = `${queryPayload.queryName}_${Store.getters.currentCustomerId}_${Store.getters.currentProjectId}`;
            let saveResult = true;
            try {
              saveResult = storageManager.saveData(cacheKey, results);
              if (saveResult == false)
                console.log(`could not save ${cacheKey}`);
            } catch (e) {
              console.log(`could not save ${cacheKey}`);
            }
          }

          callBack(callBackName, results);
        } else {
          if (callBackName == "BULKIMAGE") {
            Store.dispatch("updateBulkImageText", queryResults.data.Messages);
            return queryResults.data;
          } else if (
            queryResults.data.Messages[0] &&
            queryResults.data.Messages[0].includes("No Meta Data Found")
          ) {
            // this error will always throw when no data has been loaded,
            // suppress the error unless it's the count, in which case do stuff
            if (callBackName == "updateMVProjectPoleCountResult") {
              // no data has been loaded yet, return a count of 0
              Store.dispatch(callBackName, 0);
            }
            Store.dispatch("updateShowMVLoader", false);
          } else {
            const errorObj = {
              requestName: queryName,
              Error: queryResults.data.Messages,
              errorMessage: errorMessage
            };
            Store.dispatch("updateShowMVLoader", false);
            Store.dispatch("setAdvanceSearchQueryRunning", false);
            Store.dispatch("updateError", errorObj);
            // Store.dispatch("logger", {
            //   LoggingCategory: "Information",
            //   Action: "Query Failed"
            // });

            Store.dispatch("logger", {
              LoggingCategory: "Exception",
              Action: "executeQueryFailed",
              Message: `Could not execute query id ${queryName}`,
              StackTrace: error,
              ExceptionType: `Query Service Error`
            });
          }
        }
      })
      .catch(error => {
        console.log("There was an error:");
        console.dir(error);
        const errorObj = {
          requestName: queryName,
          Error: error,
          errorMessage: errorMessage
        };
        Store.dispatch("updateShowMVLoader", false);
        Store.dispatch("setAdvanceSearchQueryRunning", false);
        Store.dispatch("updateError", errorObj);
        // Store.dispatch("logger", {
        //   LoggingCategory: "Information",
        //   Action: "Query Failed"
        // });
        Store.dispatch("logger", {
          LoggingCategory: "Exception",
          Action: "executeQueryFailed",
          Message: `Could not execute query id ${queryName}`,
          StackTrace: error,
          ExceptionType: `Query Service Error`
        });
      });
  },
  executeGetQuery(
    queryServiceURL,
    callBackName,
    callBack,
    authToken,
    queryName = "default",
    errorMessage,
    callBackAdditionalParams = {}
  ) {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };

    if (queryName == "exportFullCurrentCSV")
      config.responseType = "arraybuffer";
    Axios.get(queryServiceURL, config)
      .then(queryResults => {
        //if (queryResults.data.Status == true) {

        if (Object.keys(callBackAdditionalParams).length) {
          const results = {
            results: queryResults.data,
            additionalParams: callBackAdditionalParams
          };
          callBack(callBackName, results);
        } else {
          callBack(callBackName, queryResults.data, queryName);
        }

        /*
        } else {
          var errorObj = {
            requestName: queryName,
            Error: queryResults.data.Messages,
            errorMessage: errorMessage
          };
          Store.dispatch("updateError", errorObj);
        }*/
      })
      .catch(error => {
        console.log("There was an error:");
        console.dir(error);
        const errorObj = {
          requestName: queryName,
          Error: error,
          errorMessage: errorMessage
        };
        Store.dispatch("updateShowMVLoader", false);
        Store.dispatch("updateError", errorObj);
        Store.dispatch("logger", {
          LoggingCategory: "Information",
          Action: "Query Failed"
        });
      });
  },
  async executeQueryFromSpec(
    querySpec,
    queryServiceURL,
    callBackName,
    callBack,
    callBackAdditonalParams = {},
    countQuery,
    additionalRules
  ) {
    // get this from the store

    //  determine if queryResults already exist in the local storage
    // if they do, then do not execute the query, get the results from local storage
    // and then call dispatch to populate the store
    // this  should live in Cosmose Query engine
    // the query spec should have property cacheable
    // when the query returns, if it is cacheable, then store the results
    // using query name
    // querySpec.Cacheable=true;

    // if the cache is stale, then remove the item
    // and then skip this

    // method in map view will control that
    // set stale when stale then not when not
    console.log("indexDBDisabled " + Store.getters.indexDBDisabled);
    if (querySpec.Cacheable && Store.getters.indexDBDisabled == false) {
      let removeItemError = false;
      const cacheKey = `${querySpec.queryName}_${Store.getters.currentCustomerId}_${Store.getters.currentProjectId}`;
      if (
        Store.getters.cacheStale == true &&
        Store.getters.refreshedItems[cacheKey] == null
      ) {
        try {
          removeItemError = await storageManager.removeItem(cacheKey);
          Store.dispatch("updateRefreshedItems", cacheKey);
        } catch (e) {
          console.log("could not remove item");
          removeItemError = true;
        }
      }
      let storageResults = [];

      if (removeItemError == false) {
        try {
          storageResults = await storageManager.getResults(cacheKey);
        } catch (e) {
          console.log(`could not retrieve Item ${cacheKey}`);
        }
      }

      if (storageResults.length > 0 && storageResults[0]) {
        console.log("found item" + cacheKey);
        //console.dir(storageResults)
        if (Object.keys(callBackAdditonalParams).length) {
          callBackAdditonalParams.queryCustomerId =
            Store.getters.currentCustomerId;
          callBackAdditonalParams.queryProjectId =
            Store.getters.currentProjectId;
          const results = {
            results: storageResults[0].results,
            additonalParams: callBackAdditonalParams
          };

          callBack(callBackName, results);
          return;
        }
      }
    }

    await this.executeQuery(
      this.configureQuery(querySpec, countQuery, additionalRules),
      queryServiceURL,
      callBackName,
      callBack,
      callBackAdditonalParams,
      querySpec.AuthToken,
      querySpec.queryName,
      querySpec.errorMessage
    );
  },
  /******************************
   * Create a query payload object
   * based on the query specs
   * passed in
   *******************************/
  configureQuery(querySpec, countQuery, additionalRules) {
    const payload = {};
    querySpec.QueryId && querySpec.QueryId != ""
      ? (payload.QueryId = querySpec.QueryId)
      : (payload.QueryId = uuid.v1());

    if (querySpec.Cacheable) {
      payload.queryName = querySpec.queryName;
      payload.Cacheable = true;
    }

    payload.CustomerId = querySpec.CustomerId;
    payload.Format = querySpec.Format;

    let CSVReport = false;

    if (querySpec.CSVReport && querySpec.CSVReport === true) {
      CSVReport = true;
    }
    if (CSVReport) {
      payload.ExportType = querySpec.exportType;
      payload.ExportScope = "partial";
      if (querySpec.CSVFullReport && querySpec.CSVFullReport === true) {
        payload.ExportScope = "full";
      }
    }
    if (querySpec.MaxSequence && querySpec.MaxSequence == true)
      payload.MaxSequence = querySpec.MaxSequence;

    payload.Async = false;
    if (querySpec.Async && querySpec.Async === true) payload.Async = true;

    // build the select clause column list
    // this will be constructed from the project visibility
    if (CSVReport) {
      const columnList = [
        {
          Field: "OUS_LOCATION",
          Alias: "OUS_LOCATION"
        }
      ];
      payload.ColumnList = columnList;
    } else if (querySpec.Visibility) {
      payload.ColumnList = this.getFormattedVisibility(
        querySpec.Visibility,
        querySpec.queryName
      );
    } else if (querySpec.ColumnList && querySpec.ColumnList.length > 0)
      payload.ColumnList = querySpec.ColumnList;

    // build the where clause
    // payload will have array of filter Rules
    // for types like reject status
    // rule will have sub rules with joined with an OR
    payload.Condition = querySpec.Conjunction;
    payload.VisibilityAlias = querySpec.VisibilityAlias;
    payload.Visibility = querySpec.Visibility;
    console.dir(querySpec);
    payload.GroupByFields = querySpec.GroupByFields;
    payload.Rules = [];
    querySpec.hasSplitBy
      ? (payload.hasSplitBy = true)
      : (payload.hasSplitBy = false);
    payload.SourceTableName = querySpec.SourceTableName;
    querySpec.Filters.forEach(filter => {
      // check the filter type. Types are Single, Multiple
      if (filter.FilterType == "Single") {
        const thisFilter = this.getSingleFilter(filter);
        payload.Rules.push(thisFilter);
      } else if (filter.FilterType == "Parent") {
        this.getParentFilters(filter, payload);
      } else {
        this.getMultipleFilter(filter, payload);
      }
    });

    // sequence section
    if (querySpec.SequenceIdStart && querySpec.SequenceIdEnd) {
      payload.SequenceIdStart = querySpec.SequenceIdStart;
      payload.SequenceIdEnd = querySpec.SequenceIdEnd;
    }

    //add aditional rules
    if (additionalRules?.Rules?.length)
      payload.Rules.push({ Condition: "AND", Rules: [additionalRules] });

    //if it is a count query
    if (countQuery) {
      payload.CountQuery = true;
      payload.Threashold = querySpec.Threashold;
    }

    // getting threashould vale
    if (querySpec.ReturnMax) payload.ReturnMax = true;

    console.log("payload", payload);

    // if this is a search flyout simple search
    // and mantenance type is selected
    // inject a maintenance status rule to the payload
    if (querySpec.SimpleSearch) {
      this.addStatusRule4(payload);
    }

    return payload;
  },
  getFormattedVisibility(visibility, queryName) {
    const columnList = [];

    Object.keys(visibility.Schema).forEach(tableName => {
      // replace commas with pipe delimiter ignore objects that are Location
      const tableColumns = visibility.Schema[tableName];

      Object.keys(tableColumns).forEach(columnName => {
        const displayName = tableColumns[columnName].DisplayName;
        const fieldName =
          tableColumns[columnName].TableName +
          (columnName != "voidColumn" ? "." : "") +
          tableColumns[columnName].ColumnName;
        const col = {
          Field: fieldName,
          Alias: displayName
        };
        columnList.push(col);
      });
    });

    if (
      (queryName == "filter" ||
        queryName == "getStructureDataByPolygon" ||
        queryName == "getStructureData") &&
      "OUS_LOCATION.OUS_INSPECTION" in visibility.Schema &&
      !("InspectionID" in visibility.Schema["OUS_LOCATION.OUS_INSPECTION"]) &&
      !("InspectionId" in visibility.Schema["OUS_LOCATION.OUS_INSPECTION"])
    ) {
      const inspectionIdColumn = {
        Alias: "OUS_LOCATION.OUS_INSPECTION.InspectionID",
        Field: "OUS_LOCATION.OUS_INSPECTION.InspectionID"
      };

      columnList.push(inspectionIdColumn);
    }
    return columnList;
  },
  getSingleFilter(filter) {
    const rule = {};
    if (filter.FilterType == "Single") {
      rule.Operator = filter.Operator; // greater,less_than,equal,not_equal,etc
      rule.Type = filter.Type; // data type integer, string, datetime, etc
      rule.Field = filter.Field; // fully qualified atttribute name, i.e Location.Id
      rule.Value = filter.Value; // value to test against, needs to respect data type
      rule.Format = filter.Format;
      rule.Condition = filter.Condition;
      if (filter.LimitRule) {
        rule.LimitRule = filter.LimitRule;
      }
      if (filter.IncludeChildren) rule.IncludeChildren = filter.IncludeChildren;

      if (filter.Name) rule.Name = filter.Name;
      if (filter.Filters) {
        rule.Rules = [];
        // if(!filter.New)
        this.getChildFilters(rule, filter.Filters);
      }
      return rule;
    }
  },
  getMultipleFilter(filter, payload) {
    const parentRule = {};
    parentRule.Rules = [];
    parentRule.LimitRule = filter.LimitRule;
    parentRule.Condition = "OR";
    payload.Rules.push(parentRule);
    filter?.Values?.forEach(value => {
      // construct rule for each value
      const childRule = {};
      childRule.Operator = filter.Operator;
      childRule.Type = filter.Type; // data type integer, string, datetime, etc
      childRule.Field = filter.Field; // fully qualified atttribute name, i.e Location.Id
      childRule.Value = value; // value to test against, needs to respect data type
      parentRule.Rules.push(childRule);
    });

    return payload;
  },
  getCombinedFilter(filter, payload) {
    filter.Values.forEach(value => {
      // construct rule for each value
      const childRule = {};
      childRule.Operator = filter.Operator;
      childRule.Type = filter.Type; // data type integer, string, datetime, etc
      childRule.Field = filter.Field; // fully qualified atttribute name, i.e Location.Id
      childRule.Value = value; // value to test against, needs to respect data type
      payload.Rules.push(childRule);
    });
    return payload;
  },
  getRulesFromFilter(filter) {
    const payload = {};
    payload.Rules = [];
    filter.Filters.forEach(filter => {
      // check the filter type. Types are Single, Multiple
      if (filter.FilterType == "Single") {
        const thisFilter = this.getSingleFilter(filter);
        payload.Rules.push(thisFilter);
      } else if (filter.FilterType == "Parent") {
        this.getParentFilters(filter, payload);
      } else {
        this.getMultipleFilter(filter, payload);
      }
    });
    return payload;
  },
  getParentFilters(filter, payload) {
    const parentRule = {};
    parentRule.Rules = [];
    if (filter.Conjunction) parentRule.Condition = filter.Conjunction;
    else parentRule.Condition = "OR"; // maybe get this from filter passed in
    filter.Filters.forEach(value => {
      if (value.FormatType === "Native") {
        parentRule.Rules.push(value);
      } else if (value.FilterType == "Single") {
        const thisFilter = this.getSingleFilter(value);
        parentRule.Rules.push(thisFilter);
      } else if (value.FilterType == "Parent") {
        this.getParentFilters(value, parentRule);
      } else if (value.FilterType == "Multiple") {
        this.getMultipleFilter(value, parentRule);
      } else {
        this.getCombinedFilter(value, parentRule);
      }
    });
    // do not process the same multiple filter twice

    payload.Rules.push(parentRule);
  },
  getChildFilters(rule, filters) {
    if (filters && filters.length > 0) {
      filters.forEach(filter => {
        if (filter.FilterType == "Single") {
          const thisFilter = this.getSingleFilter(filter);
          //thisFilter.New=true;
          rule.Rules.push(thisFilter);
          // only single types can have children
          // this.getChildFilters(thisFilter,filter.Filters);
        } else if (filter.FilterType == "Parent") {
          this.getParentFilters(filter, rule);
        } else {
          this.getMultipleFilter(filter, rule);
        }
      });
    }
  },

  addStatusRule4(rule, parentRule) {
    if (
      parentRule &&
      rule.Field &&
      rule.Field == "OUS_LOCATION.OUS_INSPECTION.OUS_MAINTENANCE.Type"
    ) {
      const newRule = {
        id: "newRule",
        Condition: "AND",
        Rules: [rule]
      };
      const statusRule = {
        Operator: "equal",
        Value: "Open",
        Field: "OUS_LOCATION.OUS_INSPECTION.OUS_MAINTENANCE.Status",
        Type: "string"
      };
      newRule.Rules.push(statusRule);
      parentRule.Rules.push(newRule);
      rule.Remove = true;
    }

    if (rule.Rules) {
      rule.Rules.forEach(childRule => {
        this.addStatusRule4(childRule, rule);
      });
      rule.Rules = rule.Rules.filter(theRule => !theRule.Remove);
    }
  }
};

import { useSearchResultsStore } from "../../../../../src/components/dynamic/MapViewV2/LocalStore/SearchResultsStore";
export class O360StoreHelper {
  getConfigurationsProxy() {
    const searchResultsStore = useSearchResultsStore();
    return searchResultsStore.getConfigurations;
  }
  getUserProjectConfigProxy() {
    const searchResultsStore = useSearchResultsStore();
    return searchResultsStore.getUserProjectConfiguration;
  }
  getProjectApplicationConfigProxy() {
    const searchResultsStore = useSearchResultsStore();
    return searchResultsStore.getProjectApplicationConfigProxy;
  }
  getHighLightLayerCount(): number {
    const searchResultsStore = useSearchResultsStore();
    return searchResultsStore.getHighLightLayerCount ?? 0;
  }
}

// import Vue from "vue";
import Vuex from "vuex";
import * as authorizationStore from "@/store/Modules/AuthorizationStore.js";
// import * as projectStore from "@/store/Modules/ProjectStore.js";
/*import * as mapStore from "@/store/Modules/MapStore.js";
import * as projectStore from "@/store/Modules/ProjectStore.js";
import * as queryEngineStore from "@/store/Modules/QueryEngineStore.js"; */
import * as errorStore from "@/store/Modules/ErrorStore.js";
// import * as statesMappingStore from "@/store/Modules/StatesMappingStore.js";
import * as LoggingStore from "@/store/Modules/LoggingStore.js";
import * as NotificationStore from "@/store/Modules/NotificationStore.js";
// Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    /* mapStore,
    projectStore,
    queryEngineStore,
    errorStore */
    // projectStore,
    authorizationStore,
    errorStore,
    // statesMappingStore,
    LoggingStore,
    NotificationStore
  },
  state: {}
});

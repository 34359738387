/***********************************************
 * Joint use API's
 * *********************************************
 * functions to obtain Grid Data on project selection
 * Information from API Services
 *
 **********************************************/
/////////////////// Imports ///////////////////
import Axios from "axios";
import Store from "@/store/store";
export default {
  /*
  getCustomerIdOrig: function(apiURL, gridDataURL, authToken, userId) {
    Axios.get(apiURL, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
      .then(response => {
        Store.dispatch("updateProjectDoAuth", response.data);
        Store.dispatch(
          "updateWidgetList",
          response.data.defaultWidgetLocations
        );

        let projectId = response.data.ProjectId;
        let customerId = response.data.CustomerId;
        let url = gridDataURL + `?id1=${customerId}&id2=${projectId}`;

        if (userId) {
          url =
            userFilter && userFilter.length
              ? gridDataURL
              : gridDataURL + `/${userId}/${projectId}`;
        }
        this.getJointUseGridData(url, authToken);
      })
      .catch(error => {
        var errorObj = {
          requestName: "getVisibility",
          errorMessage:
            "Could not retrieve project id. Please try again. If this error continues to occur, please contact support.",
          Error: error
        };
        //Thow error in the UI.
        Store.dispatch("updateError", errorObj);
        Store.dispatch("updateJointUseLoader", false);
      });
  },
  */
  getCustomerId: function (apiURL, authToken) {
    Axios.get(apiURL, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
      .then(response => {
        Store.dispatch("updateJointUseLoader", false);
        Store.dispatch("updateProjectDoAuth", response.data);
        Store.dispatch(
          "updateWidgetList",
          response.data.defaultWidgetLocations
        );
      })
      .catch(error => {
        const errorObj = {
          requestName: "getProjectDOAuth",
          errorMessage:
            "Could not retrieve project id. Please try again. If this error continues to occur, please contact support.",
          Error: error
        };
        //Thow error in the UI.
        Store.dispatch("updateError", errorObj);
        Store.dispatch("updateJointUseLoader", false);
      });
  },
  getJointUseGridData: function (apiURL, authToken, payload = undefined) {
    if (payload) {
      Axios.post(apiURL, payload, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      })
        .then(response => {
          Store.dispatch("updateJointUseData", response.data);
          Store.dispatch("updateJointUseLoader", false);
        })
        .catch(error => {
          const errorObj = {
            requestName: "getVisibility",
            errorMessage:
              "Could not retrieve joint use grid content. Please try again. If this error continues to occur, please contact support.",
            Error: error
          };
          //Thow error in the UI.
          Store.dispatch("updateError", errorObj);
          Store.dispatch("updateJointUseLoader", false);
        });
    } else {
      Axios.get(apiURL, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      })
        .then(response => {
          Store.dispatch("updateJointUseData", response.data);
          Store.dispatch("updateJointUseLoader", false);
        })
        .catch(error => {
          const errorObj = {
            requestName: "getVisibility",
            errorMessage:
              "Could not retrieve joint use grid content. Please try again. If this error continues to occur, please contact support.",
            Error: error
          };
          //Thow error in the UI.
          Store.dispatch("updateError", errorObj);
          Store.dispatch("updateJointUseLoader", false);
        });
    }
  },
  getUSStates: function (apiURL, authToken) {
    Axios.get(apiURL, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
      .then(response => {
        Store.dispatch("updateUSStates", response.data);
      })
      .catch(error => {
        const errorObj = {
          requestName: "getVisibility",
          errorMessage:
            "Could not retrieve US States. Please try again. If this error continues to occur, please contact support.",
          Error: error
        };
        //Thow error in the UI.
        Store.dispatch("updateError", errorObj);
        Store.dispatch("updateJointUseLoader", false);
      });
  },
  getApplicationDetails: function (apiURL, authToken, callback) {
    Store.dispatch("updateJointUseLoader", true);
    return Axios.get(apiURL, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
      .then(response => {
        Store.dispatch("setSelectedApplication", response.data);
        Store.dispatch("updateJointUseLoader", false);
        Store.dispatch("setJUApplicationDetails", response.data);
        if (callback) {
          callback(response.data);
        }
      })
      .catch(error => {
        const errorObj = {
          requestName: "getVisibility",
          errorMessage:
            "Could not retrieve application details. Please try again. If this error continues to occur, please contact support.",
          Error: error
        };
        //Thow error in the UI.
        Store.dispatch("updateError", errorObj);
        Store.dispatch("updateJointUseLoader", false);
      });
  },
  addWorkRequestComment: function (apiURL, authToken, payload) {
    Store.dispatch("updateJointUseLoader", true);
    Axios.post(apiURL, payload, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
      .then(response => {
        Store.dispatch("pushApplicationComment", response.data);
        Store.dispatch("updateJointUseLoader", false);
      })
      .catch(error => {
        const errorObj = {
          requestName: "getVisibility",
          errorMessage:
            "Could not add comment. Please try again. If this error continues to occur, please contact support.",
          Error: error
        };
        //Thow error in the UI.
        Store.dispatch("updateError", errorObj);
        Store.dispatch("updateJointUseLoader", false);
      });
  },
  getGridPoleData: function (
    apiURL,
    authToken,
    callBack,
    getDataFromGrid,
    reload
  ) {
    Axios.get(apiURL, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
      .then(response => {
        callBack(response.data.ApplicationPoles, getDataFromGrid, reload);
      })
      .catch(error => {
        const errorObj = {
          requestName: "getVisibility",
          errorMessage:
            "Could not retrieve pole grid data. Please try again. If this error continues to occur, please contact support.",
          Error: error
        };
        //Thow error in the UI.
        Store.dispatch("updateError", errorObj);
      });
  },
  downloadSinglePoleFiles: function (apiURL, authToken, payload, callback) {
    Store.dispatch("updateJointUseLoader", true);
    Axios.post(apiURL, payload, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
      .then(response => {
        if (response) {
          callback(response);
        } else {
          const errorObj = {
            requestName: "getVisibility",
            errorMessage:
              "Could not initiate download. Please try again. If this error continues to occur, please contact support.",
            Error: response
          };
          //Thow error in the UI.
          Store.dispatch("updateError", errorObj);
        }
        Store.dispatch("updateJointUseLoader", false);
      })
      .catch(error => {
        const errorObj = {
          requestName: "getVisibility",
          errorMessage:
            "Could not initiate download. Please try again. If this error continues to occur, please contact support.",
          Error: error
        };
        //Thow error in the UI.
        Store.dispatch("updateError", errorObj);
        Store.dispatch("updateJointUseLoader", false);
      });
  },
  requestApplicationFilesDownload(
    workReqUrl,
    apiURL,
    authToken,
    payload,
    customerId,
    IncludeAnnotation,
    callback,
    noFileCallBack
  ) {
    function getWorkRequestByID(workReqUrl, authToken) {
      Store.dispatch("updateJointUseLoader", true);
      return Axios.get(workReqUrl, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });
    }
    getWorkRequestByID(workReqUrl, authToken)
      .then(response => {
        const res = response.data;
        if (
          (res.Files &&
            res.Files.filter(doc => doc.DesignDocument).length > 0) ||
          (res.ApplicationPoles &&
            res.ApplicationPoles.filter(
              a => a.TrackingStatusID == 10 && a.Files.length > 0
            ).length > 0)
        ) {
          requestDownload(apiURL, payload, authToken, callback);
        } else {
          const deliveredPoles = res.ApplicationPoles.filter(
            a => a.TrackingStatusID == 10 && a.InspectionID != null
          );

          const inspectionIds = deliveredPoles.map(b => b.InspectionID);
          if (inspectionIds.length > 0) {
            getImageToken(
              process.env.VUE_APP_MEDIA_SERVICE_AUTH_TOKEN_URL,
              authToken,
              customerId,
              inspectionIds,
              IncludeAnnotation
            );
          } else {
            noFileCallBack();
            Store.dispatch("updateJointUseLoader", false);
          }
        }
      })
      .catch(error => {
        const errorObj = {
          requestName: "getVisibility",
          errorMessage:
            "Could not retrieve application details. Please try again. If this error continues to occur, please contact support.",
          Error: error
        };
        //Thow error in the UI.
        Store.dispatch("updateError", errorObj);
        Store.dispatch("updateJointUseLoader", false);
      });
    function requestDownload(apiURL, payload, authToken, callback) {
      // Store.dispatch("updateJointUseLoader", true);
      Axios.post(apiURL, payload, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      })
        .then(response => {
          if (response) {
            callback(response);
          } else {
            const errorObj = {
              requestName: "getVisibility",
              errorMessage:
                "Could not initiate download. Please try again. If this error continues to occur, please contact support.",
              Error: response
            };
            //Thow error in the UI.
            Store.dispatch("updateError", errorObj);
          }
          Store.dispatch("updateJointUseLoader", false);
        })
        .catch(error => {
          const errorObj = {
            requestName: "getVisibility",
            errorMessage:
              "Could not initiate download. Please try again. If this error continues to occur, please contact support.",
            Error: error
          };
          //Thow error in the UI.
          Store.dispatch("updateError", errorObj);
          Store.dispatch("updateJointUseLoader", false);
        });
    }
    function getImageToken(
      mediaAuthTokenURL,
      authToken,
      customerId,
      inspectionIds,
      IncludeAnnotation
      // noFileCallBack
    ) {
      Store.dispatch("updateJointUseLoader", true);
      Axios.get(mediaAuthTokenURL, {
        headers: { Authorization: `Bearer ${authToken}` }
      })
        .then(mediaAuthToken => {
          getPoleImageData(
            mediaAuthToken.data.access_token,
            customerId,
            inspectionIds,
            IncludeAnnotation,
            noFileCallBack
          );
        })
        .catch(error => {
          const errorObj = {
            requestName: "getVisibility",
            errorMessage:
              "Could not retrieve media token. Please try again. If this error continues to occur, please contact support.",
            Error: error
          };
          //Thow error in the UI.
          Store.dispatch("updateError", errorObj);
          Store.dispatch("updateJointUseLoader", false);
        });
    }
    function getPoleImageData(
      token,
      customerId,
      inspectionIds,
      IncludeAnnotation,
      noFileCallBack
    ) {
      const mediaImageURL = process.env.VUE_APP_MAKE_MEDIA_REQUEST_URL;
      const requestData = {
        imageReturnType: 2,
        customerId: customerId,
        inspectionIdList: [...inspectionIds],
        appKey: process.env.VUE_APP_MEDIA_APP_KEY,
        maxResize: 128,
        IncludeAnnotation: IncludeAnnotation ? IncludeAnnotation : false
      };
      Axios.post(mediaImageURL, requestData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(mediaResults => {
          if (mediaResults.data.mediaDataObjects.length > 0) {
            requestDownload(apiURL, payload, authToken, callback);
          } else {
            noFileCallBack(true);
            Store.dispatch("updateJointUseLoader", false);
          }
        })
        .catch(error => {
          const errorObj = {
            requestName: "getVisibility",
            errorMessage:
              "Could not retrieve structure's media records. Please try again. If this error continues to occur, please contact support.",
            Error: error
          };
          //Thow error in the UI.
          Store.dispatch("updateError", errorObj);
          Store.dispatch("updateJointUseLoader", false);
        });
    }
  },
  getDownloadedRequest: function (
    apiURL,
    authToken,
    param,
    routePath,
    callback,
    requestFromNotificationManager = false
  ) {
    Store.dispatch("updateJointUseLoader", true);
    Axios.get(apiURL, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
      .then(response => {
        if (param === "downloadMag" || param === "refresh") {
          if (response.data) {
            const convertToUnit = function (number) {
              const unit = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
              let counter = 0;
              while (Math.round(number / 1024) >= 1) {
                number = number / 1024;
                counter++;
              }
              return number.toFixed(2) + " " + unit[counter];
            };
            const requestedDownlods = [];
            response.data.sort((a, b) => {
              return (
                new Date(a.createdOn).getTime() -
                new Date(b.createdOn).getTime()
              );
            });
            response.data.forEach(ele => {
              if (ele.File) {
                const obj = {
                  id: ele.id,
                  name: ele.File.Name,
                  size:
                    ele.Status.toLowerCase() == "completed" && ele.File.Size
                      ? convertToUnit(ele.File.Size)
                      : null,
                  url: ele.File.URL,
                  status: ele.Status,
                  fileCount: ele.File.FileCount,
                  individuleStatus: ele.File.Status,
                  isImage: false,
                  createdOn: new Date(ele.CreatedOn).toLocaleDateString(
                    "en-US",
                    {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit"
                    }
                  ),
                  exportedStatus:
                    ele.Status.toLowerCase() == "completed"
                      ? new Date(ele.ModifiedOn).toLocaleDateString("en-US", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit"
                        })
                      : ele.Status,
                  structureCount: ele.StructureCount
                };
                requestedDownlods.unshift(obj);
              }
              if (ele.ImageFile) {
                const obj = {
                  id: ele.id,
                  name: ele.ImageFile.Name,
                  size:
                    ele.Status.toLowerCase() == "completed" &&
                    ele.ImageFile.Size
                      ? convertToUnit(ele.ImageFile.Size)
                      : null,
                  url: ele.ImageFile.URL,
                  status: ele.Status,
                  fileCount: ele.ImageFile.FileCount,
                  individuleStatus: ele.ImageFile.Status,
                  isImage: true,
                  createdOn: new Date(ele.CreatedOn).toLocaleDateString(
                    "en-US",
                    {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit"
                    }
                  ),
                  structureCount: ele.StructureCount
                };
                if (ele.Status.toLowerCase() == "completed") {
                  obj.exportedStatus =
                    ele.ImageFile.Status.toLowerCase() != "cancelled"
                      ? new Date(ele.ModifiedOn).toLocaleDateString("en-US", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit"
                        })
                      : ele.ImageFile.Status;
                } else {
                  obj.exportedStatus = ele.Status;
                }
                requestedDownlods.unshift(obj);
              }
            });
            if (param === "downloadMag") {
              Store.dispatch("setDownloadRequestData", requestedDownlods);
              Store.dispatch("setDownloadManagerPopup", true);
            } else {
              Store.dispatch("setDownloadRequestData", requestedDownlods);
              callback(requestedDownlods);
            }

            this.sendReadReceiept(
              process.env.VUE_APP_JOINT_USE_SEND_READ_RECEIPT,
              authToken,
              routePath,
              Store.getters.currentProjectId
            );
          }
        }
        if (param === "notification") {
          if (response.data) {
            const unReadRequest = response.data.filter(ele => {
              return (
                (ele.Status.toLowerCase() === "failed" && !ele.isRead) ||
                (ele.Status.toLowerCase() === "completed" && !ele.isRead)
              );
            });

            const currentNotifications = Store.getters.getRawNotificationsArr;
            unReadRequest.forEach(a => (a.NotificationModule = "jointuse"));

            const newNotifications = currentNotifications.concat(unReadRequest);

            console.log(response.data);
            Store.dispatch("setNotificationData", newNotifications);
          }
        }
        Store.dispatch("updateJointUseLoader", false);
      })
      .catch(error => {
        const errorObj = {
          requestName: "getVisibility",
          errorMessage:
            "Could not retrieve download request data. Please try again. If this error continues to occur, please contact support.",
          Error: error
        };
        //Thow error in the UI.
        if (!requestFromNotificationManager) {
          Store.dispatch("updateError", errorObj);
        }
        Store.dispatch("updateJointUseLoader", false);
      });
  },
  sendReadReceiept(apiUrl, authToken, routePath, projectID) {
    if (projectID) {
      apiUrl = apiUrl + "/" + projectID;
    }
    Axios.post(
      apiUrl,
      {},
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then(res => {
        const notifications =
          Store.state.NotificationStore.notifications.filter(ele => {
            return (
              (ele.ProjectId != Store.getters.currentProjectId &&
                ele.NotificationModule == "jointuse") ||
              ele.NotificationModule != "jointuse"
            );
          });
        Store.dispatch("setNotificationData", notifications);
      })
      .catch(error => {
        const errorObj = {
          requestName: "getVisibility",
          errorMessage:
            "Could not update read receiept. Please try again. If this error continues to occur, please contact support.",
          Error: error
        };
        //Thow error in the UI.
        Store.dispatch("updateError", errorObj);
      });
  },
  getDistinctWorkrequestNames(projectId) {
    const authToken = Store.getters.authToken;
    const URL = `${process.env.VUE_APP_JOINT_USE_GET_DISTINCT_WORK_REQUEST_NAMES}/?id1=${projectId}`;
    Axios.get(URL, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
      .then(response => {
        Store.dispatch("updateDistinctWRNames", response.data);
      })
      .catch(error => {
        const errorObj = {
          requestName: "getDistinctWRNames",
          errorMessage:
            "Could not get distinct application names. Please try again. If this error continues to occur, please contact support.",
          Error: error
        };
        //Thow error in the UI.
        Store.dispatch("updateError", errorObj);
      });
  }
};

let database;
export default {
  async getDatabase() {
    return new Promise((resolve, reject) => {
      if (this.database) {
        resolve(this.database);
      }

      const request = window.indexedDB.open("Osmose360", 1);

      request.onerror = event => {
        console.error("ERROR: Unable to open database", event);
        reject("Error");
      };

      request.onsuccess = event => {
        this.database = event.target.result;
        resolve(this.database);
      };

      request.onupgradeneeded = event => {
        const database = event.target.result;
        database.createObjectStore("Osmose360", {
          autoIncrement: true,
          keyPath: "id"
        });
      };
    });
  },

  async saveData(resultName, result) {
    database = await this.getDatabase();

    return new Promise((resolve, reject) => {
      const transaction = this.database.transaction("Osmose360", "readwrite");
      const store = transaction.objectStore("Osmose360");
      const resultObj = { id: resultName, results: result };
      store.put(resultObj);

      transaction.oncomplete = () => {
        resolve(true);
      };

      transaction.onerror = event => {
        reject(event);
      };
    }).catch(e => {
      console.log("storage error save item");
      console.dir(e);
      return false;
    });
  },

  /*****************************************
   * return the item from the object store
   * based on the key, resultName
   ***************************************/
  async getResults(resultName) {
    this.database = await this.getDatabase();

    return new Promise((resolve, reject) => {
      const transaction = this.database.transaction("Osmose360", "readonly");
      const store = transaction.objectStore("Osmose360");
      transaction.onerror = event => {
        reject(event);
      };

      const results = [];
      const request = store.get(resultName);
      request.onsuccess = function (event) {
        if (request.result && request.result.results)
          results.push(request.result.results);
        else results.push(request.result);

        resolve(results);
      };
      request.onerror = function (event) {
        reject(event);
      };
    }).catch(e => {
      console.log("storage error retrieving item");
      console.dir(e);
      return [];
    });
  },

  /**********************************************
   * Remove and item from the object store
   *********************************************/
  async removeItem(cacheKey) {
    this.database = await this.getDatabase();

    return new Promise((resolve, reject) => {
      const transaction = this.database.transaction("Osmose360", "readwrite");
      const store = transaction.objectStore("Osmose360");
      console.log("removing item" + cacheKey);
      store.delete(cacheKey);

      resolve(false);

      transaction.onerror = event => {
        reject(event);
      };
    }).catch(e => {
      console.log("storage error removing item");
      return true;
    });
  },

  /********************************************
   * remove all entries for a customer
   *******************************************/
  async clearCustomerEntries(customerId) {
    this.database = await this.getDatabase();

    return new Promise((resolve, reject) => {
      const transaction = this.database.transaction("Osmose360", "readwrite");
      const store = transaction.objectStore("Osmose360");

      store.openCursor().onsuccess = event => {
        const cursor = event.target.result;
        if (cursor) {
          console.log("delete storage");
          console.log(cursor.value.id);
          const key = cursor.value.id;
          if (key.includes(customerId)) {
            console.log("item removed" + cursor.value.id);
            store.delete(cursor.value.id);
          }

          cursor.continue();
        }
      };
    });
  }
  // async getResults(resultName) {
  //   this.database = await this.getDatabase()

  //   return new Promise((resolve, reject) => {
  //     const transaction = this.database.transaction("Osmose360", 'readonly')
  //     const store = transaction.objectStore("Osmose360")

  //     let results = []

  //     store.openCursor().onsuccess = event => {
  //       const cursor = event.target.result
  //       if (cursor) {
  //         if(cursor.value.id ==resultName)
  //           results.push(cursor.value.results);
  //         else
  //           cursor.continue()
  //       }
  //     }

  //     transaction.oncomplete = () => {
  //       resolve(results)
  //     }

  //     transaction.onerror = event => {
  //       reject(event)
  //     }
  //   })
  // }
};

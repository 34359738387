import { IO360DataSourceClient } from "./IO360DataSourceClient";

export class O360AdvancedSearchClient implements IO360DataSourceClient {
  queryName: string;
  callBack: string;
  dispatch: any;
  params: any;
  addData(data: any[]): void {
    //this.params.additonalParams = this.params;
    const args: any = { additonalParams: this.params, results: data };

    this.dispatch(this.callBack, args);
  }
}

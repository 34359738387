import Axios from "axios";
import Store from "@/store/store";
export async function postRequest(url, payload) {
  const authToken = Store.getters.authToken;
  const results = await Axios.post(url, payload, {
    headers: { Authorization: `Bearer ${authToken}` }
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
  return results;
}
export async function getRequest(url, options) {
  const authToken = Store.getters.authToken;
  const requestOptions = {
    headers: { Authorization: `Bearer ${authToken}` }
  };
  if (options && options.responseType)
    requestOptions.responseType = options.responseType;

  const results = await Axios.get(url, requestOptions)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
  return results;
}
